@import url(stencil.scss);

// @import url(../assets/style/fonts.scss);

/*-
- Bhavesh Gohil
- Date: 22/12/2023.
-*/



.fa, .fa-brands, .fa-classic, .fa-duotone, .fa-light, .fa-regular, .fa-sharp, .fa-sharp-solid, .fa-solid, .fa-thin, .fab, .fad, .fal, .far, .fas, .fasl, .fasr, .fass, .fast, .fat {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
}
.fa-classic, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fal, .far, .fas, .fat {
    font-family: "Font Awesome 6 Pro";
}

.vConnectMainWrap {
    background: #F8F8F8;
    // height: 100vh;
    // width: 100vw;
    img {
        max-width: 100%;
    }
    /* @keyframes FadeIn {
        0% {
            opacity: 0;
            transform: scale(1);
        }


        100% {
            opacity: 1;
            transform: scale(1);
        }
    }*/

    .leftside {
        transition: all 0.3s ease-in;

        &.leftside-open {
            .upgradeBlock {
                transition-timing-function: cubic-bezier(0.8, 0.3, 0.8, 1.50);
                /*transition-duration: 0.3s;*/
                transition: opacity 4s;
                opacity: 1;
                display: unset;
            }
            .hideOncollapse {
                transition-timing-function: cubic-bezier(0.8, 0.3, 0.8, 1.50);
                /*transition-duration: 0.3s;*/
                transition: opacity 4s;
                opacity: 1;
            }
        }
    }

    .vCbadge {
        background: #B42318;
        margin-left: 5px;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        vertical-align: middle;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: #fff;
        line-height: 20px;
        padding: 0;
        display: inline-block;
    }

    .leftside {
        width: 169px;
        max-width: 169px;
        height: calc(100vh - 50px);
        background: #fff;
        border-right: 1px solid #E7E7E7;
        border-top: 1px solid #E7E7E7;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 10;
        transition: all 0.3s ease-in;

        .fa-chevrons-left {
            display: inline-block;
            transition: all 0.3s ease-in;
        }

        .fa-chevrons-right {
            display: none;
            transition: all 0.3s ease-in;
        }
        .hideOncollapse {
            opacity: 0;
        }

        .upgradeBlock {
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 16px;
            background: #478CCA;
            padding: 16px 15px 26px 15px;
            text-align: center;
            color: #fff;
            border-radius: 8px;
            /*display: block;*/
            opacity: 0;

            .msgImg {
                margin-bottom: 8px;
                img {
                    max-width: 59px;
                }
            }

            .textArea {
                margin-bottom: 18px;

                &-title {
                    font-size: 15px;
                    line-height: 20px;
                    color: #FFF;
                }

                &-info {
                    font-size: 12px;
                    line-height: 16px;
                    text-align: left;
                    color: #FFF;
                }
            }

            &-btn {
                padding: 9px 16px;
                border-radius: 3px;
                background: #fff;
                color: #333;
                font-size: 15px;
                text-decoration: none;
                font-weight: 400;
                cursor: pointer;
            }
        }

        &-toggle {
            padding-top: 19px;
            padding-bottom: 6px;
            padding-left: 24px;
            color: #6D6E6F;
            display: block;
            cursor: pointer;
        }

        &-menu {
            &_options {
                padding: 7.5px 16px 7.5px 20px;
                text-decoration: none;
                font-size: 16px;
                line-height: 20px;
                color: #333;
                font-weight: 600;
                cursor: pointer;
                white-space: nowrap;
                display: flex;
                align-items: center;

                &:hover {
                    background: #F8F8F8;
                }

                &.active {
                    background: #E7E7E7;
                }

                i {
                    margin-right: 11px;
                    font-size: 16px;
                    vertical-align: middle;

                    &.fa-users {
                        // margin-bottom: -1px;
                        font-family: "Font Awesome 5 Free";
                    }

                    &.fa-id-card {
                        margin-left: 2px;
                    }
                }

                .vCbadge {
                    // display: none;
                    height: 16px;
                    width: 16px;
                    line-height: 16px;
                    font-weight: 600;
                    margin-left: 10px;
                }

                &.active {
                    .vCbadge {
                        display: inline-block;
                    }
                }
            }
        }
        &-collepas.leftside {
            max-width: 40px;
            width: 40px;

            .leftside-menu_options {
                font-size: 0;
                padding-left: 10px;

                i {
                    margin-right: 0;
                }
            }

            .leftside-toggle {
                padding-left: 12px;
                padding-top: 13px;
            }

            .leftside-menu {
                margin-top: 13px;
                // flex-direction: column;

                &_options {
                    padding: 10px 12px 10px 10px;

                    &.active {
                        border-left: 1px solid var(--border-neutral-border-neutral-strong-ondark, #333);
                    }
                }
            }
            /*.upgradeBlock {
                opacity: 0;
            }*/

            .vCbadge,
            .fa-chevrons-left,
            .textCounArea {
                display: none;
                transition: all 0.3s ease-in;
            }

            .fa-chevrons-right {
                display: inline-block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .textCounArea {
        padding: 16px 17px 16px 15px;
        border-top: 1px solid #E7E7E7;

        &-sent {
            color: #333;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 8px;

            &_count {
                font-size: 26px;
                font-weight: 500;
                line-height: 32px;
                margin-right: 7px;
            }
        }

        &-limit {
            color: #2A90D6;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 12px;
            cursor: pointer;
        }

        &-contact {
            color: #333;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 4px;
        }
        &-status-msg {
            color: var(--text_neutral_default)!important;
        }

        .progress {
            margin-bottom: 12px;
            border-radius: 8px;
            background: var(--bkg_neutral_tiertiary, #E7E7E7);
            height: 8px;

            &-bar {
                border-radius: 8px;
                background: var(--bkg_green_default);
            }
        }

        .mail-sent-per {
            color: var(--text_neutral_default);
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 4px;
        }
    }

    .welcomeScreen {
        background: #F8F8F8;
        position: relative;
        width: calc(100vw - 178px);

        .typeChatWrap {
            bottom: 0;
        }

        &-block {
            position: absolute;
            height: 100%;
            width: 345px;
            left: 0;
            right: 0;
            /*top: 0;*/
            top: -30px;
            text-align: center;
            color: #333;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &_title {
                margin-top: 48px;
                line-height: 28px;
                font-size: 24px;
            }

            &_info {
                font-size: 16px;
                line-height: 20px;
                margin-top: 24px;
            }
        }
    }

    .typeMsgText {
        margin: 0;
        margin-bottom: -7px;
        background-color: rgb(235 234 234);
        color: #333;
        padding: 5px 18px 10px 18px;
        border-radius: 5px;
        font-size: 14px;
    }

    .typeChatWrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 6;

        .typeMsgText {
            margin: 0;
            margin-bottom: -7px;
            background-color: rgb(235 234 234);
            color: #333;
            padding: 5px 18px 10px 18px;
            border-radius: 5px;
            font-size: 14px;
        }

        &-block {
            margin: 20px;
            background: #fff;
            border-radius: 8px;
            border: 1px solid #FFF;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
            border: 1px solid #CDCDCD;

            &_options {
                display: flex;
                align-items: end;
                justify-content: space-between;
                padding: 0 16px 15px 16px;

                &-left {
                    nav a {
                        text-decoration: none;
                        margin: 0 8px 0 8px;

                        &:first-child {
                            margin-left: 1px;
                        }
                    }

                    .optionIcon {
                        font-family: "Font Awesome 5 Pro";
                        color: #A2A0A2;
                        text-align: center;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        vertical-align: middle;

                        &.smile::before {
                            content: '\f118';
                        }

                        &.attch::before {
                            content: '\f0c6';
                        }
                        /* &.gif {
                            background: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/gifIcon.svg') center center no-repeat;
                            height: 15px;
                            width: 17px;
                            display: inline-block;
                        }

                        &.url {
                            background: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/urlIcon.svg') center center no-repeat;
                            height: 15px;
                            width: 19px;
                            display: inline-block;
                        }*/

                        &.note::before {
                            content: '\f249';
                            color: #FF9F08;
                        }
                    }
                }

                &-right {
                    .btn-send {
                        border-radius: 3px;
                        color: #fff;
                        background: var(--bkg_green_default);
                        padding: 10px 23px;
                        text-decoration: none;
                        display: inline-block;
                    }
                }
            }
        }

        textarea.textBlock {
            background: transparent;
            padding: 9px 16px 3px 16px;
            border-radius: 8px;
            border: none;
            display: block;
            width: 100%;
            resize: none;

            &::placeholder {
                color: #A2A0A2;
            }
        }
    }

    .leftside-collepas {
        & + .chatarea {
            width: calc(100% - 40px);
        }
    }

    .collapsibleChat {
        display: none;
    }

    .chatarea {
        width: calc(100% - 169px);

        > div.d-flex {
            justify-content: space-between;
        }

        .rightSideOnly-header,
        .upgradeBlock,
        .smallChatBox-footer,
        .messagesArea-header_back {
            display: none;
        }

        .chatListBlock {
            background: #fff;
            border-right: 1px solid #E7E7E7;
            height: calc(100vh - 50px);
            max-width: 319px;
            flex: 0 0 319px;

            &-searchBlock {
                display: flex;
                justify-content: space-between;

                .serachGrooup {
                    position: relative;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    input.searchField {
                        width: 100%;
                        border-radius: 3px;
                        border: 1px solid #CDCDCD !important;
                        background: #FFF;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        color: #A2A0A2;
                        padding: 10px 30px;
                    }

                    .searchFieldIcon {
                        position: absolute;
                        top: 14px;
                        right: 10px;
                        color: var(--text_neutral_default);
                        opacity: 0.5;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.6;
                        }
                    }

                    .searchIcon {
                        position: absolute;
                        left: 9px;
                        // top: 12px;
                        // bottom: auto;
                    }

                    .btn-filter {
                        position: absolute;
                        right: 7px;
                        top: 10px;
                        background: transparent;
                        color: #333333;
                        border: none;
                        box-shadow: none;
                        display: none;

                        button {
                            border: none;
                            padding: 0px 2px 0 0px;
                            box-shadow: none;
                            background-color: transparent;
                            border-color: transparent;
                            color: transparent;
                            height: auto;
                            display: block;

                            &:after {
                                display: none;
                            }

                            &:active,
                            &:focus,
                            &:hover,
                            &.show {
                                border: none;
                                padding: 0px 2px 0 0px;
                                box-shadow: none;
                                background-color: transparent;
                                border-color: transparent;
                                color: transparent;
                                height: auto;
                                display: block;
                            }
                        }
                    }
                }

                .btn-addToChat {
                    background: var(--bkg_green_default);
                    border-radius: 3px;
                    padding: 8px 16px;
                    margin-left: 14px;
                    border: none;
                    /* width: 48px;
                    height: 40px;*/
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover,
                    &:focus {
                        background: var(--bkg_components_button_green_hover);
                        border-color: var(--bkg_components_button_green_hover);
                    }
                }
            }

            &-searchWrap {
                padding: 33px 16px 12px 15px;

                .searchIcon {
                    &::after {
                        content: '\f002';
                        font-family: "Font Awesome 5 Pro";
                        color: #A2A0A2;
                        font-size: 14px;
                    }
                }
            }

            .chatList {
                &-header {
                    border-bottom: 2px solid #CDCDCD;
                    padding: 0 8px;
                    display: flex;

                    &_menu {
                        border-bottom: 2px solid;
                        border-color: transparent;
                        text-decoration: none;
                        margin: 0 8px;
                        padding: 14px 0;
                        /*display: inline-block;*/
                        margin-bottom: -2px;
                        font-size: 18px;
                        color: #6D6E6F;
                        font-weight: 500;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        .vCbadge {
                            margin-bottom: 1px;
                        }

                        &.active {
                            color: #333;
                            border-bottom: 2px solid;
                            border-color: var(--text_neutral_default) !important;
                        }
                    }
                }
                // This is for set scroll
                &-groupBlock {
                    // header 50px
                    // search block 86px
                    // chat list header 53px
                    height: calc(100vh - 190px);
                    /*height: calc(100vh - 189px);*/
                    overflow-y: auto;
                    // &::-webkit-scrollbar {
                    //     display: none;
                    //     opacity: 0;
                    // }
                }

                &-group {
                    &_header {
                        background: #F8F8F8;
                        color: #6D6E6F;
                        padding: 8px 16px;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        text-transform: uppercase;

                        i.fa {
                            font-size: 18px;
                            margin-left: 8px;
                            vertical-align: middle;
                            line-height: 13px;
                        }
                    }
                }

                &-person {
                    display: flex;
                    align-items: center;
                    padding: 16px 16px 15px 4px;
                    border-bottom: 1px solid#CDCDCD;
                    position: relative;

                    &_overlay {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 1;
                        // z-index: 1;
                    }

                    &:last-child:not(:first-child) {
                        border-bottom: none;
                    }

                    &_dotMenu {
                        position: absolute;
                        right: 16px;
                        top: 5px;
                        text-decoration: none;
                        // z-index: 2;
                        color: var(--icon-neutral-icon-neutral-default, #333);
                        display: none;

                        .dropdown-toggle {
                            padding: 8px 2px 0 0px;
                            display: block;

                            &:after {
                                display: none;
                            }

                            &:active,
                            &:focus,
                            &:hover,
                            &.show {
                                border: none;
                                padding: 8px 2px 0 0px;
                                box-shadow: none;
                                background-color: transparent;
                                border-color: transparent;
                                color: transparent;
                                height: auto;
                            }
                        }

                        .dropdown {
                            &-menu {
                                border-radius: 3px;
                                border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                                background: var(--bkg-neutral-bkg-neutral-default, #FFF);
                                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
                                padding: 3px 0;
                                width: 200px;
                            }

                            &-item {
                                padding: 12px;
                                white-space: pre-wrap;

                                &:hover {
                                    background: #F8F8F8;
                                }
                            }
                        }
                    }

                    &:hover {
                        background: var(--bkg-neutral-bkg-neutral-secondary, #F8F8F8);
                    }

                    &.active {
                        background: var(--bkg_neutral_tiertiary) !important;
                    }

                    &.newChat {
                        align-items: center;

                        .avatar-group-img-wrap {
                            border: 1px solid var(--border_neutral_strong) !important;
                            background: var(--bkg_category_airhead) !important;
                            display: flex;
                            align-items: center;
                            justify-content: space-around;

                            i {
                                color: var(--text_neutral_inverse);
                            }
                        }

                        .chatList-person_name {
                            font-weight: 500;
                        }
                    }

                    &:hover {
                        // background: var(--bkg-neutral-bkg-neutral-secondary, #F8F8F8);
                        cursor: pointer;

                        .chatList-person_time {
                            display: none;
                        }

                        .dropdown-toggle,
                        .dropdown-toggle:hover {
                            border: none;
                            i {
                                color: var(--text_neutral_default);
                            }
                        }
                    }

                    .status {
                        height: 8px;
                        width: 8px;
                        border-radius: 8px;
                        margin-right: 4px;
                        background: transparent;

                        &.unread {
                            background: #C5392F;
                        }
                    }

                    .avatar {
                        margin-right: 16px;
                    }

                    &_name {
                        color: #333;
                        font-size: 16px;
                        font-style: normal;
                        // font-weight: 500;
                        line-height: 20px;
                        text-transform: capitalize;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-right: 3px;
                        max-width: 80%;
                        // margin-bottom: 1px;
                    }

                    &_time {
                        color: #333;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                    }

                    &_messages {
                        color: #6D6E6F;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &_messagedeleted {
                        font-style: italic;
                    }

                    &_left {
                        display: flex;
                        align-items: center;
                    }

                    &_right {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: 77%;

                        &-top {
                            width: 100%;
                            white-space: nowrap;
                            display: flex;
                            align-items: start;
                            justify-content: space-between;
                        }

                        &-bottom {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .isTypingMain {
                                width: 90%;
                            }
                        }
                    }

                    &_dotMenu.show.dropdown ~ .chatList-person_right > .chatList-person_right-top > .chatList-person_time {
                        display: none;
                    }

                    &.userBlock {
                        .userBlock-icon {
                            display: block;
                        }
                    }

                    .userBlock-icon {
                        display: none;
                        position: absolute;
                        top: 15px;
                        left: 18px;
                        height: 12px;
                        width: 12px;
                        border-radius: 15px;
                        background: #f00;
                        color: #fff;
                        font-size: 8px;
                        text-align: center;
                        line-height: 13px;
                        z-index: 1;
                        font-weight: 600;
                    }
                }

                &-noData {
                    // height: calc(100vh - 189px);
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    padding: 26px 10px 10px 10px;
                    text-align: center;
                    font-size: 14px;

                    &_search {
                        padding: 10px;
                        text-align: center;
                        background: #f5f5f5;
                        font-size: 14px;
                        border-bottom: 1px solid #E7E7E7;
                    }

                    &_icon {
                        color: var(--icon_neutral_default);
                        text-align: center;
                        font-size: 64px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        margin-bottom: 10px;
                    }

                    &_title {
                        color: var(--text_neutral_default);
                        text-align: center;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px;
                        margin-bottom: 17px;
                        margin-top: 5px;
                    }

                    &_info {
                        color: var(--text_neutral_default);
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }
            }
        }

        .btn-filter {
            position: absolute;
            right: 7px;
            top: 10px;
            background: transparent;
            color: #333333;
            border: none;
            box-shadow: none;
            display: none;

            button {
                border: none;
                padding: 0px 2px 0 0px;
                box-shadow: none;
                background-color: transparent;
                border-color: transparent;
                color: transparent;
                height: auto;
                display: block;

                &:after {
                    display: none;
                }

                &:active,
                &:focus,
                &:hover,
                &.show {
                    border: none;
                    padding: 0px 2px 0 0px;
                    box-shadow: none;
                    background-color: transparent;
                    border-color: transparent;
                    color: transparent;
                    height: auto;
                    display: block;
                }
            }
        }

        .messagesArea {
            position: relative;

            &-header {
                background: #fff;
                border-top: 1px solid #E7E7E7;
                border-bottom: 1px solid #E7E7E7;
                border-left: none;
                border-right: none;
                padding: 16px 16px 16px 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &_nameArea {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 90%;
                }

                &_btnArea {
                    display: flex;
                    align-items: center;

                    .btn-addCtm {
                        padding: 8px 16px;
                        border-radius: 3px;
                        border: 1px solid #CDCDCD;
                        background: #FFF;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        text-decoration: none;
                        display: none;
                    }
                }

                &_name {
                    color: #333;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: capitalize;
                    margin-left: 10px;
                    word-break: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 90%;
                }
                // @media screen and (max-width: 1024px) {
                //     &_name {
                //         max-width: calc(100vw - 700px);
                //     }
                // }
                // @media screen and (max-width: 768px) {
                //     &_name {
                //         max-width: calc(100vw - 640px);
                //     }
                // }

                &_avGroup {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .avatar {
                        // flex: 0 0 30px;
                        &-group-img-wrap-text {
                            // margin-top: -2px;
                            // margin-left: -2px;
                        }
                    }
                }

                &_moreVertical {
                    display: none;
                }
            }

            &-middle {
                // Header 50px
                // msg area header 70px
                // msg area footer 145
                height: calc(100vh - 265px);
                overflow-y: auto;
                padding: 17px 24px;

                .inChatmsg {
                    margin: 16px 0 8px 0;
                    color: #A2A0A2;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    text-transform: uppercase;

                    &.GroupName {
                        text-transform: none;
                        word-break: break-word;
                    }

                    &.personBlockText {
                        color: #A2A0A2;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        text-transform: none;

                        .bookingDetailInChat {
                            span {
                                color: var(--text_neutral_weak);
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600; 
                                line-height: 16px;
                            }
                            color: var(--text_neutral_weak);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 16px
                        }
                    }
                }

                .messagesBox {
                    display: flex;
                    align-items: start;
                    justify-content: start;
                    margin-bottom: 16px;

                    .avatar {
                        margin-top: 18px;
                    }

                    &-block {
                        margin: 0 8px;
                        max-width: 56%;
                        position: relative;

                        .name {
                            color: #333;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            text-transform: capitalize;
                            line-height: 16px;
                            margin-bottom: 1px;
                        }

                        .messages {
                            max-width: 100% !important;
                            padding: 10px 12px;
                            border-radius: 8px;
                            background: #E7E7E7;
                            color: #333;
                            margin: 2px 0;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            word-break: break-word;
                            max-width: fit-content;
                            margin: 0 0 2px auto;

                            p.ck-placeholder {
                                &::before {
                                    font-size: 15px;
                                }
                            }
                        }

                        .time {
                            color: #6D6E6F;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            // position: absolute;
                            // bottom: 0;
                            // left: 0;

                            .chechIcon {
                                color: var(--border_green_strong);

                                &::before {
                                    font-family: "Font Awesome 5 Pro";
                                    content: '\f058';
                                    margin-left: 3px;
                                    font-size: 11px;
                                    margin-right: 2px;
                                }
                            }
                        }

                        .saveControl {
                            &Wrap {
                                position: absolute;
                                right: 0;
                            }

                            display: inline-block;
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }

                    &.sent,
                    &.recived {
                        .messages {
                            &.message-note-block {
                                border: 1px solid #FF9F08 !important;
                                background: #FFF5E6 !important;
                                color: #333 !important;
                            }

                            p {
                                margin: 0;
                            }
                        }
                    }

                    .editControl {
                        &mainWrap {
                            // &:has(.editControlWrap){
                                position: absolute;
                                right: 1px;
                                top: -20px;                                
                            // }
                        }
                        &Wrap {
                            background: #fff;                            
                            border-radius: 3px;
                            display: block;
                            min-width: 64px;
                            z-index: 9;
                        }

                        border-right: 1px solid #f5f5f5;
                        padding: 5px 10px;
                        cursor: pointer;
                        text-decoration: none;
                        display: inline-block;

                        &:last-child {
                            border-right: none;
                        }

                        i {
                            color: #000;
                            font-size: 12px;
                        }

                        .editControlText {
                            display: none;
                        }
                    }
                    &.recived {
                        .editControlWrap {
                            left: 0;
                        }
                        .editControlmainWrap {
                                &:has(.editControlWrap){
                                left: 0;                     
                            }
                        }
                    }

                    &.sent {
                        justify-content: flex-end;

                        .messages {
                            background: #5162A8;
                            color: #fff;
                            margin: 0 0 2px auto;
                            position: relative;

                            a {
                                color: #fff;
                            }

                            
                        }

                        .message-note-block {
                            a {
                                color: #333;
                            }
                        }

                        .name,
                        .time {
                            text-align: right;
                        }
                        // .time {
                        //     right: 0;
                        //     left: auto;
                        // }

                        .name {
                            margin-bottom: 2px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 100%;
                        }
                    }

                    &.recived {
                        .messages {
                            margin: 0 auto 2px 0;
                        }
                    }

                    &.messagesEditWrap {
                        .messagesBox-block {
                            width: 100%;

                            .messages {
                                background: transparent !important;

                                .typeMsgText {
                                    margin: 0;
                                    margin-bottom: -7px;
                                    background-color: rgb(235 234 234);
                                    padding: 5px 18px 10px 18px;
                                    border-radius: 5px;
                                    font-size: 14px;
                                    color: #333;
                                }
                            }
                        }

                        .messages {
                            background: transparent;
                            width: 100%;
                            max-width: 100%;
                            color: #333;
                            padding: 0;

                            .vcDrop-wrap {
                                bottom: 30px;
                                left: 30px;
                            }

                            .vcDrop-group-item {
                                color: var(--text-neutral-text-neutral-default, #333);
                            }
                        }

                        .gifPluginBlock {
                            bottom: 30px;
                            left: 60px;
                        }

                        .EmojiPickerReact {
                            position: absolute;
                            bottom: 30px;
                            left: 5px;
                            z-index: 9;
                            height: 396px;
                            width: 382px;
                        }

                        .ck-editor {
                            &.ck-reset {
                                .ck-editor__main .ck-editor__editable {
                                    padding: 5px 15px;
                                }

                                .ck-toolbar .ck-toolbar__items {
                                    // margin-bottom: -5px;
                                }
                            }

                            .ck-editor__top .ck-sticky-panel .ck-toolbar {
                                padding: 0 10px 5px 10px;
                                min-height: auto;
                            }
                        }

                        .saveControl {
                            // border: 1px solid #f00;
                            padding: 0 5px;
                            display: inline-block;
                            height: 20px;
                            cursor: pointer;

                            .fa.fa-solid.fa-check {
                                cursor: pointer;
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                font-size: 14px;
                                // color: #333 !important;
                            }

                            &-cancel {
                                &::after {
                                    font-family: "Font Awesome 5 Free";
                                    font-size: 14px;
                                    font-weight: 900;
                                    content: '\f00d';
                                    // color: #333 !important;
                                }
                            }
                        }
                    }
                }
            }

            &-footer {
                .moTypeTextArea {
                    display: none;
                }

                .EmojiPickerReact {
                    position: absolute;
                    bottom: 70px;
                    left: 30px;
                    z-index: 9;
                }
            }
        }
    }

    .EmojiPickerReact {
        position: absolute;
        bottom: 70px;
        left: 30px;
        width: 370px !important;
        z-index: 99999;
    }
    
    .chat-deleted {
        background: var(--bkg_neutral_tiertiary) !important;
        border-radius: 5px;
        padding: 10px;
        margin: 16px 0 8px 0;
        color: #A2A0A2;
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 16px;
        margin-bottom: 16px;
        margin-right: 38px;
        font-style: italic;

        &Wrap {
            display: flex;
            align-items: center;
            justify-content: end;
        }

        &_undo {
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            color: #7b80eb;
        }
    }

    .chatListBlock {
        background: #fff;
        border-right: 1px solid #E7E7E7;
        border-top: 1px solid #E7E7E7;
        height: calc(100vh - 55px);
        max-width: 319px;
        flex: 0 0 319px;

        &-searchBlock {
            display: flex;
            justify-content: space-between;

            .serachGrooup {
                position: relative;
                width: 100%;

                input.searchField {
                    width: 100%;
                    border-radius: 3px;
                    border: 1px solid #CDCDCD !important;
                    background: #FFF;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    color: #A2A0A2;
                    padding: 10px 30px;
                }

                .searchIcon {
                    position: absolute;
                    left: 8px;
                    // top: 10px;
                }

                .btn-filter {
                    position: absolute;
                    right: 7px;
                    top: 10px;
                    background: transparent;
                    color: #333333;
                    border: none;
                    box-shadow: none;
                    display: none;
                }
            }

            .btn-addToChat {
                background: var(--bkg_green_default);
                border-radius: 3px;
                padding: 7px 15px 10px 15px;
                margin-left: 14px;
                border: none;

                svg path {
                    fill: white !important;
                }
            }
        }

        &-searchWrap {
            padding: 34px 16px 10px 16px;

            .searchIcon {
                &::after {
                    content: '\f002';
                    font-family: "Font Awesome 5 Pro";
                    color: #A2A0A2;
                }
            }
        }

        .chatList {
            &-header {
                border-bottom: 2px solid #CDCDCD;
                padding: 0 8px;

                &_menu {
                    border-bottom: 2px solid transparent;
                    text-decoration: none;
                    margin: 0 8px;
                    padding: 14px 0;
                    display: inline-block;
                    margin-bottom: -2px;
                    font-size: 18px;
                    color: #333;
                    font-weight: 500;
                    cursor: pointer;

                    &.active {
                        border-bottom: 2px solid #333;
                    }
                }
            }
            // This is for set scroll
            &-groupBlock {
                // header 50px
                // search block 86px
                // chat list header 53px
                height: calc(100vh - 189px);
                overflow-y: scroll;
                // &::-webkit-scrollbar {
                //     display: none;
                //     opacity: 0;
                // }
            }

            &-group {
                &_header {
                    background: #F8F8F8;
                    color: #6D6E6F;
                    padding: 8px 16px 7px 16px;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    text-transform: uppercase;

                    i.fa {
                        font-size: 18px;
                        margin-left: 8px;
                        vertical-align: middle;
                    }
                }
            }

            &-person {
                display: flex;
                align-items: center;
                padding: 16px 16px 15px 4px;
                border-bottom: 1px solid#CDCDCD;
                position: relative;

                &_dotMenu {
                    position: absolute;
                    right: 16px;
                    top: 5px;
                    text-decoration: none;
                    color: var(--icon-neutral-icon-neutral-default, #333);
                    display: none;

                    .dropdown-toggle {
                        padding: 8px 2px 0 0px;
                        display: block;

                        &:after {
                            display: none;
                        }

                        &:active,
                        &:focus,
                        &:hover,
                        &.show {
                            border: none;
                            padding: 8px 2px 0 0px;
                            box-shadow: none;
                            background-color: transparent;
                            border-color: transparent;
                            color: transparent;
                            height: auto;
                        }
                    }

                    .dropdown {
                        &-menu {
                            border-radius: 3px;
                            border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                            background: var(--bkg-neutral-bkg-neutral-default, #FFF);
                            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
                            padding: 0;
                        }

                        &-item {
                            padding: 12px;

                            &:hover {
                                background: #fff;
                            }
                        }
                    }
                }

                &.active {
                    background: #E7E7E7;
                }

                &:hover {
                    background: var(--bkg-neutral-bkg-neutral-secondary, #F8F8F8);
                    cursor: pointer;

                    .chatList-person_time {
                        display: none;
                    }

                    .dropdown-toggle,
                    .dropdown-toggle:hover {
                        border: none;
                    }
                }

                .status {
                    height: 8px;
                    width: 8px;
                    border-radius: 8px;
                    margin-right: 4px;
                    background: transparent;

                    &.unread {
                        background: #C5392F;
                    }
                }

                .avatar {
                    margin-right: 16px;
                }

                &_name {
                    color: #333;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    text-transform: capitalize;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 3px;
                    // width: 70%;
                    // margin-bottom: 1px;
                }

                &_time {
                    color: #333;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                }

                &_messages {
                    color: #6D6E6F;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &_left {
                    display: flex;
                    align-items: center;
                }

                &_right {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 77%;
                }

                &_dotMenu.show.dropdown ~ .chatList-person_right > .chatList-person_time {
                    display: none;
                }

                &.userBlock {
                    .userBlock-icon {
                        display: block;
                    }
                }

                .userBlock-icon {
                    display: none;
                    position: absolute;
                    top: 15px;
                    left: 18px;
                    height: 12px;
                    width: 12px;
                    border-radius: 15px;
                    background: #f00;
                    color: #fff;
                    font-size: 8px;
                    text-align: center;
                    line-height: 13px;
                    z-index: 9;
                    font-weight: 600;
                }
            }
        }
    }

    .pinnedGroup.accordion {
        .accordion {
            &-button {
                background-color: transparent;
                box-shadow: none;
                padding: 0;
                display: block;

                &::after {
                    display: none;
                }

                .fa.fa-caret-down {
                    transition: transform 0.2s ease-in-out;
                }

                &.collapsed {
                    .fa.fa-caret-down {
                        transform: rotate(-90deg);
                    }
                }
            }

            &-body {
                padding: 0;
                background-color: transparent;
            }

            &-item {
                border: none;
                background-color: transparent;
            }
        }
    }

    .chatList {
        &-header {
            border-bottom: 2px solid #CDCDCD;
            padding: 0 8px;

            &_menu {
                border-bottom: 2px solid transparent;
                text-decoration: none;
                margin: 0 8px;
                padding: 14px 0;
                display: inline-block;
                margin-bottom: -1px;
                font-size: 18px;
                color: #333;
                font-weight: 500;

                &.active {
                    border-bottom: 2px solid #333;
                    margin-bottom: -1px;
                }
            }
        }
        // This is for set scroll
        &-groupBlock {
            // header 50px
            // search block 86px
            // chat list header 53px
            height: calc(100vh - 189px);
            overflow-y: scroll;
            // &::-webkit-scrollbar {
            //     display: none;
            //     opacity: 0;
            // }
        }

        &-group {
            &_header {
                background: #F8F8F8;
                color: #6D6E6F;
                padding: 8px 16px 7px 16px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                text-transform: uppercase;

                i.fa {
                    font-size: 18px;
                    margin-left: 8px;
                    vertical-align: middle;
                }
            }
        }

        &-person {
            display: flex;
            align-items: center;
            padding: 16px 16px 15px 4px;
            border-bottom: 1px solid#CDCDCD;
            position: relative;

            &_dotMenu {
                position: absolute;
                right: 16px;
                top: 14px;
                text-decoration: none;
                color: var(--icon-neutral-icon-neutral-default, #333);
                display: none;
            }

            &.active {
                background: #E7E7E7;
            }

            &:hover,
            &:active {
                background: var(--bkg-neutral-bkg-neutral-secondary, #F8F8F8);
                cursor: pointer;

                .chatList-person_dotMenu {
                    display: flex;
                }

                .chatList-person_time {
                    opacity: 0;
                }
            }

            &.personRead {
                .chatList-person_name {
                    font-weight: 500;
                }

                .chatList-person_time {
                    font-weight: 400;
                }
            }


            .status {
                height: 8px;
                width: 8px;
                border-radius: 8px;
                margin-right: 4px;
                background: transparent;

                &.unread {
                    background: #C5392F;
                }
            }

            .avatar {
                margin-right: 16px;
            }

            &_name {
                color: #333;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                text-transform: capitalize;
                // margin-bottom: 1px;
            }

            &_time {
                color: #333;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
            }

            &_messages {
                color: #6D6E6F;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                width: 100%;
            }

            &_left {
                display: flex;
                align-items: center;
            }

            &_right {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    .employeeSelectWrap {
        padding: 17px 15px;
        position: relative;
        z-index: 9;



        .select2-container--default {
            &.vcEmployeeSelect {
                top: 0px !important;
            }

            .select2-selection {

                &--multiple,
                &--single {
                    border-radius: 3px;
                    border: 1px solid var(--border_neutral_default)!important;
                    
                    opacity: 0.8;
                    background: var(--bkg-neutral-bkg-neutral-default, #FFF);
                    height: 60px;

                    .select2-selection__rendered {
                        padding: 0px;

                        span {
                            padding: 20px;
                            display: inline-block;
                        }

                        .select2-search--inline {
                            float: none;

                            .select2-search__field {
                                border: none;
                                height: 57px;
                                background: transparent;
                                padding: 0;
                                font-family: var(--bs-body-font-family);
                                font-size: 16px;
                                padding: 0 16px;
                                color: #cdcdcd;
                            }
                        }
                    }
                }

                &--single {
                    padding: 0 20px;
                    border-color: #cdcdcd !important;
                    opacity: 1;

                    .select2-selection__rendered {
                        /*display: inline-block;*/
                        padding: 14px 0;

                        .select2-selection__placeholder {
                            padding: 0;
                            font-family: var(--bs-body-font-family);
                            font-size: 16px;
                            color: var(--text-neutral-text-neutral-default, #333);
                        }

                        .select2-selection__clear {
                            display: none;
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }

                &--multiple {
                    opacity: 1;
                    padding: 0;
                    border-color: var(--border-neutral-border-neutral-default, #E7E7E7);

                    .select2-selection__rendered {
                        display: flex;
                        max-width: 97%;
                        overflow: auto;
                    }

                    .select2-selection__choice {
                        width: auto;
                        height: 44px;
                        padding: 4px 8px;
                        margin: 8px 10px 0 0;
                        border-radius: 5px;
                        border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                        background: var(--bkg-neutral-bkg-neutral-secondary, #F8F8F8);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &:first-child {
                            margin-left: 16px;
                        }

                        &__display {
                            padding: 0 !important;
                            color: var(--Text-Primary, #333);
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            width: 100%;
                            min-width: 95px;
                            max-width: 180px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-right: 5px;
                        }

                        &__remove {
                            padding: 0;
                            margin: 0;
                            border: none;
                            font-size: 26px;
                            background: transparent;
                            color: #000;

                            > span {
                                padding: 0;
                                margin: 0;
                                opacity: 0.6;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }

                        .avatar {
                            display: inline-block;
                            margin-right: 8px;
                            vertical-align: middle;

                            &-sm {
                                height: 28px;
                                width: 28px;

                                .avatar-group-img-wrap {
                                    height: 29px;
                                    width: 29px;

                                    img {
                                        height: 28px;
                                        width: 28px;

                                        &[src=""] {
                                            display: none;
                                        }
                                    }

                                    .initial-avtar {
                                        color: #333;
                                        font-size: 12px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.select2-container--below.select2-container--open {
                // .select2-selection--single {
                //     opacity: 0;
                // }
            }
        }
    }

    .extendButton {
        cursor: pointer;
        margin: 0 8px;

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            margin-left: 0;
        }

        i.fa-solid {
            font-size: 15px;
        }

        .icon {
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 16px;
                font-weight: 400;
                font-family: "Font Awesome 5 pro";
            }
        }

        &.fullscreen {
            cursor: pointer;

            span.icon {
                vertical-align: middle;
                height: 30px;
                width: 30px;
                display: inline-block;
                background: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/fullscreenIcon.svg') center center no-repeat;
            }
        }

        &.arrowDown {
            cursor: pointer;

            span.icon {
                vertical-align: middle;
                height: 30px;
                width: 30px;
                display: inline-block;
                background: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/arrowDown.svg') center center no-repeat;
            }
        }

        &.close {
            span.icon {
                vertical-align: middle;
                height: 30px;
                width: 30px;
                display: inline-block;
                background: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/closeBlackIcon.svg') center center no-repeat;
            }
        }

        &.minimize {
            padding: 4px;
            border-radius: 3px;
            transition: all 0.1s ease-in;

            span.icon {
                vertical-align: middle;
                height: 30px;
                width: 30px;
                display: inline-block;
                background: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/minimizeChat.svg') center center no-repeat;
            }

            &:hover {
                // margin: 0;
                background: var(--background-dark-hover, #E7E7E7);
            }
        }

        &.moreMinimize {
            span.icon {
                vertical-align: middle;
                height: 30px;
                width: 30px;
                display: inline-block;
                background: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/smallChatIcon.svg') center center no-repeat;
            }
        }
    }
    // START: CK editer
    .cKEditerWrap {
        position: relative;
    }

    .CKediterWrap {
        &.note-active {
            .cke_inner {
                border: 1px solid #FF9F08 !important;

                .cke_contents.cke_reset {
                    background: #FFF5E6;
                }
            }

            .cke_bottom {
                background: #FFF5E6 !important;
            }
        }
    }



    .gifPluginBlock {
        position: absolute;
        bottom: 50px;
        left: 98px;
        background: #fff;
    }

    .cke_browser_webkit {
        border: none;
        padding: 15px;
        /*padding-bottom: 0;*/
    }

    .cke_resizer {
        display: none;
    }

    .cke_bottom {
        padding: 0 11px 5px;
        position: relative;
        border: none;
        background: transparent;
        display: flex;
        align-items: end;
        justify-content: space-between;
    }

    .cke_inner {
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid var(--border-neutral-border-neutral-strong, #CDCDCD);
        background: var(--bkg-neutral-bkg-neutral-default, #FFF);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

        .cke_contents.cke_reset {
            padding: 15px;
        }

        a.cke_button,
        a.cke_button:hover,
        a.cke_button:focus,
        a.cke_button:active {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            height: auto;
        }

        a.cke_bottom {
            background: transparent;
            border: none;
            padding: 0;
        }

        .cke_path {
            display: none;
        }

        .cke_toolgroup {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .cke_button {
                &__emojipanel {
                    order: 1;
                }

                &_upload {
                    order: 2;
                    margin-left: 6px;
                }

                &_gif {
                    order: 3;
                    margin-left: 5px;
                }

                &_link {
                    order: 4;
                    margin-left: 5px;
                }

                &_note {
                    order: 5;
                    margin-left: 9px;
                }

                &:hover,
                &:focus,
                &:active {
                    background: none;
                    border: none;
                    padding: 0;
                    cursor: pointer;
                    height: auto;
                }
            }

            .cke_button_icon {
                font-family: "Font Awesome 5 Pro";
                font-size: 15px;
                color: #A2A0A2;
                padding: 5px;
                background: none !important;

                &-gif {
                    font-family: "Font Awesome 6 Pro";
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;

                    &::after {
                        content: '\e190';
                        // font-family: "Font Awesome 5 Pro";
                        font-weight: 400;
                    }
                }

                &-note {
                    &::before {
                        content: "\f249";
                        color: #FF9F08;
                    }
                }

                &-link {
                    font-family: 'vcIcon';
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;

                    &::after {
                        content: '\e902';
                    }
                }

                &-upload {
                    &::before {
                        content: "\f0c6";
                    }
                }

                &.cke_button__emojipanel_icon {
                    &::before {
                        content: "\f118";
                    }
                }
            }

            .cke_button_arrow,
            .cke_path {
                display: none;
            }
        }

        .cke_button.cke_button_Send,
        .cke_button.cke_button_Send:hover,
        .cke_button.cke_button_Send:focus,
        .cke_button.cke_button_Send:active {
            border-radius: 3px;
            background: var(--bkg_green_default);
            padding: 9px 22px;
            color: var(--text-neutral-text-neutral-ondark, #FFF);
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            float: right;
            margin-bottom: 11px;
            margin-right: 6px;
        }
    }

    .EmojiPickerReact {
        // border: 1px solid var(--border-neutral-border-neutral-strong, #CDCDCD);
        // border: none;
        // box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        // background: #EAECEF;
        // backdrop-filter: blur(50px);
        // border-radius: 4px;

        .epr-header {
            > .epr_-8ygbw8:first-child {
                padding: 10px 8px 0px 8px;
            }

            .epr-search-container {
                input {
                    border-radius: 12px;
                    background: #F7F8FC;
                    height: 37px;
                    padding-left: 39px;

                    &::placeholder {
                        color: var(--Labels-Secondary, rgba(60, 60, 67, 0.60));
                        font-feature-settings: 'clig' off, 'liga' off;
                        // font-family: "SF Pro";
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }

                .epr-icn-search {
                    background-size: 26px;
                    height: 26px;
                    width: 26px;
                    top: 18px;
                    left: 8px;
                }
            }

            .epr-category-nav {
                // border: 1px solid #f00;
                // position: absolute;
                // bottom: -349px;
                // width: 100%;
                .epr-icn-suggested {
                    padding: 0;
                    height: 30px;
                    width: 30px;
                }
            }
        }

        .epr-emoji-img {
            max-width: fit-content;
        }

        .epr-emoji-category-label {
            color: rgba(60, 60, 67, 0.35);
            // font-feature-settings: 'clig' off, 'liga' off;
            // font-family: "SF Pro";
            font-size: 12px;
            font-style: normal;
            font-weight: 590;
            line-height: normal;
            background: #EAECEF;
            height: 20px;
            text-transform: uppercase;
        }

        .epr-emoji-list {
            .epr-emoji-category[data-name="suggested"] {
                display: none;
            }
        }
    }

    .gifPluginBlock {
        position: absolute;
        bottom: 65px;
        left: 33px;
        background: #fff;
        z-index: 9;
        border-radius: 4px;
        background: var(--bkg-neutral-bkg-neutral-tiertiary, #E7E7E7);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        // border: 1px solid var(--border-neutral-border-neutral-strong, #CDCDCD);
        .reactGiphySearchbox-listWrapper {
            height: 253px !important;
        }

        .reactGiphySearchbox-componentWrapper {
            width: 324px !important;
        }

        .reactGiphySearchbox-poweredByGiphy {
            padding-bottom: 5px;
            padding-right: 5px;
            float: right;
        }

        .reactGiphySearchbox-searchForm-form {
            padding: 8px 0px;
            margin-bottom: 0;
            position: relative;

            .reactGiphySearchbox-searchForm-input {
                background: var(--bkg-neutral-bkg-neutral-tiertiary, #E7E7E7);
                padding: 0 16px 0 42px;
                height: 36px;
                border: none;
                color: var(--text-neutral-text-neutral-weak, #A2A0A2);
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;

                &::placeholder {
                    color: var(--text-neutral-text-neutral-weak, #A2A0A2);
                }
            }

            &::before {
                content: '\f002';
                color: var(--icon-neutral-icon-neutral-weak, #A2A0A2);
                text-align: center;
                font-family: "Font Awesome 6 Pro";
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                position: absolute;
                top: 19px;
                left: 17px;
            }
        }
    }

    .vcDrop {
        &-wrap {
            position: absolute;
            bottom: 60px;
            // border-radius: 3px;
            // border: 1px solid var(--border-neutral-border-neutral-strong, #CDCDCD);
            background: var(--bkg-neutral-bkg-neutral-default, #FFF);
            // box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
            border-radius: 4px;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            padding: 0;
            width: 320px;
            z-index: 9;
            color: var(--text_neutral_default) !important;

            &.driveWrap {
                left: 60px;
                bottom: 60px;
            }

            &.linkWrap {
                left: 122px;
                bottom: 60px;
                width: 192px;
                border-radius: 3px;
                border: none;
                background: var(--bkg-neutral-bkg-neutral-default, #FFF);
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);

                &.dropdown.show {
                    button.dropdown-toggle {
                        opacity: 0;
                        box-shadow: none;
                        padding: 0;
                    }

                    .vcDrop-group.dropdown-menu {
                        border: none;
                    }
                }


                .vcDrop-group {
                    max-height: 316px;
                    overflow-y: auto;

                    ul {
                        list-style: none;
                        padding: 0;
                    }


                    .subMenuParent {
                        // position: relative;
                        .subMenu {
                            height: 0;
                            opacity: 0;
                            display: none;
                            // position: absolute;
                            // left: 100%;
                            // bottom: 0;
                        }

                        &:hover {
                            .subMenu {
                                height: 100%;
                                opacity: 1;
                                display: block;
                            }
                        }
                    }
                }

                .vcDrop-group-item {
                    padding: 12px;
                }

                .vcDrop-group.dropdown-menu[x-placement="bottom-start"] {
                    top: 30px !important;
                }
            }
        }

        &-group {

            &-item {
                display: block;
                text-decoration: none;
                padding: 16px;
                color: var(--text-neutral-text-neutral-default, #333);
                // font-family: "Proxima Nova";
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                cursor: pointer;

                i {
                    margin-right: 3px;
                }

                &:hover {
                    border-radius: 3px;
                    background: var(--bkg-neutral-bkg-neutral-tiertiary, #E7E7E7);
                }
                // &:first-child {
                //     &:hover {
                //         border-top-right-radius: 0;
                //         border-top-left-radius: 0;
                //     }
                // }
                // &:last-child {
                //     &:hover {
                //         border-bottom-right-radius: 0;
                //         border-bottom-left-radius: 0;
                //     }
                // }
            }
        }

        &-dropBlock {
            transition: all 0.3s ease-in;

            &-parent {
            }

            &-child {
                &Wrap {
                }
            }

            &.active {
                background: #F7F7F7;

                .vcDrop-dropBlock-parent .fa-solid {
                    transform: rotate(0deg);
                }
            }
            .vcDrop-dropBlock-parent .fa-solid {
                transform: rotate(-90deg);

            }
        }
    }

    .ckeditor_2 .ck.ck-editor__editable .image-inline.ck-widget_selected {
        z-index: 0;
    }
    // END: CK editer

    .rightSideOnly-navBlock {
        display: none;
    }

    &.rightSideOnly {
        position: fixed;
        right: 0;
        bottom: 0;
        max-width: 371px;
        height: 100%;
        border-left: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
        border-right: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);

        .row.g-0 {
            height: 100%;
        }
        // .welcomeScreen .typeChatWrap {
        //     display: none;
        // }

        .chatarea {
            height: 100%;
        }

        .welcomeScreen {
            height: calc(100vh - 63px);
        }

        .chatarea .chatListBlock .chatList-groupBlock {
            height: calc(100vh - 248px);
        }

        .vcDrop-wrap.driveWrap {
            left: 10px;
            bottom: 45px;
        }

        .gifPluginBlock {
            left: 20px;
            bottom: 45px;
            margin-left: -83px;
        }

        .vcDrop-wrap.linkWrap {
            left: 50px;
            bottom: 45px;
        }

        .messagesArea-header_back,
        .chatarea .messagesArea-header_btnArea .btn-addCtm {
            display: block;
        }

        .leftside,
        .rightAreaSlideTrigger {
            display: none;
        }

        .chatarea > .d-flex {
            display: block !important;
            height: 100%;
        }

        .collapsibleChat {
            display: block;
            position: absolute;
            bottom: 0;
            z-index: 99;
            width: 100%;
            border-top: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
            min-height: 60px;
            padding: 0 !important;
            background: #fff;
            .alert-text {
                position: relative;
                bottom: 0px;
                left: 0;
                right: 0;
                margin: 0 auto;
                padding: 3px 10px;
            }

            .typeMsgText {
                margin: 0 5px;
                display: block;
                position: absolute;
                font-size: 12px;
                top: -24px;
                padding-bottom: 5px;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                width: 100%;
                left: -6px;
            }

            .ck-editor.ck-reset.note-active {
                .ck-toolbar {
                    background-color: #fff;
                }

                .ck-editor__main {
                    background: #FFF5E6;
                }

                .ck-toolbar {
                    background: #FFF5E6;
                }
                // .ck-placeholder:before {
                //     font-size: 10px !important;
                // }
            }

            .ck.ck-editor__top {
                margin-bottom: 17px;
            }



            .ck-reset {
                &.ck-editor {
                    flex-direction: row;
                    border: none !important;
                    padding: 7px 15px !important;
                    align-items: end;
                    border-radius: 0;
                    max-width: 371px;

                    .custom-emoji {
                        position: absolute;
                        right: 21px;
                        bottom: 25px;
                        // top: 17px;
                    }

                    .ck-toolbar {
                        padding: 0;

                        &_grouping {
                            min-height: auto;
                        }

                        .ck-toolbar__items {
                            margin-bottom: 0;
                        }

                        .custome_emoji {
                            position: absolute;
                            right: 15px;
                        }

                        .cke_button {
                            max-width: min-content;
                            margin-right: 8px !important;

                            .cke_button_icon {
                                /*color: #5162A8 !important;*/
                                font-size: 14px;

                                &.cke_button__emojipanel_icon {
                                    font-size: 15px;
                                }
                            }

                            &:hover {
                                .cke_button_icon {
                                    /*color: #FF9F08 !important;*/
                                }
                            }

                            .active {
                                &.cke_button_icon {
                                    /*color: #FF9F08 !important;*/
                                }
                            }

                            &.cke_button_Send {
                                display: none !important;
                            }
                        }
                    }

                    .ck-editor__top {
                        // width: 28%;
                    }

                    .ck-editor__main {
                        width: 100%;
                        border: 1px solid #e7e7e7 !important;
                        border-radius: 20px !important;
                        overflow: hidden;

                        .ck-editor__editable {
                            min-height: 42px;
                            overflow-y: auto;
                            margin-right: 42px;
                            position: relative;
                            z-index: 2;
                            padding: 3px 11px;
                            // &::-webkit-scrollbar {
                            //     width: 2px !important;
                            //     background-color: #d9d9d9;
                            //     border-radius: 10px;
                            // }

                            p {
                                margin: 0;
                                line-height: 34px;
                            }
                        }

                        .ck-placeholder:before {
                            font-size: 16px;
                        }
                    }
                }
                &.textmarketing-alert {
                    .ck.ck-editor__main {
                        border: 1px solid var(--border_alert_default) !important;
                        background: #FBECEB !important;
                    }
                }
                &.restrictedText-alert {
                    .ck.ck-editor__main {
                        border: 1px solid var(--border_alert_default) !important;
                        background: #FBECEB !important;
                    }
                }
            }

            .cke_browser_webkit {
                padding: 0;
            }

            .cke_inner {
                box-shadow: none;
                border: none !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 16px;
                border-radius: 0;

                .cke_contents.cke_reset {
                    height: 19px !important;
                    /*Have to change it to 44px right noe having margin issues in ifrem body*/
                    border-radius: 50px;
                    border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                    padding: 12px 25px 12px 12px;
                }

                .cke_button.cke_button_Send {
                    display: none;
                }

                .cke_contents {
                    order: 2;
                }

                .cke_toolgroup .cke_button_icon {
                    // font-size: 13px;
                    padding: 3px;
                }

                .cke_bottom {
                    order: 1;
                    position: static;

                    &.cke_reset_all {
                        padding: 0;
                        width: 40%;
                    }

                    .cke_toolgroup {
                        .cke_button__emojipanel {
                            position: absolute;
                            right: 25px;
                            top: 19px;
                            bottom: auto;

                            .cke_button__emojipanel_icon {
                                font-size: 15px;
                            }
                        }

                        .cke_button_gif,
                        .cke_button_note,
                        .cke_button_link,
                        .cke_button_upload {
                            margin: 0;
                        }

                        .cke_button_icon {
                            color: var(--theme-blueberry-bkg-theme-blueberry-default, #5162A8) !important;
                        }
                    }
                }
            }
            // .note-active {
            //     .cke_inner {
            //         background: rgb(255, 245, 230);
            //     }
            // }

            .note-active .cke_bottom {
                background: #fff !important;
            }

            .EmojiPickerReact {
                position: absolute;
                left: 1px;
                bottom: 60px;
                width: 370px !important;
            }
            &.textmarketing-composerwrap {
                // background: transparent !important;
                border: none;
            }
        }

        .rightSideOnly-navBlock {
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: var(--bkg_neutral_default);

            &_option {
                padding: 16px;
                color: var(--text_neutral_secondary);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                text-decoration: none;
                border-bottom: 2px solid var(--border_neutral_strong);
                width: 50%;
                cursor: pointer;

                &.active {
                    color: var(--text_neutral_default);
                    /*border-bottom: 2px solid  #333;*/
                    /*border-bottom: 2px solid var(--border_theme_snow_default);*/
                }
            }
        }

        .convRight.convRight-open,
        .convRight.convRight-close {
            left: 0;
            max-width: 100%;
            width: 100%;
            flex: 0 0 100%;
            opacity: 1;
        }

        > div.d-flex {
            justify-content: end;
            flex-wrap: wrap;
        }

        .messagesArea,
        .rightside,
        .rightSlide,
        .chatListBlock .chatList-header {
            display: none;
        }

        .chatarea .chatListBlock {
            max-width: 370px;

            &-searchWrap {
                input.searchField {
                    padding-right: 50px;
                }

                .btn-filter {
                    display: block;
                }

                .searchFieldIcon {
                    right: 32px;
                }
            }
        }

        .upgradeBlock {
            display: block;
            margin-bottom: 5px;

            &Wrap {
                position: absolute;
                bottom: 62px;
                display: block;
                z-index: 20;
            }
            .textArea-title {
                .closeupgradeBlock {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
            &-btnAreea {
                text-align: left !important;
                flex-direction: row !important;
            }
        }

        .rightSideOnly-header {
            display: block;
            padding: 16px;
            background: var(--bkg-neutral-bkg-neutral-default, #FFF);
            box-shadow: 0px -1px 0px 0px #E7E7E7 inset;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 56px;

            &_title {
                color: var(--text-neutral-text-neutral-default, #333);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }

            &_iconWrap {
                display: flex;
                align-items: center;

                icon {
                    color: #f00;
                }
            }
        }

        .chatListBlock-searchWrap {
            padding-top: 15px;
        }

        &.chatActive {
            .chatListBlock {
                display: none;
            }

            .messagesArea {
                display: block;
                height: calc(100vh - 63px);

                &-header {
                    &_back {
                        margin-right: 16px;
                        cursor: pointer;
                        color: var(--text_neutral_default)!important;
                    }

                    &_nameArea {
                        width: 100%;

                        .avatar {
                            height: 28px;
                            width: 28px;
                            flex: 0 0 28px;

                            &[data-avatar="1"] {
                                .avatar-group-img-wrap {
                                    height: 28px;
                                    width: 28px;
                                }
                            }
                            // &-group-img-wrap {
                            //     height: 28px;
                            //     width: 28px;
                            // }
                            // &-group-member {
                            //     height: 28px;
                            //     width: 28px;
                            // }
                            // .review-profile-inner-text {
                            //     font-size: 12px;
                            //     height: 26px;
                            //     width: 26px;
                            // }
                        }
                    }

                    &_name {
                        color: #333;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        width: 100%;
                        max-width: 260px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &_btnArea {
                        .extendButton.minimize {
                            display: none;
                        }
                    }
                }

                &-middle {
                    height: calc(100vh - 183px);
                    background: var(--bkg_neutral_secondary) !important;

                    .messagesBox-block {
                        max-width: 60%;
                    }

                    .messagesBox.messagesEditWrap {
                        .messagesBox-block {
                            max-width: 100%;
                        }
                    }
                }
                /*&-footer {
                    display: none;
                }*/

                .smallChatBox-footer {
                    &_fieldWrap .inputField {
                        width: 236px;
                    }
                }
            }
        }

        &.smallScreen {
            height: 100%;
            border: none;

            .welcomeScreen {
                .messagesArea-header_back {
                    margin: 21px 0;
                    padding-right: 10px;
                    cursor: pointer;
                    i.fa-solid.fa-angle-left {
                        color: var(--text_neutral_default)!important;
                    }
                }
            }
            /*This position for setting page
            right: 16px;
            */
            // .row.g-0,
            // .d-flex {
            //     height: 100%;
            // }
            .chatListBlock .chatList-groupBlock {
                height: calc(100vh - 179px);
            }

            .messagesArea {
                // height: 570px;
                height: 91%;

                &-middle {
                    height: calc(100vh - 178px);
                    background: #F8F8F8;

                    .messagesBox-block {
                        max-width: 60%;
                    }
                }

                &-footer {
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    z-index: 9;
                }
            }

            .rightSideOnly-header {
                border-radius: 10px 10px 0px 0px;
                background: var(--theme-default, #5162A8);

                &_title {
                    color: var(--text-neutral-text-neutral-inverse, #FFF) !important;
                }

                &_iconWrap {
                    i.fa-solid {
                        color: var(--text-neutral-text-neutral-inverse, #FFF) !important;
                    }
                }
            }    
            &.minimize  {
                .rightSideOnly-header {
                    cursor: pointer;
                }
            }
        

            .upgradeBlockWrap {
                bottom: 0;
            }

            .welcomeScreen {
                .select2-container .select2-selection--multiple:after {
                    // background: var(--bkg_neutral_default);
                    height: 100%;
                    right: 11px;
                    padding: 18px 5px 19px 5px;
                }
            }

            .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .gifPluginBlock {
                // left: 0;
                // margin-left: 0;
                // position: absolute;
            }

            .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .EmojiPickerReact {
                left: -18px;
                width: 85% !important;
                max-width: 350px !important;
                margin-left: -29px;
            }

            .chatarea .EmojiPickerReact,
            .welcomeScreen .EmojiPickerReact {
                height: 324px !important;
                width: 310px !important;

                em-emoji-picker {
                    max-height: 324px;
                    max-width: 310px;
                }
            }

            .image-wrap {
                &[data-image="4"] .imgBlock,
                &[data-image="2"] .imgBlock {
                    flex-basis: 46%;
                    height: 100px;
                }

                &[data-image="3"] .imgBlock {
                    flex-basis: 29%;
                    height: 100px;
                }
            }
        }

        &.newCustomer {
            .messagesArea-header_btnArea {
                // display: block;

                .extendButton {
                    display: none;
                }
            }
        }

        &.minimize {
            height: 56px;
            border: none;
            /*This position for setting page
            right: 16px;
            */
            .messagesArea,
            .upgradeBlockWrap,
            .collapsibleChat {
                display: none;
            }
        }
    }

    .smallChatBox {
        &Wrap {
            position: absolute;
            bottom: 0;
            right: 9px;
            z-index: 9999;
            height: 100%;
            width: 370px;
            border-radius: 10px 10px 0px 0px;
            // background: var(--border-theme-flamingo-border-theme-flamingo-default, #CC4744);
            box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.20);            

            
            &.customer-minimize {
                .smallChatBox-header {
                    cursor: pointer;
                }
            }

            .collapsibleChat {
                display: block;
                // position: static;
                bottom: 0;
                z-index: 99;
                width: 100%;
                border-top: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                min-height: 60px;
                padding: 0 !important;

                .typeMsgText {
                    margin: 0 5px;
                    display: block;
                    position: absolute;
                    top: -55px;
                    font-size: 12px;
                    width: 100%;
                    left: -5px;
                }

                .ck-reset {
                    &.ck-editor {
                        flex-direction: row;
                        border: none !important;
                        padding: 7px 15px !important;
                        align-items: end;
                        border-radius: 0;
                        position: absolute;
                        bottom: 0;
                        width: 100%;

                        .custom-emoji {
                            position: absolute;
                            right: 16px;
                            bottom: 25px;
                        }

                        .ck-toolbar {
                            padding: 0;

                            &_grouping {
                                min-height: auto;
                            }

                            .ck-toolbar__items {
                                margin-bottom: 0;
                            }

                            .custome_emoji {
                                position: absolute;
                                right: 15px;
                            }

                            .cke_button {
                                max-width: min-content;
                                margin-right: 8px !important;

                                .cke_button_icon {
                                    color: #a2a0a2 !important;
                                    font-size: 14px;

                                    &:hover,
                                    &.active {
                                        color: #ff9f08 !important;
                                    }

                                    &.cke_button__emojipanel_icon {
                                        font-size: 15px;
                                        color: #cc4744 !important;
                                    }
                                }

                                &.cke_button_Send {
                                    display: none !important;
                                }
                            }
                        }

                        .ck-editor__top {
                            width: 15%;
                            margin-bottom: 17px;
                        }

                        .ck-editor__main {
                            width: 85%;
                            border: 1px solid #e7e7e7 !important;
                            border-radius: 20px !important;
                            overflow: hidden;

                            .ck-editor__editable {
                                min-height: 42px;
                                overflow-y: auto;
                                margin-right: 32px;
                                padding: 12px;
                                /*padding: 3px 11px;*/
                                // &::-webkit-scrollbar {
                                //     width: 2px !important;
                                //     background-color: #d9d9d9;
                                //     border-radius: 10px;
                                // }

                                p {
                                    margin: 0;
                                    line-height: 34px;
                                }

                                p::-webkit-scrollbar,
                                p::-webkit-scrollbar-thumb {
                                    opacity: 0;
                                    display: none;
                                }
                            }

                            .ck-placeholder:before {
                                font-size: 16px;
                            }
                        }
                    }
                }

                .cke_browser_webkit {
                    padding: 0;
                }

                .cke_inner {
                    box-shadow: none;
                    border: none !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 16px;
                    border-radius: 0;

                    .cke_contents.cke_reset {
                        height: 19px !important;
                        /*Have to change it to 44px right noe having margin issues in ifrem body*/
                        border-radius: 50px;
                        border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                        padding: 12px 25px 12px 12px;
                    }

                    .cke_button.cke_button_Send {
                        display: none;
                    }

                    .cke_contents {
                        order: 2;
                    }

                    .cke_toolgroup .cke_button_icon {
                        // font-size: 13px;
                        padding: 3px;
                    }

                    .cke_bottom {
                        order: 1;
                        position: static;

                        &.cke_reset_all {
                            padding: 0;
                            width: 40%;
                        }

                        .cke_toolgroup {
                            .cke_button__emojipanel {
                                position: absolute;
                                right: 25px;
                                top: 19px;
                                bottom: auto;

                                .cke_button__emojipanel_icon {
                                    font-size: 15px;
                                }
                            }

                            .cke_button_gif,
                            .cke_button_note,
                            .cke_button_link,
                            .cke_button_upload {
                                margin: 0;
                            }

                            .cke_button_icon {
                                color: var(--theme-blueberry-bkg-theme-blueberry-default, #5162A8) !important;
                            }
                        }
                    }
                }
                // .note-active {
                //     .cke_inner {
                //         background: rgb(255, 245, 230);
                //     }
                // }

                .note-active .cke_bottom {
                    background: #fff !important;
                }

                .gifPluginBlock {
                    bottom: 45px;
                    left: 30px;
                }

                .EmojiPickerReact {
                    position: absolute;
                    left: 0px;
                    bottom: 45px;
                    width: 370px !important;
                }
            }
            // Old Ckediter for customer
            // .collapsibleChat {
            //     display: block;
            //     position: absolute;
            //     bottom: 0;
            //     z-index: 99;
            //     width: 100%;
            //     border-top: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
            //     .gifPluginBlock {
            //         left: 15px;
            //     }
            //     .typeMsgText {
            //         margin: 0 5px;
            //         display: block;
            //         position: absolute;
            //         top: -16px;
            //         font-size: 12px;
            //     }
            //     .cke_browser_webkit {
            //         padding: 0;
            //     }
            //     .cke_inner {
            //         box-shadow: none;
            //         border: none !important;
            //         display: flex;
            //         align-items: center;
            //         justify-content: space-between;
            //         padding: 8px 14px;
            //         border-radius: 0;
            //         .cke_contents.cke_reset {
            //             height: 22px !important; /*Have to change it to 44px right noe having margin issues in ifrem body*/
            //             border-radius: 50px;
            //             border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
            //             padding: 10px 25px 10px 16px;
            //         }
            //         .cke_button.cke_button_Send {
            //             display: none;
            //         }
            //         .cke_contents {
            //             order: 2;
            //         }
            //         .cke_bottom {
            //             order: 1;
            //             position: static;
            //             &.cke_reset_all {
            //                 padding: 0;
            //                 width: 17%;
            //             }
            //             .cke_toolgroup {
            //                 margin-top: 5px;
            //                 .cke_button__emojipanel {
            //                     position: absolute;
            //                     right: 22px;
            //                     top: 17px;
            //                     bottom: auto;
            //                 }
            //                 .cke_button_gif,
            //                 .cke_button_note,
            //                 .cke_button_link,
            //                 .cke_button_upload {
            //                     margin: 0;
            //                 }
            //                 .cke_button_icon {
            //                     color: #A2A0A2 !important;
            //                     &.cke_button__emojipanel_icon {
            //                         color: #CC4744 !important;
            //                         padding: 4px 5px;
            //                         font-size: 16px;
            //                     }
            //                 }
            //             }
            //         }
            //         .cke_toolgroup .cke_button_icon {
            //             font-size: 13px;
            //             padding: 6px 3px;
            //         }
            //     }
            //     .note-active {
            //         .cke_inner {
            //             background: rgb(255, 245, 230);
            //         }
            //     }
            // }

            .chatarea {
                height: 100%;
                width: 100%;
            }

            .messagesArea {
                position: static;

                &-header {
                    padding: 0;
                    display: block;

                    &_nameArea,
                    &_avGroup {
                        display: block;
                    }
                }
            }

            &.customer-extand {
                .smallChatBox-header {
                    border-radius: 0;
                }
            }

            &.customer-minimize {

                .collapsibleChat,
                .smallChatBox-middle {
                    display: none;
                }
            }
        }

        &-header {
            padding: 19px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: var(--border-theme-flamingo-border-theme-flamingo-default, #CC4744);
            border-radius: 10px 10px 0px 0px;

            &_title {
                color: #fff;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px
            }

            &_actions {
                a {
                    color: #fff;
                    margin: 0 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &-middle {
            background: #fff;

            &_nameArea {
                padding: 16px 24px;
                color: var(--text-neutral-text-neutral-default, #333);
                text-overflow: ellipsis;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                display: flex;
                align-items: center;
                width: 100%;

                &-imgWrap {
                    width: 60px;
                    max-height: 40px;
                    overflow: hidden;
                    margin-right: 8px;
                    flex: 0 0 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &-nameWrap {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }

            .messagesArea-header {
                border: none;
            }

            &_messagesWrap {
                overflow-y: scroll;
                height: 450px;
                padding: 16px 24px;
                background: #F8F8F8;
            }

            .messagesArea-middle {
                height: calc(100vh - 196px);
                background: #F8F8F8;

                .chat-deleted {
                    margin-right: 8px;
                }
            }

            .messagesBox {
                align-items: start;
                display: flex;
                justify-content: end;
                margin: 16px 0;

                .messagesBox-block {
                    max-width: 96%;
                }

                .messages {
                    border-radius: 8px;
                    background: var(--bkg-theme-default, #CC4744);
                    color: var(--text-neutral-inverse, #FFF);
                    padding: 10px 12px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    margin: 2px 0;
                }

                .time {
                    color: var(--text-neutral-secondary, #6D6E6F);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    text-align: right;

                    .chechIcon::before {
                        font-family: "Font Awesome 5 Pro";
                        content: '\f058';
                        color: #588C54;
                        margin-left: 5px;
                    }
                }

                .name {
                    color: var(--text-neutral-text-neutral-default, #333);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                }

                &.recived {
                    justify-content: start;

                    .messages {
                        background: var(--bkg-neutral-bkg-neutral-tiertiary, #E7E7E7);
                        color: var(--text-neutral-default, #333);
                    }

                    .time {
                        text-align: left;
                    }
                }
            }
        }

        &-footer {
            padding: 8px 16px;
            background: #fff;
            border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            width: 100%;

            &_actions {
                a {
                    margin: 0 4px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            &_fieldWrap {
                position: relative;

                .inputField {
                    padding: 12px 36px 11px 12px;
                    border-radius: 50px;
                    border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                    color: var(--text-neutral-text-neutral-weak, #A2A0A2);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    width: 290px;
                }

                .smileIcon {
                    position: absolute;
                    right: 12px;
                    top: 12px;
                }
            }
        }
    }

    .ckeditor_2 .ck.ck-editor__editable .image-inline {
        z-index: 0;
    }

    .upgradeBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        background: var(--bkg-blue-bkg-blue-weak, #BDDDF2);
        padding: 8px 16px;

        &Wrap {
            background: var(--bkg-neutral-bkg-neutral-default, #FFF);
            padding: 8px;
            width: 100%;
            box-shadow: 10px -5px 10px 0px rgba(0, 0, 0, 0.15);
            display: none;
        }

        .textArea {
            &-title {
                color: var(--text-neutral-text-neutral-default, #333);
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 4px;

                img,
                svg {
                    margin-right: 8px;
                }
            }

            &-info {
                color: var(--text-neutral-text-neutral-default, #333);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 0;
            }
        }

        .upgradeBlock-btnAreea {
            text-align: right;
            display: flex;
            align-items: end;
            flex-direction: column;

            .closeupgradeBlock {
                margin-bottom: 6px;
                padding-right: 5px;
                cursor: pointer;
            }

            a {
                display: inline-block;
                cursor: pointer;
            }
        }

        &-btn {
            border-radius: 3px;
            border: 1px solid var(--border-components-button-border-components-button-default, #CDCDCD);
            background: var(--bkg-components-button-bkg-components-button-neutral-default, #FFF);
            color: var(--text-neutral-text-neutral-default, #333);
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-decoration: none;
            white-space: nowrap;
            padding: 8px 16px;

            &:hover,
            &:focus-visible {
                border: 1px solid var(--border-components-button-border-components-button-default, #CDCDCD);
                outline: none;
            }
        }
    }
    // Right side css - krishna kalaria (25/12/23)
    .rightside {
        max-width: 280px;

        .dropdown-toggle {
            white-space: normal;
        }

        .businessLocation {
            &Wrap {
            }

            &Block {
                display: flex;
                align-items: start;

                .iconWrap {
                    margin-right: 7px;
                    .fa-store {
                        color: var(--text_neutral_default) !important;
                    }
                }

                .locationList {
                    margin: 0;

                    .location {
                        color: var(--Text-Primary, #333);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 23px;
                        margin: 8px 0;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:nth-child(n + 9) {
                            margin-bottom: 5px;
                        }
                    }

                    .locationToogle {
                        color: var(--text-neutral-text-neutral-secondary, #6D6E6F);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .right-mobileHeaderDetailBlock {
        display: none;
    }

    .rightside-wrapper {
        background-color: white;
        border-left: none;
        height: calc(100vh - 50px);
        overflow-y: hidden;

        .right-mobileHeader {
            &DetailBlock {
                align-items: center;
                justify-content: start;
                border-bottom: 1px solid var(--border_neutral_default);
            }

            &-back {
                padding: 15px;

                .fa-angle-left {
                    // font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            &-detail {
                width: calc(100% - 45px);
                color: var(--text-neutral-text-neutral-default);
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }



        &.client-details {
             .services-list-wrap {
                margin-bottom: 28px;
             }

            .personal-info {
                padding: 16px;
            }

            .bottom-content-wrap {
                padding-top: 0;
                margin-top: 10px;
                // max-height: calc(100vh - 284px);
            }
        }

        &.employee-detail {
            .personal-info {
                padding: 16px;
                padding-bottom: 8px;
            }

            .bottom-content-wrap {
                padding-top: 0;
            }
        }

        .bottom-content-wrap {
            overflow-y: auto;
            padding: 16px 14px;
            /*height: calc(100vh - 283px);*/
            /*height: calc(100vh - 215px);*/
        }

        .services-list-wrap {
             margin-bottom: 0px;

            .dropdown {
                .dropdown-toggle {
                    &::after {
                        display: none;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.upcoming-appt-wrap {
                .appointment-detail {
                    display: inline-block;
                    padding: 0;
                    width: 100%;
                    // &:hover {
                    //     background: #F8F8F8;
                    // }
                    .dropdown-toggle {
                        padding: 0;
                    }

                    .service-wrap {
                        background-color: #0EB9A4;
                        padding: 13px 4px 22px;
                        border-left: 8px solid;
                        border-radius: 5px;
                        position: relative;
                        width: 100%;

                        h3,
                        p {
                            color: #333;
                        }

                        &::before,
                        &::after {
                            content: '';
                            background-color: #7AA6BA;
                            position: absolute;
                            width: 100%;
                            left: 0px;
                            display: none;
                        }

                        &::before {
                            height: 10px;
                            top: 0;
                            border-top-right-radius: 5px;
                        }

                        &::after {
                            height: 20px;
                            bottom: 0;
                            border-bottom-right-radius: 5px;
                        }

                        h3 {
                            font-weight: 600;
                            font-size: 15px;
                        }

                        p {
                            margin-bottom: 0;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                        }

                        &.class-wrap {
                            background-color: #E6499A;
                            padding: 5px 4px 6px;
                            color: white;
                            margin-bottom: 6px;

                            &::before,
                            &::after {
                                display: none;
                            }

                            .bottom-icons {
                                color: black;

                                .count {
                                    background-color: white;
                                    border-radius: 50%;
                                    padding: 3px;
                                    height: 25px;
                                    width: 25px;
                                    display: flex;
                                    justify-content: center;
                                    margin-top: 0;

                                    span {
                                        border: 1px solid #CDCDCD;
                                        background-color: transparent !important;
                                        color: black;
                                        border-radius: 50%;
                                        padding: 2px 6px;
                                        margin-top: 0;
                                    }
                                }

                                span {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }

                    .bottom-icons {
                        text-align: right;
                        display: flex;
                        justify-content: flex-end;
                        // align-items: flex-start;
                        align-items: center;

                        &Wrap {
                            display: flex;
                            align-items: end;
                            justify-content: end;
                        }

                        .serviceIcon {
                            margin: 0 2px;
                            height: 18px;
                            width: 18px;
                            display: inline-block;
                            font-size: 12px;
                            text-align: center;
                            line-height: 18px;
                            padding: 0;

                            &.name-icon {
                                padding: 0;
                            }

                            &:last-child {
                                margin-right: 4px;
                            }

                            &-padding {
                                &.name-icon {
                                    padding: 0 2px;
                                    min-width: 22px;
                                }
                            }

                            i {
                                font-size: 17px;
                                vertical-align: bottom;
                            }

                            &.name-icon {
                                width: auto;
                            }

                            &.singleName {
                                width: 18px;
                            }

                            &.vIcon {
                                width: auto;
                            }

                            &.dollarSign {
                                line-height: 14px;

                                .fa-dollar-sign {
                                    font-size: 11px;
                                }
                            }
                        }

                        &::after {
                            content: '';
                            background-color: black;
                            width: 60px;
                            position: absolute;
                            bottom: 4px;
                            height: 2px;
                            z-index: 9;
                            border-radius: 1px;
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        span {
                            margin-right: 4px;
                            /*&:last-child,*/
                            color: #333;

                            &.name-icon {
                                // margin-right: 0;
                                background-color: #333333;
                                color: white;
                                border-radius: 3px;
                                padding: 1px 5px;
                                font-size: 12px;
                                font-weight: 600;
                                // line-height: 16px;

                                &.radius-style {
                                    border-radius: 50%;
                                }

                                i.fas {
                                    color: white !important;
                                }
                            }
                        }
                    }
                }
            }

            &.purchases-wrap {
                .appointment-detail {
                    padding: 8px;
                    position: relative;

                    &:hover {
                        background: #F8F8F8;
                    }

                    h3 {
                        font-size: 15px;
                    }

                    .dropdown {
                        position: absolute;
                        right: 0;
                        display: none;

                        &-toggle {
                            cursor: pointer;
                            margin: 1px 8px 0 1px;
                        }
                    }

                    &:hover {
                        .dropdown {
                            display: block;
                        }
                    }
                }
            }

            &.pastAppointmentsWrap {
                .appointment-detail {
                    position: relative;

                    &:hover {
                        background: #F8F8F8;
                    }

                    .dropdown {
                        position: absolute;
                        right: 0;
                        display: none;

                        &-toggle {
                            cursor: pointer;
                            margin: 1px 8px 0 1px;
                        }
                    }

                    &:hover {
                        .dropdown {
                            display: block;
                        }
                    }
                }
            }
        }

        .profile-wrapper {
            .profile-image {
                .avatar {
                    margin-bottom: 4px !important;
                    cursor: pointer;
                }
            }

            .name-wrap {
                h3 {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 7px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                    cursor: pointer;
                }
            }

            .membership {
                &-wrap {
                    margin: 0 auto;
                    border-radius: 3px;
                    background-color: var(--bkg-neutral-bkg-neutral-strong, #333);
                    max-width: 100%;
                    display: inline-block;
                    padding: 2px 8px;
                    margin-bottom: 8px;
                }

                &-nameWrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        color: var(--icon-neutral-icon-neutral-ondark, #FFF);
                        font-size: 14px;
                        font-weight: 400;
                        margin-right: 5px;
                    }
                }

                &-name {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                    color: var(--text-neutral-text-neutral-inverse, #FFF);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin: 0;
                }
            }
            // .membership-name {
            //     background-color: #333333;
            //     padding: 2px 8px;
            //     border-radius: 3px;
            //     color: white;
            //     display: inline-flex;
            //     align-items: center;
            //     margin-bottom: 8px;
            //     svg {
            //         margin-right: 5px;
            //         // margin-top: 2px;
            //         flex: 0 0 14px;
            //     }
            //     h5 {
            //         font-size: 12px;
            //         font-style: normal;
            //         font-weight: 400;
            //         line-height: 16px;
            //         text-overflow: ellipsis;
            //         overflow: hidden;
            //         white-space: nowrap;
            //         width: 115px;
            //     }
            // }
        }

        .contact-wrap {
            a {
                font-size: 15px;
                line-height: 20px;
                text-decoration: none;
                display: flex;
                align-items: baseline;
                word-break: break-all;
                width: 100%;
                margin-bottom: 8px;
                position: relative;
                // height: 20px;

                // &:last-child {
                //     margin-bottom: 0;
                // }

                span {
                    padding-left: 8px;
                }

                i,
                svg {
                    /*margin-right: 7px;*/
                    height: 16px;
                    width: 16px;
                    // position: absolute;
                    // left: 4px;
                    // top: 3px;
                }

                .contact-icon {
                }
            }

            .norights {
                pointer-events: none;
            }

            &-emailText {
                // var(--text_blue_default)!important
                //color: #2a90d6;
                &:hover {
                    text-decoration: underline;
                }
            }

            @media screen and (min-width: 768px) {
                &-callWrap {
                    //pointer-events: none;
                }
            }
        }

        .block-status {
            .active-status {
                padding: 2px 8px;
                border-radius: 16px;
                font-size: 12px;
                line-height: 16px;
                color: white;
                display: inline-block;
                text-transform: capitalize;
                font-weight: 400;

                &.status {
                    &-red {
                        background-color: #D83F34;
                    }

                    &-yellow {
                        background-color: #FF9F08;
                    }

                    &-green {
                        background-color: var(--bkg_green_default);
                    }

                    &-gray {
                        background-color: #777777;
                    }
                }
            }
        }

        .appointment-detail {
            padding: 12px 8px;
            display: flex;
            align-items: flex-start;
            //gap: 8px;
            position: relative;
            cursor: pointer;

            &:hover {
                background: #F8F8F8;
            }

            &:has(.service-wrap.serviceCard) {
                &:hover {
                    background: transparent;
                }
            }


            .service-icon {
                padding: 8px;
                //gap: 8px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 32px;
                flex: 0 0 32px;
                margin-right: 8px;

                &.icon {
                    display: flex;

                    &-green {
                        background-color: #A4E8B9;
                    }

                    &-greenIou {
                        background-color: #68C4A0;
                    }

                    &-blue {
                        background-color: #96C8EB;
                    }

                    &-purple {
                        background-color: #A391C5;
                    }

                    &-orange {
                        background-color: #F69681;
                    }

                    &-light-purple {
                        background-color: #C3BFDF;
                    }

                    &-pink {
                        background-color: #EFBBD6;
                    }

                    &-yellow {
                        background-color: #CBDD4E;
                    }
                }

                i {
                    font-size: 15px;
                }
            }

            .service-wrap {
                max-width: 83%;
                width: 78%;
                cursor: pointer;

                &.serviceCard {
                    max-width: 100%;
                    width: 100%;
                    min-height: 90px;

                    .serviceProviderName {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .addOn {
                        i {
                            margin: 0 3px;
                        }
                    }

                    &.status {
                        &-Accepted {
                            border-color: var(--bkg_status_accepted);
                            background: var(--bkg_status_accepted);
                        }

                        &-Show {
                            border-color: var(--bkg_status_show);
                            background: var(--bkg_status_show);
                        }

                        &-NoShow {
                            border-color: var(--bkg_status_noshow);
                            background: var(--bkg_status_noshow);
                        }

                        &-ServiceInProgress {
                            border-color: var(--bkg_status_inprogress);
                            background: var(--bkg_status_inprogress);
                        }

                        &-ServiceComplete {
                            border-color: var(--bkg_status_complete);
                            background: var(--bkg_status_complete);
                        }

                        &-AwaitingConfirmation {
                            border-color: var(--bkg_status_awaiting);
                            background: var(--bkg_status_awaiting);
                        }

                        &-Confirmed {
                            border-color: var(--bkg_status_confirmed);
                            background: var(--bkg_status_confirmed);
                        }

                        &-ReadytoStart {
                            border-color: var(--bkg_status_ready);
                            background: var(--bkg_status_ready);
                        }

                        &-NeedAcceptance {
                            border-color: var(--bkg_status_requested);
                            background: var(--bkg_status_requested);
                        }

                        &-Deleted {
                        }

                        &-Cancel {
                        }

                        &-Denied {
                        }

                        &-PersonalOff {
                        }
                    }

                    &.cleanupTime {
                        &::before {
                            display: none;
                        }

                        &::after {
                            display: block;
                            background-color: rgba(0,0,0,0.15);
                            left: -7px;
                            border-bottom-left-radius: 5px;
                            right: auto;
                            width: calc(100% + 7px);
                        }
                    }
                }

                .dropdown-toggle {
                    padding: 0;
                }

                h3 {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 0;
                    // max-width: 90%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 400;
                }

                .date-wrap {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }

            .date {
                padding: 8px 0px 4px;
                font-size: 12px;
                line-height: 16px;
            }
        }

        .accordion-body {
            padding: 0;
        }

        .accordion-item {
            background-color: transparent;
            border: none;

            .accordion-header {
                position: relative;

                .accordion-button {
                    padding: 8px 0;
                    background-color: transparent;
                    color: #333333;
                    box-shadow: inset 0 calc(-1 * 1px) 1px #cdcdcd;

                    span {
                        text-transform: uppercase;
                        font-size: 12px;
                        // font-variant: all-small-caps;
                        font-weight: 500;
                        line-height: 16px;
                    }

                    &.collapsed {
                        &::after {
                            content: '\f0da';
                        }
                    }

                    &::after {
                        content: '\f0d7';
                        position: absolute;
                        top: 50%;
                        right: -2px;
                        transform: translateY(-50%);
                        width: 16px;
                        height: 16px;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 900;
                        font-size: 16px;
                        background-image: none;
                    }
                }
            }
        }

        .carousel-control {

            &-prev,
            &-next {
                width: auto;
                opacity: 1;
            }

            &-prev {
                left: -5px;
            }

            &-next {
                right: 5px;
            }

            &-prev-icon,
            &-next-icon {
                background-image: none;
                width: auto;
                height: auto;

                &::before {
                    position: absolute;
                    top: 50%;
                    color: #6D6E6F;
                    transform: translateY(-50%);
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 900;
                }
            }

            &-prev-icon {

                &::before {
                    content: '\f053';
                }
            }

            &-next-icon {

                &::before {
                    content: '\f054';
                }
            }
        }

        .popup-note-wrap {
            width: 100%;

            .note {
                background-color: #FBECEB;
                border: 1px solid #D83F34;
                border-radius: 8px;
                padding: 8px;
                margin: 0 12px;

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 16px;
                    word-break: break-word;
                }
            }
        }

        .member-list-wrapper {
            .personal-info {
                padding: 16px 16px 0;
            }

            .bottom-content-wrap {
                padding-top: 0;
                height: calc(100vh - 162px);
            }

            h3 {
                margin-bottom: 0 !important;
            }

            .right-icon {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            #groupNameInput {
                border: 1px solid;
                padding: 8px;
                border-radius: 3px;
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
                width: 100%;
                // background: ;

                & + .right-icon {
                    margin-right: 1px;
                }
            }

            .name-wrap {
                display: flex;
                margin-bottom: 24px;
                gap: 12px;
                justify-content: center;

                h3 {
                    font-size: 20px;
                }

                .right-icon {
                    margin-left: 9px;
                    opacity: 0.8;

                    &:hover {
                        opacity: 1;
                    }
                }

                .groupNameWrap {
                    max-width: 100%;
                }
            }

            .member-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;

                h3 {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }

                .right-icon {
                    margin-right: 4px;
                    opacity: 0.8;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .profile-image {
                margin-bottom: 8px;

                .avatar-group {
                    // left: 50%;
                    // transform: translateX(-50%);
                    margin: 0 auto;
                }
            }

            .chatList {

                &-person {
                    padding: 4px 0;
                    border-bottom: none;
                    margin-bottom: 8px;

                    .avatar {
                        height: 28px;
                        width: 28px;
                        flex: 0 0 28px;
                        border: 1px solid var(--Border-Outline, #CDCDCD) !important;
                        margin-right: 8px;

                        .review-profile-inner-text {
                            font-size: 12px;
                            height: 26px;
                            width: 26px;
                            font-weight: 500;
                        }
                    }

                    &_name {
                        font-weight: 400;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 90%;
                    }

                    &_number {
                        text-align: left !important;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                    }

                    &_right {
                        display: inline-block;
                        text-align: left;
                        width: 85%;
                    }

                    &_dotMenu {
                        position: absolute;
                        right: 4px;
                        top: 5px;
                        text-decoration: none;
                        color: var(--icon-neutral-icon-neutral-default, #333);
                        // display: none;

                        .dropdown-toggle {
                            padding: 4px 5px 0 5px;
                            display: block;
                            border: none;

                            .fa-solid.fa-ellipsis-vertical {
                                color: var(--text_neutral_default);
                            }

                            &:after {
                                display: none;
                            }

                            &:active,
                            &:focus,
                            &:hover,
                            &.show {
                                border: none;
                                padding: 4px 5px 0 5px;
                                box-shadow: none;
                                background-color: transparent;
                                border-color: transparent;
                                color: transparent;
                                height: auto;
                            }
                        }

                        .dropdown {
                            &-menu {
                                border-radius: 3px;
                                border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                                background: var(--bkg-neutral-bkg-neutral-default, #FFF);
                                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
                                padding: 0;
                            }

                            &-item {
                                padding: 12px;

                                &:hover {
                                    background: #F8F8F8;
                                }
                            }
                        }
                    }
                    // &_dotMenu {
                    //     .btn {
                    //         border: none;
                    //         &.show {
                    //             display: flex !important;
                    //         }
                    //     }
                    //     .dropdown-toggle {
                    //         padding: 0;
                    //         display: block;
                    //         &:after {
                    //             display: none;
                    //         }
                    //         &:active,
                    //         &:focus,
                    //         &:hover,
                    //         &.show {
                    //             border: none;
                    //             padding: 0;
                    //             box-shadow: none;
                    //             background-color: transparent;
                    //             border-color: transparent;
                    //             color: transparent;
                    //             height: auto;
                    //         }
                    //     }
                    // }
                }
            }
            /*Edit Group detail*/
            &.edit-group {
                .name-wrap {
                    gap: 8px;
                    margin-bottom: 25px;
                }

                .bottom-content-wrap {
                    .members-list-wrap {
                        padding: 0 16px;

                        .member-header {
                            .right-icon {
                                margin-right: 1px;
                            }
                        }
                    }
                }
            }
        }
    }

    .sent {
        &.messagesBox {
            .image-message {
                max-width: 400px !important;

                .messages {
                    padding: 0;

                    .image-wrap {
                        &[data-image="9"] {
                            padding: 10px 10px 10px 12px;
                        }
                    }
                }
            }
        }
    }

    .image-wrap {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        gap: 10px;
        margin-bottom: 10px;

        .imgBlock {
            flex-basis: 100%;
            height: 180px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                vertical-align: middle;
            }

            .moreImgText {
                position: absolute;
                left: auto;
                right: auto;
                height: 100%;
                top: 0;
                width: 100%;
                font-size: 40px;
                vertical-align: middle;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &[data-image="2"] {
            .imgBlock {
                flex-basis: 47%;
                height: 180px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                }
            }
        }

        &[data-image="3"] {
            .imgBlock {
                flex-basis: 30%;
                height: 180px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                }
            }
        }

        &[data-image="4"] {
            .imgBlock {
                flex-basis: 47%;
                height: 180px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                }
            }
        }


        &[data-image="5"],
        &[data-image="6"] {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            .imgBlock {
                flex-basis: 32%;
                height: 180px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                }
            }
        }

        &[data-image="7"],
        &[data-image="8"],
        &[data-image="9"] {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            .imgBlock {
                flex-basis: 33%;
                height: 180px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                }
            }
        }
    }

    @media screen and (min-width: 1400px) {
        .image-wrap {

            &[data-image="5"],
            &[data-image="6"],
            &[data-image="7"],
            &[data-image="8"],
            &[data-image="9"] {
                .imgBlock {
                    flex-basis: 30%;
                    height: 180px;
                }
            }
        }
    }

    @media screen and (min-width: 1180px) and (max-width: 1399px) {
        .image-wrap {

            &[data-image="5"],
            &[data-image="6"],
            &[data-image="7"],
            &[data-image="8"],
            &[data-image="9"] {
                .imgBlock {
                    flex-basis: 29%;
                    height: 117px;
                }
            }
        }
    }

    @media screen and (min-width: 1025px) and (max-width: 1179px) {
        .image-wrap {

            &[data-image="5"],
            &[data-image="6"],
            &[data-image="7"],
            &[data-image="8"],
            &[data-image="9"] {
                .imgBlock {
                    flex-basis: 29%;
                    height: 70px;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .image-wrap {

            &[data-image="5"],
            &[data-image="6"],
            &[data-image="7"],
            &[data-image="8"],
            &[data-image="9"] {
                gap: 5px;

                .imgBlock {
                    flex-basis: 31%;
                    /*height: 50px;*/
                }
            }
        }
    }
}

.avatar {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: #CDCDCD !important;
    border-radius: 10em;
    // position: absolute;
    top: 0;
    left: 0;
    width: 2.8em;
    height: 2.8em;
    position: relative;

    img {
        width: 100%;
        // height: 100%;
    }

    .review-profile-inner-text {
        // color: var(--text_neutral_default)!important;
        color: #333;
    }

    &-sm {
        height: 30px;
        width: 30px;
        flex: 0 0 30px;
        border-radius: 15px;

        .avatar-group-img-wrap-text {
            font-size: 8px;
            line-height: 12px;
        }

        .avatar-group-img-wrap {
            height: 30px;
            width: 30px;

            .review-profile-inner-text {
                font-size: 8px;
            }
        }


        &[data-avatar="1"] .avatar-group-img-wrap {
            height: 31px;
            width: 31px;
            top: -1px;
            left: -1px;

            img {
                height: 30px;
                width: 30px;
            }
        }
    }

    &-md {
        height: 40px;
        width: 40px;
        flex: 0 0 40px;
        border-radius: 20px;
    }

    &[data-avatar="1"] {
        .review-profile-inner-text {
            font-size: 15px;
        }
    }
    // &-sm {
    //     &[data-avatar="1"] {
    //         .review-profile-inner-text {
    //             font-size: 10px;
    //         }
    //     }
    // }

    &[data-avatar="2"] {
        .avatar-group-img-wrap {
            &:first-child {
                left: 0 !important;
                width: 50%;
                height: 41px;
                img {
                    height: 100%;
                    left: 0;
                }
            }
            &:nth-child(2) {
                left: 50% !important;
                width: 50%;
                height: 41px;
                img {
                    height: 100%;
                    left: 0 !important;
                }
            }
        }            
    }

    &-group {
        background: var(--bkg-blue-bkg-blue-weak, #BDDDF2);
        border: 1px solid var(--Blue-Light, #96C8EB);
    }

    &-group-img-wrap,
    &-group-member {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        img {
            height: 40px;
            width: 40px;
            max-width: 100%;
            vertical-align: top;
            object-fit: cover;
            overflow-clip-margin: unset;
        }
    }

    &-group-img-wrap {
        overflow: hidden;
        /*border: 1px solid #96C8EB !important;*/
        background: var(--bkg-blue-bkg-blue-weak, #BDDDF2);
        height: 41px;
        width: 41px;
        top: -1px;
        left: -1px;

        div {
            height: 100%;

            .review-profile-inner-text {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
            }
        }

        &-text {
            color: var(--Text-Primary, #333);
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            text-align: left;
            padding-left: 2px;
        }
    }

    &-group {
        top: 0;
        left: 0;
        overflow: hidden;
        position: relative;
        // z-index: 1;

        &[data-avatar="2"] .avatar-group-img-wrap:first-child {
            left: -25%;
        }

        &[data-avatar="2"] .avatar-group-img-wrap:nth-child(2) {
            left: 50%;
        }

        &[data-avatar="2"] .avatar-group-img-wrap:nth-child(2) .avatar-group-member {
            left: -25%;
        }

        &[data-avatar="3"] .avatar-group-img-wrap:first-child {
            left: 25%;
        }

        &[data-avatar="3"] .avatar-group-img-wrap:nth-child(2),
        &[data-avatar="3"] .avatar-group-img-wrap:nth-child(3) {
            left: 0;
            width: 50%;
            height: 50%;
        }

        &[data-avatar="2"] {
            .avatar-group-img-wrap {
                &:nth-child(2) {
                    border-left: 1px solid var(--Blue-Light, #96C8EB);
                }
            }
        }
        &[data-avatar="2"] .avatar-group-img-wrap {
            height: 102% !important;
        }


        &[data-avatar="3"] {
            .avatar-group-img-wrap {
                top: 0;
                left: 0;

                img {
                    width: 100%;
                    height: 100%;
                }

                &:nth-child(2) {
                    // border-bottom: none;
                    border-right: 1px solid var(--Blue-Light, #96C8EB);
                    border-bottom: 1px solid var(--Blue-Light, #96C8EB);
                }

                &:nth-child(3) {
                    top: 50%;
                    border-right: 1px solid var(--Blue-Light, #96C8EB);
                }
            }
        }

        &[data-avatar="4"] {
            .avatar-group-img-wrap {
                border: none;

                img {
                    width: 100%;
                    height: 100%;
                }

                &:nth-child(1) {
                    top: 0;
                    left: 0;
                    border-right: 1px solid var(--Blue-Light, #96C8EB);
                    border-bottom: 1px solid var(--Blue-Light, #96C8EB);
                }

                &:nth-child(2) {
                    top: 0;
                    border-bottom: 1px solid var(--Blue-Light, #96C8EB);
                }

                &:nth-child(3) {
                    left: 0;
                    border-right: 1px solid var(--Blue-Light, #96C8EB);
                }

                &:nth-child(4) {
                    left: 0;
                }
            }
        }

        &[data-avatar="4"] .avatar-group-img-wrap {
            width: 50%;
            height: 50%;
        }

        &[data-avatar="4"] .avatar-group-img-wrap:nth-child(2),
        &[data-avatar="4"] .avatar-group-img-wrap:nth-child(4) {
            left: 50%;
        }

        &[data-avatar="4"] .avatar-group-img-wrap:nth-child(3),
        &[data-avatar="4"] .avatar-group-img-wrap:nth-child(4) {
            top: 50%;
        }
    }
}

.image-popup-wrap {
    .modal-header {
        display: block;

        .modal-title {
            display: block;
            width: 100%;
        }

        .imgModalBtnWrap {
            display: flex;
            align-items: center;
        }
    }

    .modal-content {
        background-color: white;

        .image-gallery {
            &-icon {
                color: #6D6E6F;
                filter: none;

                svg {
                    width: 10px;
                    height: auto;
                }

                @media (max-width: 767px) {
                    display: none;
                    // color: var(--text_neutral_inverse);
                    // padding: 10px;
                    // background: rgba(0, 0, 0, 0.7);
                }
            }

            &-play-button,
            &-fullscreen-button {
                display: none;
            }

            &-image {
                // width: 499px;
                // height: 479px;
                margin: 0 auto;

                @media(max-width: 767px) {
                    width: 100%;
                    height: 100%;
                }
            }

            &-slide-wrapper {
                margin-bottom: 2vh;
                height: 58vh;

                @media (max-width: 767px) {
                    height: 100vh;
                }
            }

            &-thumbnails {
                margin-bottom: 2vh;
            }

            &-thumbnails-wrapper {
                height: 38vh;

                @media (max-width: 767px) {
                    display: none;
                }
            }
            // &-slide {
            //     max-width: 60%;
            //     margin: 0 auto;
            // }
            &-image {
                width: 60%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            &-swipe,
            &-slides,
            &-slide,
            &-image {
                height: 100%;
            }

            &-thumbnail {
                margin-left: 0;
                border: none;
                width: 100px;
                height: 100px;

                &:not(:last-child) {
                    margin-right: 8px;
                }

                &:hover {
                    border: none;
                }

                &.active {
                    border: none;
                }
            }

            &-thumbnail-inner {
                height: 100px;
                width: 100px;
            }

            &-thumbnail-image {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }

    .modal-body {
        height: calc(100vh - 50px);
        overflow: hidden;
    }

    .modal-header {
        display: inline-flex;
        padding: 15px 16px 15px;
        justify-content: flex-end;
        align-items: center;
        background-color: #F8F8F8;
        // margin-top: 50px;

        @media (max-width: 767px) {
            margin-top: 0px;
        }

        .modal-title {
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            width: 100%;
        }

        .download-close,
        .btn-close {
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }

        .download {
            cursor: pointer;

            i {
                width: 16px;
                margin-right: 12px;
                display: flex;
            }
        }

        .btn-close {
            background-image: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;

            &::before {
                content: '\f00d';
                font-size: 18px;
                color: #333;
                height: 18px;
                width: 18px;
                display: inline-block;
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;
            }
        }
    }
}

.customerChatWindow {
    .vConnectMainWrap .smallChatBox-middle .messagesArea-middle {
        height: calc(100vh - 195px);
    }
    // .image-popup-wrap {
    //     margin-top: 50px;
    // }
    .image-popup-wrap {
        z-index: 9999;
    }
    .mobileListmenuWrap {
        .mobileListmenu.dropdown {
            display: none;
        }

    }
    .vConnectMainWrap {
        .chatarea {
            .messagesArea-middle {
                .messagesBox.messagesEditWrap {
                    .gifPluginBlock {
                        left: 60px !important;
                    }
                    .EmojiPickerReact {
                        width: 365px !important;
                        left: -35px;
                    }
                }
            }
            .messagesArea-footer {
                .collapsibleChat .typeMsgText {
                    top: -28px;
                }
            }
        }
    }
    .vConnectMainWrap .chatarea .messagesArea-header,
    .ck-editor.ck-reset {
        background: #fff !important;
    }

    .EmojiPickerReact {
        height: 322px !important;
        width: 310px !important;
        em-emoji-picker {
            max-height: 322px;
            // max-width: 310px;
            
            --background-rgb: 85, 170, 255;
            --border-radius: 4px;
            --category-icon-size: 20px;
            // Scroll track hover
            --color-border-over: rgba(247, 248, 252, 1);
            // Search background/Scroll Track
            --color-border: rgba(247, 248, 252, 1);
            --font-family: var(--bs-body-font-family);
            --font-size: 17px;
            --rgb-accent: none;
            // Panel background
            --rgb-background: 233, 236, 239;
            //Panel color (text, serch and category)
            --rgb-color: 60, 60, 67;
            // Input background
            --rgb-input: 247,248,252;
            // --shadow: 5px 5px 15px -8px rebeccapurple;
            // height: 50vh;
            // min-height: 400px;
        }
    }
}

// .GroupddEmployee {
//     .select2-search--dropdown {
//         display: none;
//     }

//     .select2-selectAll--dropdown {
//         top: 0 !important;
//     }

//     .select2-results {
//         padding-top: 40px !important;

//         .select2-results__options {
//             max-height: 150px !important;
//         }
//     }
// }

.select2-container {
    &.select2-container--default {
        .select2-dropdown {
            .select2-results {
                .select2-results__options {
                    .select2-results__option[aria-selected=true] {
                        .multicheck-checkbox {
                            background: #333 !important;
                            border: 1px solid #333 !important;
                            color: #fff !important;
                            top: 6px;
                            transform: none;
                            left: 9px;
                        }
                    }

                    .select2-results__option[aria-selected=false] {
                        .multicheck-checkbox {
                            background: white !important;
                            border: 1px solid #b3b3b3 !important;
                            top: 6px;
                            transform: none;
                            left: 9px;
                        }
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #A2A0A2;
                    }
                }
            }
        }
    }
}

// defualt selected value selection
.select2-container.select2-container--default .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=false] .multicheck-checkbox.defultselected {
    background: #333333 !important;
    font-family: 'Fontawesome pro 400', sans-serif;
    color: white;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    border: 1px solid #333333;
}


.select2-container.select2-container--default .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=false] .multicheck-checkbox.defultselected:after {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
}

.select2-container.select2-container--default .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=false] .multicheck-checkbox.defultselected {
    background: url("https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/select-check-unchecknew.png") no-repeat left -47px;
}




.select-employee-wrap {
    margin: 17px 0;
    position: relative;

    .select2-selection--multiple {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #E7E7E7;

        &:after {
            color: var(--text_neutral_default) !important;
        }

        .select2-selection__choice {
            margin-top: 0;
            margin-bottom: 4px;
            width: 145px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            padding: 5px;
            border: 1px solid #E7E7E7;
            background: #F8F8F8;

            &__display {
                width: 61%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .select2-selection__choice__remove {
                // display: none;
                padding: 0;
                border: none;
                margin-left: 5px;
                line-height: 0;
                background-color: transparent;
                font-size: 20px;
                color: var(--icon-neutral-icon-neutral-default, #333);

            }
        }

        .select2-selection__rendered {
            padding-top: 4px;
            flex-wrap: wrap;
            position: relative;
            padding-bottom: 0;
            min-height: 40px;
            background: #fff;
            z-index: 1;
            border-radius: 3px;
        }
    }

    .select2-container {
        width: 100% !important;

        &.select2-container--default {
            &.vcEmployeeSelect {
                top: 0 !important;

                .select2-selection__rendered {
                    position: static;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .select2-search {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;

                        .select2-search__field {
                            width: 100%;
                            padding: 0;
                            height: 40px;
                            text-indent: 10px;
                        }
                    }
                }
            }
        }
    }

    .avatar {
        overflow: hidden;
        
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-color: #CDCDCD !important;
        border-radius: 10em;
        margin-right: 5px;
        // position: absolute;
        top: 0;
        left: 0;
        width: 2.8em;
        height: 2.8em;
        position: relative;

        img {
            width: 100%;
            // height: 100%;
        }

        &-sm {
            height: 30px;
            width: 30px;
            border-radius: 15px;

            .avatar-group-img-wrap-text {
                font-size: 8px;
                line-height: 12px;
            }


            &[data-avatar="1"] .avatar-group-img-wrap {
                height: 31px;
                width: 31px;
                top: -1px;
                left: -1px;

                img {
                    height: 30px;
                    width: 30px;
                    &[src=""] {
                        display: none;
                    }
                }
            }
        }

        &-md {
            height: 40px;
            width: 40px;
            border-radius: 20px;
        }

        &-group {
            background: var(--bkg-blue-bkg-blue-weak, #BDDDF2);
            border: 1px solid var(--Blue-Light, #96C8EB);
        }

        &-group-img-wrap,
        &-group-member {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;

            img {
                height: 40px;
                width: 40px;
                max-width: 100%;
                vertical-align: top;
                object-fit: cover;
            }
        }

        &-group-img-wrap {
            overflow: hidden;
            border: 1px solid #96C8EB !important;
            background: var(--bkg-blue-bkg-blue-weak, #BDDDF2);
            height: 41px;
            width: 41px;
            top: -1px;
            left: -1px;

            .initial-avtar {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 28px;
                width: 28px;
            }

            &-text {
                color: var(--Text-Primary, #333);
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                padding-left: 2px;
            }
        }

        &-group {
            top: 0;
            left: 0;
            overflow: hidden;
            position: relative;
            z-index: 1;

            &[data-avatar="2"] .avatar-group-img-wrap:first-child {
                left: -25%;
            }

            &[data-avatar="2"] .avatar-group-img-wrap:nth-child(2) {
                left: 50%;
            }

            &[data-avatar="2"] .avatar-group-img-wrap:nth-child(2) .avatar-group-member {
                left: -25%;
            }

            &[data-avatar="3"] .avatar-group-img-wrap:first-child {
                left: 25%;
            }

            &[data-avatar="3"] .avatar-group-img-wrap:nth-child(2),
            &[data-avatar="3"] .avatar-group-img-wrap:nth-child(3) {
                left: 0;
                width: 50%;
                height: 50%;
            }

            &[data-avatar="3"] {
                .avatar-group-img-wrap {
                    top: 0;
                    left: 0;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    &:nth-child(2) {
                        border-bottom: none;
                    }

                    &:nth-child(3) {
                        top: 50%;
                    }
                }
            }

            &[data-avatar="4"] {
                .avatar-group-img-wrap {
                    border: none;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    &:nth-child(1) {
                        top: 0;
                        left: 0;
                        border-right: 1px solid var(--Blue-Light, #96C8EB);
                        border-bottom: 1px solid var(--Blue-Light, #96C8EB);
                    }

                    &:nth-child(2) {
                        top: 0;
                        border-bottom: 1px solid var(--Blue-Light, #96C8EB);
                    }

                    &:nth-child(3) {
                        left: 0;
                        border-right: 1px solid var(--Blue-Light, #96C8EB);
                    }

                    &:nth-child(4) {
                        left: 0;
                    }
                }
            }

            &[data-avatar="4"] .avatar-group-img-wrap {
                width: 50%;
                height: 50%;
            }

            &[data-avatar="4"] .avatar-group-img-wrap:nth-child(2),
            &[data-avatar="4"] .avatar-group-img-wrap:nth-child(4) {
                left: 50%;
            }

            &[data-avatar="4"] .avatar-group-img-wrap:nth-child(3),
            &[data-avatar="4"] .avatar-group-img-wrap:nth-child(4) {
                top: 50%;
            }
        }
    }
}

/* Remove Chat Dialogue */
.vgn-modal-alert {
    .modal-header .btn-close {
        font-family: "Font Awesome 5 Pro", fontawesome-5pro-regular-400;
        font-size: 21px;
        background: transparent;
        font-weight: 300;
        outline: none;
        background: none;
        border: none;
        position: absolute;
        right: 29px;
        top: 29px;
        padding: 0;
        color: #6d6e6f;
        opacity: 1;
        text-shadow: none !important;
        z-index: 99;

        &::after {
            content: '\f00d';
        }
        &:hover {
            color: #333333;
            opacity: 1;
        }

        &:focus {
            box-shadow: none;
        }
    }
    .modal-content {
        background-color: #fff;
        color: var(--text_neutral_default) !important;
    }

    .modal-footer {
        background-color: #f8f8f8;
        border-top: 1px solid #e7e7e7;
        .btn.btn-primary {            
            background: var(--bkg_green_default);
            border-color: var(--bkg_green_default);
        }

        .btn.btn-primary:hover,
        .btn.btn-primary:focus {
            background: var(--bkg_components_button_green_hover);
            border-color: var(--bkg_components_button_green_hover);
        }
        .btn.btn-secondary {
            background-color: #fff;
            border-color: #cdcdcd;
            color: #777;
        }
        .btn.btn-secondary:hover,
        .btn.btn-secondary:focus {
            background-color: #fff;
            border-color: #333;
            color: #333;
        }
    }
}

.modal-content {
    background-color: #fff;
    color: var(--text_neutral_default) !important;

    svg path {
        fill: var(--text_neutral_default) !important;
    }
}
.modal-body p {
    color: #333;
}

.modal-header {
    background-color: transparent;
    padding: 15px;
    padding-bottom: 0;
    color: #333;
    .modal-title,
    .modal-title span {
        color: #333;
    }
}

.modal-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    color: #333;

    a {
        cursor: pointer;
    }
}

.modal-body {
    padding: 15px;
    p {
        font-size: 16px;
        font-weight: 400;
        color: #333;
        margin: 0;
    }
    .check-label {
        color: #333;
    }
    > * {
        color: #333;
    }
}


.select2-container.select2-container--default.select2-container--open.vcEmployeeSelect.GroupddEmployee {
    .select2-dropdown.select2-dropdown--below {
        // background: #fff !important;
    }
    .select2-search.select2-search--dropdown {
        margin-bottom: 0;
    }
    .select2-results {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
    .select2-results__options {
        max-height: 200px;
    }
}
.add-member-dialogue {
    .modal-dialog {
        margin-top: 0 !important;
    }
    .modal-content {
        margin-top: 50px;
    }
    .modal-header .btn-close {
        font-family: "Font Awesome 5 Pro", fontawesome-5pro-regular-400;
        font-size: 21px;
        opacity: 1;
        background: transparent;
        font-weight: 300;
        outline: none;
        background: none;
        border: none;
        position: absolute;
        right: 29px;
        top: 29px;
        padding: 0;
        color: #6d6e6f;
        text-shadow: none !important;

        &::after {
            content: '\f00d';
        }
        &:hover {
            color:#333333;
            opacity: 1;
        }

        &:focus {
            box-shadow: none;
        }
    }
    .modal-dialog {
        max-width: 504px;
        margin: 0 auto;

        @media(max-width: 575px) {
            max-width: calc(100% - 30px);
            margin: 0 15px;
        }
    }

    

    .modal-footer {
        background-color: #f8f8f8;
        /*background-color: var(--bkg_neutral_secondary) !important;*/
        padding: 15px;
        gap: 16px;

        .btn {
            padding: 9px;
            font-size: 15px;
            min-width: 80px;
        }
        .btn.btn-primary {
            background: var(--bkg_green_default);
            border-color: var(--bkg_green_default);

            &:hover,
            &:focus {
                background: var(--bkg_components_button_green_hover);
                border-color: var(--bkg_components_button_green_hover);
            }
        }
    }

    input[type="text"] {
        color: var(--text_neutral_default) !important;
        border-color: var(--border_neutral_strong) !important;
        background-color: var(--bkg_neutral_default) !important;
        margin-bottom: 17px;
    }

    .v-commoninput {
        border: 1px solid #cdcdcd;
        height: 40px;
        padding: 0 10px;
        border-radius: 3px !important;
        font-size: 16px;
        color: #333;
        font-family: inherit;
        appearance: none;
        width: 100%;
        font-style: normal;
        box-shadow: none;
        cursor: text;
        outline: none;
    }

    .chat-history-box {
        .check-label {
            display: block;
            position: relative;
            padding-left: 28px;
            /*margin-bottom: 12px;*/
            cursor: pointer;
            // font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked~.checkmark {
                    background-color: #333;
                }

                &:checked~.checkmark {
                    &::after {
                        display: block;
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                border-radius: 5px;
                // background-color: #eee;
                border: 1px solid #cdcdcd;

                &:after {
                    content: "\f00c";
                    position: absolute;
                    display: none;
                    left: 1px;
                    top: 0px;
                    font-family: 'Font Awesome 5 pro';
                    color: white;
                }
            }
        }
    }
}

.select2-container {
    // &.select2-container--default {
    //     width: 100% !important;
    //     max-width: 100% !important;
    // }

    &--default {
        .select2-results__option[aria-selected=true] .multicheck-checkbox {
            font-family: "Font Awesome 5 Pro";
        }
    }

    .select2-selection__arrow {
        display: none;
    }

    .select2-selection--single,
    .select2-selection--multiple {
        &:after {
            font-weight: 900;
            font-family: "Font Awesome 5 Pro";
            cursor: pointer;
            height: 100%;
            padding: 18px 5px 19px;
            right: 11px;
        }
    }
}

/*- START: select 2 -*/
.ddlEmployee,
.ddlSelectCustomer1 {
    &.select2-container.select2-container--default.vcEmployeeSelect {
        top: 0px !important;
        z-index: 9999;
    }
}
.vConnectMainWrap.rightSideOnly.smallScreen {
    .ddlEmployee,
    .ddlSelectCustomer1 {
        &.select2-container.select2-container--default.vcEmployeeSelect {
            top: 0px !important;
            z-index: 9999;
        }
    }
    .select2-container.select2-container--default.vcEmployeeSelect .select2-search--dropdown:after {
        top: 19px !important;
    }
}

.GroupddEmployee {
    &.select2-container.select2-container--default.vcEmployeeSelect {
        .select2-dropdown--below {
            top: 0 !important;
        }
    }
}
.newchatEditerWrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px;
}

.ddlSelectCustomer1 {
    z-index: 0;
    .select2-search--dropdown {
        margin-bottom: 0;
    }
    &.select2-container.select2-container--default.vcEmployeeSelect {
        .select2-results {
            padding-top: 0 !important;
            border-radius: 2px;
            box-shadow: none;
            border: 1px solid #cdcdcd;
        }
        .select2-search--dropdown {
            margin-bottom: 0;
            // border: 1px solid #cdcdcd;
        }
        .select2-results-count {
            border-radius: 2px;
        }
        
    }
}

.select2-container.select2-container--default.select2-container--open.ddlSelectCustomer11 {
    top: 0 !important;
    .select2-search {
        margin-bottom: 0;
    }
    .select2-results {
        padding-top: 0;
        border: 1px solid #cdcdcd;
        box-shadow: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        .select2-results__options {
            .select2-results__option {
                padding: 7px 8px 11px 15px;
            }
            .option.load-more {
                padding: 7px 8px 11px 15px;
                color: var(--text_neutral_default)!important;
            }
        }
    }
}


.select2-container.select2-container--default.vcEmployeeSelect {
    // &.ddlEmployee {
    //     .select2 {    
    //         &-search {
    //             &__field {
    //                 border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
    //             }
    //         }
    //     }
    // }

    .select2 {
        &-dropdown--below {
            background: transparent !important;
            border: none !important;
            top: 15px;
        }

        &-search {
            &--dropdown {
                padding: 0;
                height: 60px;
                margin-bottom: 7px;
                &::after {
                    font-family: "Font Awesome 6 Pro";
                    font-size: 16px;
                    content: "\f0d7";
                    font-weight: 900;
                    position: absolute;
                    right: 17px;
                    top: 19px;
                    color: #000;
                    display: block;
                }
            }

            &__field {
                border-radius: 3px;
                // border: none;
                // opacity: 0;
                // background: #fefefe;
                padding: 16px;
                // height: 60px;

                // width: 90%;
                height: 60px;
                appearance: none!important;
                -webkit-appearance: none!important;
                // margin-top: 1px;
                // margin-left: 1px;
                background: #fff;
            }
        }

        &-results {
            border-radius: 5px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: var(--bkg-neutral-bkg-neutral-default, #FFF);
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            padding: 8px;
            padding-left: 0;
            padding-top: 42px;
            position: relative;
            padding-bottom: 0;
            padding-right: 0;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            overflow: hidden;

            $childPosLeft: 28px;

            &__option {
                padding-bottom: 11px;

                &.select2-results__option--highlighted[aria-selected="false"]{
                    background: transparent;

                    &:hover {
                        background: #f5f5f5;
                    }
                }

                &:has(> span.multicheck-checkbox.defultselected:not(.multilocation-business-item)) {
                    pointer-events: none;
                    .multicheck-checkbox.defultselected {
                        color: var(--icon_neutral_ondark)!important;
                        border: 1px solid var(--border_neutral_strong) !important;
                        background: var(--text_neutral_weak) !important;
                        
                        &:after {
                            color: var(--icon_neutral_ondark);
                        }
                    }
                    .multicheck-checkbox.defultselected ~ label {
                        color: var(--text_neutral_weak)!important;
                    }
                }
                label[data-is-parent="true"] b {
                    font-weight: 700 !important;
                }
            }

            &__options {
                margin-bottom: 0px;

                .select2-results__option {
                    .multicheck-checkbox {
                        &.multilocation-business-item {
                            margin-left: $childPosLeft;
                        }
                    }

                    .multilocation-business-label {
                        margin-left: $childPosLeft;
                    }

                    .labelpadd {
                        color: var(--text-neutral-text-neutral-default, #333);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;

                        b {
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        &-selectAll--dropdown {
            position: absolute;
            top: 68px;
            z-index: 9;
            color: var(--text-neutral-text-neutral-default, #333);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            background: transparent;
            padding: 10px 9px;

            .selectall.AllCustomer:hover,
            .selectall.AllCustomer:focus {
                color: #333;
            }

            .selectnone {
                display: none;
            }
        }
    }

    .select2-results-count {
        color: var(--text-neutral-text-neutral-default, #333);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background: #f8f8f8;
        padding: 8px;
        margin-bottom: 0px;
        // margin-left: -8px;
        // margin-right: -9px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;

        b {
            font-weight: 500;
        }
    }

    &.ddlSelectCustomer1 {

        .select2-results {
            padding-top: 3px;
            padding-right: 0;

            &__options {
                max-height: 265px;
                min-height: 265px;
            }

            &__option {
                padding: 7px 8px 11px 15px;
                padding-top: 7px;
                color: var(--text-neutral-text-neutral-default, #333);
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
    &.ddlSelectCustomer11 {
        .select2-results {
            margin-top: -1px;
            &__options {
                min-height: 200px;
            }
        }
    }
}
.option.load-more {
    padding: 8px;
}

//select2 employee dropdown login employee disable -karshit
.select2-results__option.checkboxdisable{
pointer-events: none;
}
/*- END: select 2 -*/


// Vagaro Drive modal start

.drive-file-type {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        object-fit: cover;
        max-height: 100%;
    }

    i {
        font-size: 32px;
    }

    .fa-file-pdf {
        color: #f40f02;
    }

    .fa-video {
        color: #cf0018;
    }

    .fa-file-excel {
        color: var(--bkg_green_default);
    }

    .fa-file-alt,
    .fa-images {
        color: #777777;
    }
}

#vDriveModal {
    max-width: 1104px;
    margin-top: 0;

    @media(max-width: 1024px) {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media(max-width: 787px) {
        margin-top: -20px;
    }

    @media(max-width: 575px) {
        margin-top: -40px;
    }

    .drive-wrap {
        @media(max-width: 480px) {
            display: inline-block;
            width: 100%;
        }
    }

    .modal-body-wrap {
        overflow-y: auto;
        height: calc(100vh - 240px);
        padding-right: 20px;

        @media(max-width: 787px) {
            height: calc(100vh - 290px);
        }
    }



    .modal {
        &-dialog {}

        &-content {}

        &-header {
            padding: 15px 20px 7px;
            background: #fff;
        }

        &-title {
            color: var(--Text-Primary, #333);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            width: 100%;
            justify-content: space-between;
            display: flex;
            align-items: center;

            a {
                display: inline-block;
                cursor: pointer;
                padding: 0px 10px;
            }
        }

        &-body {
            background: #fff;
            padding-right: 13px;

            .tabNavWrap {
                max-width: 216px;
            }

            .nav-item {
                margin-bottom: 16px;
            }

            .tabTitle {
                margin-bottom: 16px;
                font-weight: 500;
            }

            .nav-pills {
                color: #000;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;

                .nav-link {
                    padding: 10px;
                    min-width: 190px;

                    &:hover,
                    &:focus {
                        color: #000;
                    }
                }

                .nav-link.active,
                .show>.nav-link {
                    color: #000;
                    border-radius: 5px;
                    background: var(--background-dark-hover, #E7E7E7);
                }
            }

            .card {
                border: none;
                margin-bottom: 16px;
                border-radius: 3px;
                /*overflow: hidden;*/
                cursor: pointer;
                /*height: calc(100% - 16px);*/
                overflow: hidden;

                &.selected {
                    /*border: 1px solid var(--border-blue-border-blue-default, #2A90D6);*/
                    box-shadow: 0px 0px 0px 1px #2A90D6;

                    .selectedCount {
                        display: block;
                    }

                    .card-body {
                        background: var(--bkg-components-button-bkg-components-ghost-hover, #EAF4FB);
                    }
                }

                .selectedCount {
                    display: none;
                    position: absolute;
                    top: 3px;
                    right: 9px;
                    height: 24px;
                    width: 24px;
                    border-radius: 20px;
                    border: 1px solid var(--border-blue-border-blue-default, #2A90D6);
                    background: #FFF;
                    color: #000;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 23px;
                    text-align: center;
                }

                &-imgWrap {
                    border-radius: 3px;
                    background: #eee;
                    position: relative;
                    width: 198px;
                    height: 199px;

                    @media(min-width: 768px) and (max-width: 991px) {
                        height: 115px;
                    }
                }

                &-body {
                    padding: 10px 8px 0;
                    background: #fff;
                }

                &-title {
                    color: var(--text-neutral-text-neutral-default, #333);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    margin-bottom: 0;

                    @media(max-width: 991px) {
                        font-size: 13px;
                    }
                }

                &-text {
                    color: var(--text-neutral-text-neutral-secondary, #6D6E6F);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;

                    @media(max-width: 991px) {
                        font-size: 13px;
                    }
                }
            }
        }

        &-footer {
            background-color: #F8F8F8;

            .btn-secondary,
            .btn-secondary:hover,
            .btn-secondary:focus {
                border-radius: 3px;
                border: 1px solid var(--border-components-border-button-default, #CDCDCD);
                background: var(--bkg-components-button-bkg-button-neutral-default, #FFF);
                color: var(--text-neutral-text-neutral-default, #333);
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            .btn-primary,
            .btn-primary:hover,
            .btn-primary:focus {
                border-radius: 3px;
                background: var(--bkg_green_default);
                border-color: var(--bkg_green_default);
                color: var(--text-neutral-text-neutral-ondark, #FFF);
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }
}

.no-data {
    font-size: 15px;
    padding: 10px 0;
    color: var(--text-neutral-text-neutral-secondary, #6D6E6F);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}




// Responsive css 23/1
.rightSlide {
    max-width: 280px;
    width: 280px;
    flex: 0 0 280px;
    background-color: #fff;
    border-left: 1px solid #e7e7e7;

}

.moRightSlideTrigger {
    display: none;
}

.vConnectMainWrap .chatarea .chatListBlock .chatList-person_dotMenu:focus-visible {
    outline: none;
    border: none;
}


.vConnectMainWrap .chatarea .chatListBlock .chatList-person:hover,
.vConnectMainWrap .chatarea .chatListBlock .chatList-person {
    button {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

.vcTab,
.vcTab-browser {
    .extendButton.minimize {
        display: none;
    }
    .moRightSlideTrigger {
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;
    }

    .rightSlide {
        max-width: 280px;
        transition: all 0.3s ease-in;
        position: fixed;
        z-index: 99;
        // height: calc(100% - 40px);
        right: -280px;
        height: 100%;

        &-close {
            right: -280px;
            overflow: hidden;
        }

        &-open {
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
            right: 0px;
        }
    }   
}

@media screen and (min-width: 768px) and (max-width: 1023px) {


    .vConnectMainWrap {
        .chatarea .chatListBlock {
            &.convRight {
                position: relative;
                z-index: 9;
                transition: all 0.3s ease-in;

                &-open {
                    width: 392px;
                    left: 0;
                    opacity: 1;
                    height: 100% !important;
                }

                &-close {
                    width: 0;
                    left: -392px;
                    flex: 0;
                    opacity: 0;
                }

                
            }
        }

        &.rightSideOnly {
            .chatarea {

                .chatListBlock.convRight-close,
                .chatListBlock.convRight-open {
                    width: 100%;
                    left: 0;
                    opacity: 1;
                }
            }
        }
    }

    .iosmobiledevice .vConnectMainWrap .chatarea .chatListBlock.convRight-open { height: 100%!important;}     
    .iosmobiledevice .vcTab-portrait .chatList {height: calc(100% - 165px);}
}

.class-progress-main {
    position: relative;
    right: 0px;
    bottom: 0px;
    background: transparent;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    margin: 0 2px;
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }

    .class-progressbar {
        width: 28px;
        height: 28px;
        clear: both;
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 0px !important;
        border-radius: 50%;
        border: #000 solid 1px;

        canvas {
            margin-top: 0.2px;
        }

        .class-progress-inner-border {
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 3px;
            left: 3px;
            border-radius: 50%;
            border: solid 1px #000;
            background-color: transparent !important;
        }

        .class-progress-span {
            display: block;
            margin-top: 0px !important;
            text-align: center;
            position: absolute;
            font-weight: 500;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.8);
            width: 20px;
            letter-spacing: 0px;
            top: 3px;
            left: 3px;
            border-radius: 100%;
            height: 20px;
            line-height: 21px;
            overflow: hidden;
        }
    }
}

// .select2Collapsible {
//     .select2-container.select2-container--default.vcEmployeeSelect .select2-dropdown--below {
//         top: 75px;
//     }
// }

.collapsibleDropDown {
    .select2-container.select2-container--default.vcEmployeeSelect .select2-dropdown--below {
        top: 72px !important;
    }
    .select2-container.select2-container--default.vcEmployeeSelect .select2-search__field {
        border: none !important;
    }
    .select2-container.select2-container--default.select2-container--open.vcEmployeeSelect.ddlEmployee {
        .select2-search__field {
            border: 1px solid var(--border_neutral_default)!important;
        }
    }
}

.remove-chat-modal {
    &:before {
        display: none;
    }
}

.vConnectMainWrap.rightSideOnly.chatActive.smallScreen {
    .messagesArea-header_nameArea #messagesAreaGroupDetail .avatar[data-avatar="1"] {
        .avatar-group-img-wrap {
            .avatar-group-member {
                height: 27px;
                width: 27px;
            }
        }
    }
}
// .ddlSelectCustomer1.select2-container.select2-container--default.vcEmployeeSelect .select2-search__field {
//     background: #fff;
//     width: 90%;
//     height: 58px;
//     .select2-results {
//         border-top: none;
//     }
// }

/*-START: Customer side -*/
.customerChatWindow,
html.wf-proximanova-n5-active body.customerChatWindow {
    background: transparent !important;
}
.customerChatWindow {
    .banner-warning-master {
        top: 62px;
        width: calc(100% - 50px);
        left: 41px;
    }
    &.customerChatWindow-mobile {
        .banner-warning-master {
            left: 0;
            width: 100%;
        }
        .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .gifPluginBlock {
            left: 0 !important;
        }
        .sent.messagesBox {
            .EmojiPickerReact {
                left: auto !important;
                right: 20px;
            }
        } 

    }
}

/*-END: Customer side -*/

/*-- START: Comman control --*/
/*-START: DropDown-*/
.dropdown {
    text-decoration: none;
    color: #333;

    .dropdown-toggle {
        padding: 8px 2px 0 0px;
        display: block;

        &:after {
            display: none;
        }

        &:active,
        &:focus,
        &:hover,
        &.show {
            border: none;
            padding: 8px 2px 0 0px;
            box-shadow: none;
            background-color: transparent;
            border-color: transparent;
            // color: transparent;
            height: auto;
        }
    }

    .dropdown {
        &-menu {
            // border-radius: 3px;
            // border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
            background: #FFF;
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
            padding: 0px 0;
            border-radius: 4px;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
        }

        &-item {
            padding: 12px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: #333;

            &:hover {
                background: #F8F8F8;
                color: #333;
            }

            &.active {
                background: #E7E7E7;
                color: #333;
            }
        }
    }
}

/*-END: DropDown-*/
/*-- START: Icons --*/
.fa-usd-circle {
    font-family: "Font Awesome 5 Pro";
}

.fa.fas.fa-usd-circle {
    color: #000;
}

.fa.calender-icon.new-norecoring-icon {
    font-family: 'vagaro-pro-icons';

    &:after {
        content: "\e901";
        font-size: 15px;
    }
}
/*-- END: Icons --*/
/*-- END: Comman control --*/

/*Start Css for Passive alert for live stream */
.banner-success-master,
.banner-fail-master,
.banner-warning-master {
    position: fixed;
    left: 0px;
    top: 0;
    right: 0px;
    display: flex;
    box-sizing: border-box;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    background-color: var(--bkg_green_default);
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: #fff;
    min-height: 40px;
    z-index: 9999999 !important;
    transform: translateY(-100px);
    transition: all .3s ease-in;
    opacity: 0;
    visibility: hidden;
}

.banner-success-master,
.banner-success {
    // background-color: var(--bkg_green_default) !important;
    // box-shadow: 0 3px 8px 0 rgba(var(--modal_box_shadow), 0.20);
    // color: var(--text_neutral_default) !important;
    background-color: var(--bkg_green_default) !important;
    box-shadow: 0 3px 8px 0 rgba(0,0,0, 0.20);
    color: #fff !important;
    
}

.banner-fail-master,
.banner-fail {
    // background-color: var(--bkg_alert_default) !important;
    // box-shadow: 0 3px 8px 0 rgba(var(--modal_box_shadow), 0.20);
    // color: var(--text_neutral_default) !important;
    background-color: #D83F34 !important;
    box-shadow: 0 3px 8px 0 rgba(0,0,0, 0.20);
    color: #fff !important;
}

.banner-warning-master,
.banner-warning,
.banner-warning-popup {
    // background-color: var(--bkg_warning_default) !important;
    // box-shadow: 0 3px 8px 0 rgba(var(--modal_box_shadow), 0.20);
    // color: var(--text_neutral_default) !important;
    background-color: #FF9F08 !important;
    box-shadow: 0 3px 8px 0 rgba(0,0,0, 0.20);
    color: #fff !important;
}

.banner-success-master.showmsgTop,
.banner-fail-master.showmsgTop,
.banner-warning-master.showmsgTop {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
}

.banner-success-master .icon-banner,
.banner-fail-master .icon-banner,
.banner-warning-master .icon-banner {
    font-size: 24px;
    margin-right: 10px;
    line-height: 34px;
}
.icon-banner.font-awesome-normal {
    font-family: "Font Awesome 5 Pro";
}
.customerChatWindow {
    .vConnectMainWrap .smallChatBox-middle .chatarea .messagesArea-middle .messagesBox.sent .messages {
        background: #cc4744 !important;
    }
}

/* CK Editor 5 */
.ck-body-wrapper {
    display: none;
}

.ckeditor_1:not(.showOutline),
.ckeditor_2:not(.showOutline) {
    .ck .ck-widget,
    .ck .ck-widget:hover {
        outline: none !important;
    }
}

.ck-powered-by {
    display: none;
}

.ck-editor.ck-reset .ck-editor__main .ck-editor__editable a {
    color: #2490E1 !important;
}

.typeChatWrap {
    padding: 15px !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    background: transparent !important;
}
.ck-editor {
    

    &.ck-reset {
        display: flex;
        flex-direction: column-reverse;
        /*margin: 15px !important;*/
        border-radius: 8px;
        border: 1px solid #CDCDCD !important;
        background: #FFF;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        overflow: hidden;

        .ck-toolbar {
            border: none;
            border-radius: 8px !important;
            padding: 0 15px 15px 16px;
            background: transparent;

            &.ck-toolbar_grouping {
                display: flex;
                align-items: end;
                min-height: 56px;
            }

            &__items {
                margin-bottom: 4px;
            }

            .cke_button:not(.cke_button_Send) {
                height: 16px;
                width: 16px;
            }

            .cke_button {
                margin: 0 !important;
                margin-right: 16px !important;

                &:hover {
                    .cke_button_icon {
                        color: #6D6E6F;
                    }
                }

                .active {
                    &.cke_button_icon {
                        color: #333;
                    }
                }

                &.cke_button_note {
                    &:hover {
                        .cke_button_icon-note::before {
                            color: #E89107;
                        }
                    }
                    // &.active {
                    //     .cke_button_icon-note::before {
                    //         color: #E89107;
                    //         font-weight: 900;
                    //     }
                    // }
                }

                &.cke_button_Send {
                    font-family: var(--bs-body-font-family);
                    font-size: 15px;
                    font-weight: 500;
                    background: var(--bkg_green_default);
                    border-radius: 3px;
                    padding: 8px 25px;
                    margin-left: 14px;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    margin-right: 0 !important;
                    cursor: pointer;

                    &:hover,
                    &:focus {
                        background: var(--bkg_components_button_green_hover);
                        border-color: var(--bkg_components_button_green_hover);
                    }
                }


                .cke_button_icon {
                    cursor: pointer;
                    font-family: "Font Awesome 5 Pro";
                    font-size: 15px;
                    color: #A2A0A2;
                    padding: 0 !important;
                    background: none !important;

                    &-gif {
                        font-family: "Font Awesome 6 Pro";
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        &::after {
                            content: '\e190';
                            // font-family: "Font Awesome 5 Pro";
                            // font-weight: 400;
                        }
                    }

                    &-note {
                        &::before {
                            content: "\f249";
                            color: #FF9F08;
                        }
                    }

                    &-link {
                        font-family: "Font Awesome 6 Pro";
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        &::after {
                            content: '\f0c1';
                        }
                    }

                    &-upload {
                        &::before {
                            content: "\f302";
                        }
                    }

                    &.cke_button__emojipanel_icon {
                        &::before {
                            content: "\f118";
                        }
                    }
                }

                &__emojipanel {
                    order: 1;
                }

                &_upload {
                    order: 2;
                }

                &_gif {
                    order: 3;
                }

                &_link {
                    order: 4;
                }

                &_note {
                    order: 5;
                }
            }
        }

        .ck-editor__main {
            .ck-placeholder {
                &:before {
                    font-family: var(--bs-body-font-family);
                    color: #A2A0A2;
                }
            }

            .ck-editor__editable {
                border: none !important;
                outline: none !important;
                border-radius: 8px !important;
                box-shadow: none !important;
                min-height: 24px;
                max-height: 195px;
                overflow-y: auto;
                padding: 3px 15px;

                a {
                    color: #0000EE;
                }
            }
        }
        

        &.note-active {
            border: 1px solid #FF9F08 !important;
            // background: #FFE1B2 !important;

            .ck-toolbar .cke_button .cke_button_icon-note::before {
                font-weight: 900;
                color: #E89107;
            }

            .ck-toolbar,
            .ck-editor__editable {
                // background: #FFF5E6;

                .ck-placeholder {
                    &:before {
                        color: #FF9F08;
                    }
                }
            }

            .cke_bottom {
                background: #FFF5E6 !important;
            }
            .ck.ck-editor__main>.ck-editor__editable {
                background: transparent;
            }
        }
    }
}
.ckeditor_1 {
    .ck-reset {

        &.note-active{
            background: #FFE1B2 !important;
        }
        
    }
}

.mobileListmenuWrap {
    .ckeditor_1 .ck-reset {
        &.textmarketing-alert {
            background: #fbeceb !important;
            border: 1px solid var(--border_alert_default) !important;
        }
        &.restrictedText-alert {
            background: #fbeceb !important;
            border: 1px solid var(--border_alert_default) !important;
        }
    }
}

/*-- START: Ck Editer for mobile --*/
.mobileEditer {
    .ck-editor {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        &.ck-reset {
            border: none !important;
            box-shadow: none;
        }

        &__top {
            .ck.ck-toolbar {
                &__items {
                    // display: none;
                    .cke_button_gif,
                    .cke_button_link,
                    // .cke_button_upload,
                    .custom-emoji {
                        display: none;
                    }
                    .cke_button_note {
                        position: absolute;
                        right: 5px;
                        top: 16px;
                        margin: 0 !important;
                    }
                }
                &_grouping {
                    padding: 0;
                    background: transparent;
                    display: flex;
                    align-items: center;
                }
            }
        }
        &__main {
            width: 90%;
            border-radius: 50px;
            overflow: hidden;
            padding-right: 24px;
            background-color: #fff;
        }
        &.ck-reset .ck-toolbar .cke_button.cke_button_Send {
            display: none !important;
            font-size: 0;
            background-color: transparent;
            background: url('https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/messageFieldSendicon.png') center center no-repeat;
            background-size: contain;
            height: 32px;
            width: 32px;
            padding: 0;
            position: absolute;
            right: 0;
        }
    }
    &.active {
        .ck-editor {
            justify-content: start;
            &.ck-reset .ck-toolbar .cke_button.cke_button_Send {
                display: block !important;
            }
            &__main {
                width: 75%;
            }
            &__top .ck.ck-toolbar__items .cke_button_note {
                right: 57px;
            }
        }

    }
}

.mobileListmenuWrap {
    .ckeditor_1 .ck-reset {
        &.textmarketing-alert {
            background: #fbeceb !important;
            border: 1px solid var(--border_alert_default) !important;
        }
        .restrictedText-alert {
            background: #fbeceb !important;
            border: 1px solid var(--border_alert_default) !important;
        }
    }
}
/*-- END: Ck Editer for mobile --*/
.chatMinimize {
    .select2-container.select2-container--default.select2-container--open.vcEmployeeSelect.ddlEmployee {
        display: none;
    }
}

/*-START: media -*/
@media screen and (max-width: 1440px) {
    .vConnectMainWrap {
        .welcomeScreen-block {
            img {
                max-width: 290px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .vConnectMainWrap {
        .welcomeScreen-block {
            img {
                max-width: 190px;
            }

            &_title {
                margin-top: 10px;
                font-size: 18px;
            }

            &_info {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
 
     

    .vConnectMainWrap {
        .welcomeScreen-block {
            width: 100%;

            img {
                max-width: 190px;
            }

            &_title {
                margin-top: 10px;
                font-size: 18px;
            }

            &_info {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
}
@media screen and (max-height: 744px) { 
    .vConnectMainWrap {
        .welcomeScreen-block {
            width: 100%;

            img {
                max-width: 190px;
            }

            &_title {
                margin-top: 10px;
                font-size: 18px;
            }

            &_info {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
}
.vConnectMainWrap.rightSideOnly .welcomeScreen {
    height: calc(100vh - 54px);
}
.vConnectMainWrap.rightSideOnly.smallScreen .welcomeScreen-block img {
    max-width: 146px !important;
    margin-bottom: 30px;
}

.vConnectMainWrap.rightSideOnly.smallScreen .welcomeScreen-block .start-chatting-btn {
    display: none;
}

.vConnectMainWrap.rightSideOnly.smallScreen .employeeSelectWrap {
    display: flex;
    align-items: center;
}
.vConnectMainWrap.rightSideOnly.smallScreen .employeeSelectWrap div{
    width: 100%;
}
.vConnectMainWrap.rightSideOnly.smallScreen .welcomeScreen-block {
    height: calc(100% - 160px);
    overflow-y: auto;
    position: static;
}
.start-chatting-btn {
    display: none;
}
@media screen and (max-width: 767px) {
    .vcMobile {
        .vConnectMainWrap.rightSideOnly .collapsibleChat .typeMsgText { top:-27x;        min-height: 35px;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            align-items: center;}
    }
    .vConnectMainWrap {
        .welcomeScreen-block {
            width: 100%;

            img {
                max-width: 190px;
                margin-bottom: 16px;
            }

            &_title {
                margin-top: 0px;
                font-size: 24px;
                margin-bottom: 16px;
                font-weight: 600;
                line-height: 28px;
            }

            &_info {
                margin-top: 0px;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
            }

            /*.start-chating {
                width: 100%;
                background: var(--bkg_green_default);
                padding: 15px;
                border-radius: 3px;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: center;
                border: none;
                color: #FFF;*/
                /* bottom: 75px; */
                /*&:hover {
                    background: var(--bkg_components_button_green_hover);
                    border-color: var(--bkg_components_button_green_hover);
                }
            }*/

            .start-chatting-btn {
                padding: 16px;
                position: absolute;
                right: 0;
                left: 0;
                top: auto;
                bottom: 0px;
                display: block;
            }
        }
    }
}
@media screen and (max-height: 530px) {
    .vConnectMainWrap {
        .welcomeScreen-block {
            display: none;
        }
    }
    .vConnectMainWrap.rightSideOnly.smallScreen {
        .welcomeScreen-block {
            display: block;
        }
    }
}
.highlight {
    color: #6D6E6F;
    font-weight: bold;
}

/*-END: media -*/
.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {   
    position: static !important; 
    width: 100% !important;
    box-shadow: none !important;
    border: none !important;

    .ck-toolbar_grouping {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        box-shadow: none !important;
        border: none !important;
    }
}
.ck.ck-sticky-panel__placeholder {
    display: none !important;
}

.iconchat { display:none;}
.messagesArea-header_avGroup #messagesAreaGroupDetail {
    display: flex;
    align-items: center;
    width: 100%;
}
.msg-header { display:none;}
/*CSS for small screen template emp group chat window start here*/
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar.avatar-group[data-avatar="4"] .review-profile-inner-text {
    height: auto;
    width: auto;
    font-size: 8px;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar.avatar-group[data-avatar="3"] .avatar-group-img-wrap:nth-child(2) .review-profile-inner-text {
    height: auto;
    width: auto;
    font-size: 8px;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar.avatar-group[data-avatar="3"] .avatar-group-img-wrap:nth-child(3) .review-profile-inner-text {
    height: auto;
    width: auto;
    font-size: 8px;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="4"] .avatar-group-member {
    height: auto !important;
    width: auto !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="4"] .avatar-group-img-wrap:nth-child(3) .avatar-group-member {
    width: 100%  !important;
    height: 100% !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="4"] .avatar-group-img-wrap:nth-child(2) .avatar-group-member {
    width: 100% !important;
    height: 100% !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="3"] .avatar-group-img-wrap:nth-child(2) .avatar-group-member {
    width: 100% !important;
    height: 100% !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="3"] .avatar-group-img-wrap:nth-child(3) .avatar-group-member {
    width: 100% !important;
    height: 100% !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="4"] .avatar-group-img-wrap:nth-child(4) .avatar-group-member {
    width: 100% !important;
    height: 100% !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="4"] .avatar-group-img-wrap:first-child .avatar-group-member {
    width: 100% !important;
    height: 100% !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="4"] .avatar-group-img-wrap {
    width: 50%;
    height: 50%;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .avatar-group[data-avatar="4"] .avatar-group-img-wrap-text {
    font-size: 8px;
    line-height: 13px;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesArea-header_nameArea .avatar-group[data-avatar="4"] .avatar-group-img-wrap div .review-profile-inner-text {
    justify-content: flex-start;
}

/*CSS for small screen template emp group chat window end here*/
.vConnectMainWrap .smallChatBoxWrap .smallChatBox-middle .chatarea .messagesArea-header_nameArea {
    width: auto;
}
.vConnectMainWrap .smallChatBoxWrap .messagesArea-footer .collapsibleChat .ck-reset.ck-editor .ck-editor__main .ck-editor__editable p {
    line-height: normal;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .chatarea .messagesArea-middle .sent.messagesBox.messagesEditWrap .messagesBox-block {
    max-width: 88%;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .collapsibleChat .ck-reset.ck-editor {
    background: var(--bkg_neutral_default) !important;
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .collapsibleChat .ck-editor.ck-reset.note-active .ck-toolbar {
    background: var(--bkg_neutral_default) !important;
}
.vConnectMainWrap .rightside-wrapper .appointment-detail.iou-section:hover {
    cursor: default;
    background: none !important;
}

.vConnectMainWrap .rightside-wrapper .appointment-detail.iou-section:hover .service-wrap {
    cursor: default;
}
.vConnectMainWrap.rightSideOnly.smallScreen .collapsibleChat .ck-reset.ck-editor, .vConnectMainWrap.rightSideOnly.smallScreen .collapsibleChat .ck-reset.ck-editor .ck-toolbar {
    background: var(--bkg_neutral_default) !important;
}
.vConnectMainWrap .chatarea .messagesArea-middle .recived.messagesBox .messagesBox-block .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.customerChatWindow .image-popup-wrap .modal-header {
    margin-top: 0px;
}
.rightside-wrapper.client-details .dropdown .dropdown-toggle:hover {
    padding: 8px 8px 0px 26px;
}

.rightside-wrapper.client-details .dropdown .dropdown-toggle {
    width: 24px;
    padding: 8px 8px 0px 26px;
}
.rightside-wrapper.client-details .mobile-contextmenu.serviceCardWrap.dropdown .dropdown-toggle {
    width: 100% !important;
}
.add-member-dialogue .chat-history-box .check-label .checkmark:after{
    left: 3px;
}
.vConnectMainWrap .rightside-wrapper .member-list-wrapper .chatList-person .avatar .review-profile-inner-text{
    width: 29px;
    height: 29px;
}

.vcMobile .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .gifPluginBlock {
    // left: 0px;
    // position: absolute;
    // margin-left: 0;
}
.vcMobile .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .EmojiPickerReact {
    // left: -24px;
}
.vcMobile .vConnectMainWrap.rightSideOnly.smallScreen,
.vConnectMainWrap.rightSideOnly.smallScreen {
    .welcomeScreen {
        .gifPluginBlock {
            margin-left: 0;
        }
    }
}


.messagesArea-middle  .gifPluginBlock { position: fixed; bottom:inherit;}
.vConnectMainWrap .messagesArea-footer .gifPluginBlock { left: 98px;}

.select2-container.select2-container--default .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=true] .multicheck-checkbox.defultselected { 
    background: var(--text_neutral_weak) !important;
    border: 1px solid var(--border_neutral_strong) !important;
    color: var(--icon_neutral_ondark)!important}
    .select2-container.select2-container--default.vcEmployeeSelect .select2-results__options .select2-results__option[aria-selected=true]  .labelpadd { color: var(--text_neutral_weak)!important;}

.ddlSelectCustomer1.select2-container.select2-container--default.vcEmployeeSelect .select2-results__options {
    max-height: 190px;
    min-height: 190px;
}
.select2Collapsible.collapsibleDropDown .select2-container.select2-container--default.vcEmployeeSelect .select2-results__options {
    max-height: 190px;
}
// .vcTab .vConnectMainWrap .chatarea .chatListBlock.convRight-open{
//     height: 100vh !important;
// }
@media (max-width: 767px) {
    // .customerChatWindow .image-popup-wrap {
    //     margin-top: 70px;
    // }
    .vcMobile .vConnectMainWrap .chatarea .messagesArea-middle .inChatmsg.personBlockText {
        text-transform: inherit;
    }

    .vcMobile .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-header_back, .vcMobile .vConnectMainWrap .rightside-wrapper .right-mobileHeader-back .fa-angle-left {
        font-size: 20px;
        text-decoration: none;
        font-weight: 900;
    }

    .image-popup-wrap .modal-header .modal-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        padding-right: 20px;
    }
    .vcMobile .msg-header .icon-width i {
        font-size: 20px;
    }
    .vcMobile .vConnectMainWrap.rightSideOnly .chatarea .messagesArea-header_moreVertical .dropdown-toggle{
        font-size: 20px;
    }
}
@media (max-width: 400px) {
    .vcMobile .vConnectMainWrap.rightSideOnly .collapsibleChat .ck-reset.ck-editor .ck-editor__main .ck-placeholder:before {
        font-size: 14px;
    }
}
em-emoji-picker {
    max-height: 390px;
    height: 390px;
    max-width: 382px;
}
/*changes form tablet device welcome screen*/



@media(min-width:768px) and (max-width:1024px) {
    .vcTab .vConnectMainWrap .typeChatWrap { padding-left: 8px!important; padding-right: 8px!important;}
    .ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send {
        padding-left: 8px!important;
        padding-right: 8px!important;
        font-size: 14px;
    }
    .ck-editor.ck-reset .ck-toolbar .cke_button {
        margin: 0 14px 0 0 !important;
    }
    .ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_note { margin-right: 0px!important;}
    
}
    .vagaroConnect.vcTab .vConnectMainWrap .row {
        flex-wrap: nowrap;
    }

    .vagaroConnect.vcTab .vConnectMainWrap .rightSideOnly-nav {
        width: 0;
    }

    .vagaroConnect.vcTab .vConnectMainWrap .welcomeScreen .employeeSelectWrap .select2-container.select2-container--default.vcEmployeeSelect {
        width: 100% !important;
    }
    // .vagaroConnect.vcTab .vConnectMainWrap .employeeSelectWrap {
    //         padding: 35px 15px 16px;
    // }
    .vagaroConnect.vcTab .vConnectMainWrap .employeeSelectWrap .select2-container--default .select2-selection--multiple .select2-selection__rendered {
        max-width: 95%;
    }
    // .vcTab .ddlEmployee.select2-container.select2-container--default.vcEmployeeSelect{
    //     width: 100% !important;
    // }
    // .vagaroConnect.vcTab .select2-container.select2-container--default.vcEmployeeSelect .select2-dropdown--below{
    //     top: 35px;
    // }
    /*- START: Live issues fixes -*/
    .customerChatWindow:not(.customerChatWindow-mobile) {
        .vConnectMainWrap .smallChatBox-middle .messagesArea-middle {
            height: calc(100vh - 179px);
            &:has(~ .messagesArea-footer .typeChatWrap.collapsibleChat .isTypingMain) {
                padding-bottom: 50px;
            }
        }
    }
.vConnectMainWrap .rightside .rightside-wrapper {
    display: flex;
    flex-flow: column;
}


.vConnectMainWrap .textCounArea-status-msg {
    font-size: 12px;
}

// css for ipad only 
.vcTab {
.vConnectMainWrap .rightside-wrapper { height: 100vh;}

}
.vcTab, .vcTab-browser {
    .vgn-modal-alert .modal-footer { gap:0;
        .btn-primary { margin-left: 15px;}
    }
    
}

.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages.message-note-block { color: #333!important;}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages.message-note-block a { color: #333!important;}
.vConnectMainWrap .customer-detail-wrap { margin-top: 24px;}
// .businessLocationWrap .businessLocationBlock { padding-top: 16px;}
.team-employee-name { display: none;}

@media (max-width: 767px) {
    .customerChatWindow.customerChatWindow-mobile .vConnectMainWrap .smallChatBoxWrap, .customerChatWindow.customerChatWindow-mobile .vConnectMainWrap .smallChatBoxWrap .smallChatBox-middle {
        height: 100%;
    }

    .customerChatWindow.customerChatWindow-mobile .vConnectMainWrap .smallChatBoxWrap .messagesArea {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .customerChatWindow.customerChatWindow-mobile .vConnectMainWrap .smallChatBox-middle .messagesArea-middle {
        flex-grow: 1;
        height: auto !important;
    }

    .customerChatWindow.customerChatWindow-mobile .vConnectMainWrap .smallChatBox-middle .collapsibleChat {
        position: inherit;
    }

    .vagaroConnect.customerChatWindow.customerChatWindow-mobile {
        -webkit-touch-callout: none;
        height: 100%
    }
}

// Website builder css
body.vagaroConnect.customerChatWindow.websitebuilder-loaded .smallChatBox-header {
    background: var(--wbBtnSecondaryBgColor) !important;
}

.vagaroConnect.customerChatWindow.websitebuilder-loaded .smallChatBoxWrap {
    width: 380px;
}

.vagaroConnect.customerChatWindow.websitebuilder-loaded .vConnectMainWrap .smallChatBox-middle .chatarea .messagesArea-middle .messagesBox.sent .messages {
    background: var(--wbBtnSecondaryBgColor) !important;
    color: var(--wbFontColor)!important;
}
.websitebuilder-loaded .vConnectMainWrap .smallChatBox-header_actions a, .websitebuilder-loaded .vConnectMainWrap .smallChatBox-header_title {
    color: var(--wbFontColor)!important;
}

.vagaroConnect.customerChatWindow.websitebuilder-loaded .vConnectMainWrap .smallChatBox-header_title, .vagaroConnect.customerChatWindow.websitebuilder-loaded .vConnectMainWrap .smallChatBox-middle_nameArea-nameWrap {
    font-family: var(--glbHeadFont);
    color: #333!important;
}

.vagaroConnect.customerChatWindow.websitebuilder-loaded .vConnectMainWrap .smallChatBoxWrap {
    right: 0;
}

.vagaroConnect.customerChatWindow.websitebuilder-loaded .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages p {
    font-family: var(--wbFontFamily) !important;
}

.vagaroConnect.customerChatWindow.websitebuilder-loaded .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.recived .messages {
    background: var(--wbBodyBg) !important;
}

.vagaroConnect.customerChatWindow.websitebuilder-loaded .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages .editControlWrap {
    background: var(--wbBodyBg) !important;
}
.websitebuilder-loaded {
    .banner-success-master, .banner-fail-master, .banner-warning-master {
        width: 100% !important;
        left: 0px !important;
        right: 0px !important;
    }

    .customerChatWindow .image-popup-wrap{
        margin-top: 0px;
    }
    .chatarea .messagesArea-middle .messagesBox .editControlWrap {
        background: #fff;
    }
    .reactedWrap .reactionBlock .reaction {
        border: 1px solid #e7e7e7;
        background: #fff;
        &.active {
            border: 1px solid var(--wbc-primary-button-bg);
        }
    }
    .reactionPopHover {
        background: #fff;
    }
}

.vcTab, .vcMobile {
    .stencilChatListBlock .messagesArea-middle:after { display: none!important;}
    .stencilChatarea .messagesArea-middle:after { display: none!important;}
} 


@media only screen and (max-width: 767px) {
    .websitebuilder-loaded.customerChatWindow.customerChatWindow-mobile .vConnectMainWrap .smallChatBox-header {
        display: none;
    }
    .websitebuilder-loaded .EmojiPickerReact em-emoji-picker { max-width:350px;} 
    .websitebuilder-loaded .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .EmojiPickerReact {width: 350px !important;}   
    .websitebuilder-loaded .vConnectMainWrap .gifPluginBlock {background-color: #e7e7e7!important;}
    .websitebuilder-loaded.customerChatWindow .vConnectMainWrap .smallChatBox-middle .messagesArea-middle {height: calc(100vh - 185px);}
    .vagaroConnect.customerChatWindow.websitebuilder-loaded .smallChatBoxWrap{width: 100%;}
    .websitebuilder-loaded .vConnectMainWrap .chatarea .messagesArea-middle::-webkit-scrollbar-thumb {background: #7d7e7e!important;}
}


    /*- END: Live issues fixes -*/

    html[data-theme="light"] {
        em-emoji-picker {
            --background-rgb: 85, 170, 255;
            --border-radius: 4px;
            --category-icon-size: 20px;
            // Scroll track hover
            --color-border-over: rgba(247, 248, 252, 1);
            // Search background/Scroll Track
            --color-border: rgba(247, 248, 252, 1);
            --font-family: var(--bs-body-font-family);
            --font-size: 17px;
            --rgb-accent: none;
            // Panel background
            --rgb-background: 233, 236, 239;
            //Panel color (text, serch and category)
            --rgb-color: 60, 60, 67;
            // Input background
            --rgb-input: 247,248,252;
            // --shadow: 5px 5px 15px -8px rebeccapurple;
            // height: 50vh;
            // min-height: 400px;
        }
    }

    // html[data-theme="dark"] {
    //     em-emoji-picker {
    //         // Scroll track hover
    //         --color-border-over: rgba(247, 248, 252, 1);
    //         // Search background/Scroll Track
    //         --color-border: rgba(247, 248, 252, 1);
    //         // Panel background
    //         --rgb-background: var(--bkg_neutral_tiertiary)!important;
    //         //Panel color (text, serch and category)
    //         --rgb-color: 60, 60, 67;
    //         // Input background
    //         --rgb-input: 247,248,252;
    //     }
    // }
    /*- Start: color theme -*/
    // .vConnectMainWrap {
    //     .chatarea .messagesArea-middle .messagesBox.sent .messages {
    //         background: var(--MenuBackColor)!important;
    //     }
    //     &.rightSideOnly.smallScreen .rightSideOnly-header {
    //         background: var(--MenuBackColor)!important;
    //     }
    // }
    // html[data-theme=dark] .vConnectMainWrap.rightSideOnly.smallScreen .rightSideOnly-header {
    //     background: var(--MenuBackColor)!important;
    // }
    /*- End: color theme -*/

.emp-back-arrow {  display: none; }
.desktop-hide-icon { display: none;;}
.vcTab-browser .upgradeBlockWrap { display: none!important;}

.vcTab-portrait {
    .ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send {
        min-width: 80px;
        padding: 4px 16px !important;
        background: linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%) !important;
    
    }
}
.vcTab-portrait.vcMobile {
    .modal-dialog { max-width: 100%!important;}
}

@media only screen and (min-width:768px) and (max-width:1023px) and (orientation:portrait) { 
    .vcTab-portrait {
        .vConnectMainWrap .chatarea .chatListBlock .chatList-person.active{
            background : transparent !important;
        }
        .modal-dialog { max-width: 100%!important;}
        .modal-header button.btn-close { display: block!important;}
        .start-chatting-btn { display: block; left: 20px; right: 20px; bottom: 20px;}
        .vConnectMainWrap {
            .welcomeScreen-block {
                img {
                    max-width: 500px;
                    width: 100%;
                }
    
                &_title {
                    margin-top: 30px;
                    font-size: 28px;
                }
    
                &_info {
                    margin-top: 20px;
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
        
        .vConnectMainWrap .rightSideOnly-nav { width: 100%!important;}
        .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle {height: calc(100% - 183px)!important; flex-grow: unset;}
        .vConnectMainWrap .chatarea .messagesArea-footer { position: static;}
      
        .vConnectMainWrap .typeChatWrap { z-index: 3;}
        .chatList { display: flex;
            flex-direction: column;
            height: calc(100% - 160px);
            flex-grow: inherit;}
        .vConnectMainWrap .chatarea .chatListBlock .chatList-groupBlock {
            height: auto !important;
            flex-grow: 1;
        }
        .vConnectMainWrap.rightSideOnly .upgradeBlockWrap { position: relative!important;}
        .detail-content-scroll { height: 100%; overflow:auto;}
.vConnectMainWrap .rightside-wrapper .bottom-content-wrap { height:auto!important;}
.member-list-wrapper { height: 100%; overflow: auto;}
.vConnectMainWrap .rightside-wrapper .member-list-wrapper .bottom-content-wrap { height: auto!important;}
.vConnectMainWrap.rightSideOnly.chatActive {height: 100%!important;}
.businessLocationBlock .iconWrap svg { width: 15px!important; height: 12px!important;}
.vConnectMainWrap .messagesArea-footer .gifPluginBlock .reactGiphySearchbox-componentWrapper, 
.vConnectMainWrap.rightSideOnly .gifPluginBlock .reactGiphySearchbox-componentWrapper, 
.vConnectMainWrap .gifPluginBlock .reactGiphySearchbox-componentWrapper { width: 324px!important; left: 93px!important; }
.vConnectMainWrap .messagesArea-footer .gifPluginBlock, 
.vConnectMainWrap.rightSideOnly .gifPluginBlock, .vConnectMainWrap .gifPluginBlock { width:324px!important; left: 93px!important;}
.vConnectMainWrap.rightSideOnly {height: 100% !important;}
.vConnectMainWrap .rightside-wrapper .right-mobileHeader-back .fa-angle-left { font-size: 20px;}
.messagesArea-header_back .fa-angle-left { font-size: 20px; font-weight: 400;}
.messagesArea-header_moreVertical .fa-ellipsis-vertical { font-size: 20px; font-weight: 400;}
    }
   
    
}

.aap-show { display:none;}
.vcTab .ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send {
    min-width: 80px;
    padding: 4px 16px;
    background: linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%) !important;
}


//Css for ipad landscape
@media (min-width: 950px) { 
html,body.vcTab { height: 100%; }
.iosmobiledevice .vcTab .vConnectMainWrap .chatarea .chatListBlock.convRight-open { height: 100vh!important;} 
.vcTab.keywordopen .vConnectMainWrap .gifPluginBlock .reactGiphySearchbox-listWrapper { height: 210px;}
.vcTab  {
    .messagesArea-header_moreVertical .fa-ellipsis-vertical { font-size: 20px; font-weight: 400;}
    .vConnectMainWrap .chatarea .messagesArea-header_nameArea { width: 100%!important;}
    .vConnectMainWrap .chatarea .messagesArea-header_moreVertical { display:block!important; }
    .vConnectMainWrap .chatarea .messagesArea-header_moreVertical .dropdown-toggle { padding: 3px 10px; padding-right: 0px;}
#divAddMember { position: absolute; right: 16px; top: 18px; font-size: 20px;}
    .vConnectMainWrap .chatarea .chatListBlock .chatList-person.personRead .chatList-person_name { font-weight: 400!important;}
    .msg-header { display: none!important;}
    .businessLocationBlock .iconWrap svg { width: 15px!important; height: 12px!important;}
    .vConnectMainWrap .chatarea .chatListBlock-searchBlock .serachGrooup .searchIcon { top:12px;}
    
   .vConnectMainWrap .chatarea .chatListBlock .chatList-person .userBlock-icon {
        top: 8px;
        left: 27px;
    }
    .vConnectMainWrap .chatarea .pinnedGroupMobile .chatList-person.userBlock .userBlock-icon {top: 4px;left: 2px;}
    .detail-content-scroll { height: 100%; overflow:auto;}
    .vConnectMainWrap.rightSideOnly.chatActive {height: 100%!important;}
    .member-list-wrapper { height: 100%; overflow: auto;}
    // .vConnectMainWrap .typeChatWrap { z-index: 3;}
    .vConnectMainWrap .rightside-wrapper .member-list-wrapper .bottom-content-wrap { height: auto!important;}
    .Reactinclude,.vConnectMainWrap, .vConnectMainWrap .row.g-0 { height: 100%;}
    .btn-addToChat .desktop-hide-icon { display:block;}  
    .vConnectMainWrap .rightside-wrapper .member-list-wrapper .bottom-content-wrap { padding-top: 0;} 
    .emp-back-arrow { background: var(--bkg_neutral_default) !important; min-height: 57px; display:block; }
    .emp-back-arrow i { padding: 20px 16px; cursor: pointer;}
    #leftBar { display: none!important;}
    .vConnectMainWrap .chatarea .chatListBlock { max-width: 392px; width: 392px; flex: 0 0 392px;}
    .rightSlide { left:-392px; right: auto; max-width: 392px;}
    .rightSlide { width: 392px; max-width: 392px; flex: 0 0 392px;}
    .rightSlide.rightSlide-open { left: 0;} 
    .vConnectMainWrap .rightside {max-width: 392px; }
    .vConnectMainWrap .rightside-wrapper .accordion-body { padding:4px 16px; background-color: #fff; border-radius: 10px; margin-bottom: 16px!important; }
    .vConnectMainWrap .rightside-wrapper.client-details .profile-wrapper{ margin-bottom:0px!important; display: flex; flex-direction: column;}
    .profile-wrapper.services-list-wrap .profile-image { order: 1;}
    .profile-wrapper.services-list-wrap .name-wrap { order: 2;}
    .profile-wrapper.services-list-wrap .customer-detail-wrap { order: 3; margin-top: 0; margin-bottom: 8px!important;}
    .profile-wrapper.services-list-wrap .membership-wrap { order: 4;}
    .vConnectMainWrap .rightside-wrapper .accordion-item .accordion-header .accordion-button:after { left:0; right: auto;}
    .vConnectMainWrap .rightside-wrapper .appointment-detail { padding:12px 0 12px 0;  border-bottom: solid #e7e7e7 1px; align-items: center;}
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.upcoming-appt-wrap .appointment-detail { padding-bottom: 16px;}
    .vConnectMainWrap .rightside-wrapper .appointment-detail:last-of-type {  border-bottom: 0px;   }
    .vConnectMainWrap .rightside-wrapper .appointment-detail .service-wrap .date-wrap { font-size: 14px; line-height: 20px;}
    .vConnectMainWrap .rightside .dropdown-toggle {white-space: normal;margin: 0 !important;padding: 0 !important;}
    .vConnectMainWrap .rightside-wrapper .popup-note-wrap .note { margin: 0;}
    .vConnectMainWrap .rightside-wrapper .profile-wrapper .name-wrap h3 { margin:3px 0px 0 0;}
    .vConnectMainWrap .rightside-wrapper .contact-wrap a { align-items: center; justify-content: center;}
    .vConnectMainWrap .rightside-wrapper .appointment-detail .date:first-of-type { padding-top: 12px;}
    .vConnectMainWrap .rightside-wrapper .appointment-detail .date {font-size: 14px;line-height: 20px;}
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.purchases-wrap .appointment-detail { padding: 12px 0;}
    .personal-info .avatar { width: 96px; height: 96px; border-radius: 100%;}
    .personal-info .avatar[data-avatar="1"] .avatar-group-img-wrap img { width: 96px; height: 96px;}
    .personal-info .avatar[data-avatar="1"] .avatar-group-img-wrap { width: 97px; height: 97px;}
    .personal-info .avatar[data-avatar="3"] .avatar-group-img-wrap:first-child { width: 97px; height: 97px;}
    .vConnectMainWrap .customer-detail-wrap { margin-top: 0px;}
    .vConnectMainWrap .employee-detail .customer-detail-wrap:first-of-type { margin-top: 0px; padding-bottom: 0px!important;}
    // .vConnectMainWrap .employee-detail .customer-detail-wrap:first-of-type a { margin-top: 0px;}
    .vConnectMainWrap .rightside-wrapper .businessLocationWrap .contact-wrap a {margin-top: 0;}
    .detailsActive .personal-info .avatar[data-avatar="1"] .review-profile-inner-text { font-size: 18px;}
    .vConnectMainWrap .rightside-wrapper .contact-wrap a { font-size: 14px; line-height: 18px; margin: 6px 0 0 0;}
    .vConnectMainWrap .rightside .businessLocationBlock .locationList .location { font-size: 14px; margin:5px 0;}
    .vConnectMainWrap .rightside .businessLocationBlock .locationList .location:first-child { margin-top: 0px;}
    .vConnectMainWrap .rightside-wrapper.employee-detail .contact-wrap a { align-items:flex-start; justify-content:flex-start;}
    .vConnectMainWrap .rightside-wrapper.employee-detail .customer-detail-wrap {margin-top: 0; margin-bottom: 0px; padding-bottom: 16px;}
    .vConnectMainWrap .rightside-wrapper.employee-detail .businessLocationWrap:not(:empty) {border-top: solid 1px #e7e7e7; padding-top: 16px; margin-top: 16px; }
    .vConnectMainWrap .rightside-wrapper.client-details .services-list-wrap { margin-bottom: 0px;}
    .vConnectMainWrap .rightside-wrapper.employee-detail .bottom-content-wrap { max-height: 100%; height: auto!important; margin-bottom: 16px; overflow:auto;  padding: 16px!important; background-color: #fff; border-radius: 10px; margin: 0 16px; margin-top: 16px;}
    .detailsActive .personal-info .avatar .avatar-group-img-wrap-text { text-align: center; padding-left: 0; padding-top: 5px;}
    .detailsActive .personal-info .avatar .avatar-group-img-wrap div .review-profile-inner-text { font-size: 12px;}
    .vConnectMainWrap .rightside-wrapper.employee-detail-page .members-list-wrap { max-height:calc(100% - 28px)!important;  overflow:auto;  padding: 16px!important; background-color: #fff; border-radius: 10px;}
    .rightside-wrapper .accordion-item .accordion-header .accordion-button { box-shadow: none!important; padding-left: 18px!important; font-size: 12px; font-weight: 500; line-height: 16px;}
    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-header { display: none;}
    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person .avatar .review-profile-inner-text { width: 51px; height: 51px;}
    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person_number { font-size: 14px; line-height: 18px;}
   .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person_name { font-size: 16px; line-height: 22px;}
   .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person:last-of-type { margin-bottom:0px;}
   .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person:first-of-type { margin-top:0px;}
   .vConnectMainWrap .rightside-wrapper.employee-detail-page .personal-info .avatar-group-img-wrap-text {
    // font-size: 16px;
    // font-weight: 600;
    // padding: 8px;
    line-height: 24px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

   .vConnectMainWrap .rightside-wrapper.employee-detail-page .team-employee-name .chatList-person_name {font-style: normal;font-weight: 600; line-height: 24px; font-size: 20px;}
   .team-heading { display: block !important; font-size: 12px;font-style: normal;font-weight: 500;line-height: 16px; text-align: left; text-transform: uppercase; margin-bottom: 10px;}
   .vConnectMainWrap .rightside .rightside-wrapper {background-color: var(--bkg_neutral_secondary) !important;}
   .employee-detail-page .chatList-person .avatar.avatar-sm { width: 50px; height: 50px; border-radius: 100%; flex: 0 0 50px;}
   .employee-detail-page .chatList-person .avatar.avatar-sm .avatar-group-img-wrap { width: 51px; height: 51px} 
   .employee-detail-page .chatList-person .avatar.avatar-sm .avatar-group-img-wrap img { width: 50px; height: 50px;}
   .employee-detail-page .chatList-person .avatar.avatar-sm .avatar-group-img-wrap .review-profile-inner-text { width: 51px; height: 51px;}
   .appointment-detail .mobile-contextmenu.dropdown i.fa-ellipsis-vertical { width: 24px; text-align: right;} 
   .vConnectMainWrap .chatList-person .chatList-person_dotMenu { display: block!important;}
   .vConnectMainWrap .rightside-wrapper .member-list-wrapper .chatList-person_dotMenu { margin-top:-9px; top: 50%;}
   .vConnectMainWrap .rightside-wrapper .bottom-content-wrap { height:auto!important;}


  //top BAr
  .vConnectMainWrap .rightSideOnly-navBlock { display: flex !important;;}
  //Read Unread hidden
 .vConnectMainWrap .chatarea .chatListBlock .chatList-header { display: none;}

//Search Bar
.vConnectMainWrap .chatarea .chatListBlock-searchBlock .serachGrooup {
    .searchFieldIcon {
        color: var(--icon_neutral_weak);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        line-height: 16px;
        top: 12px;
    }
    input.searchField {
        border-radius: 24px;
        background: var(--bkg_neutral_secondary) !important;
        padding: 7px 30px;

        color: var(--text_neutral_default);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border: 0px!important;

        &::placeholder {
            color: var(--text_neutral_weak);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
    
    // .mobileCancel {
    //     display: none;
    //     color: var(--text_neutral_default);
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: 20px;
    //     margin: 8px;
    //     text-decoration: none;
    // }
    // &.searchActive {
    //     .mobileCancel {
    //         display: block;
    //     }
    //     .searchFieldIcon {
    //         right: 76px;
    //     }
    // }
}
/*- Select2 search Bar -*/

.vConnectMainWrap .rightSideOnly-navBlock {
    border-radius: 24px;
    background: var(--bkg_neutral_secondary) !important;
    width: 250px;
    margin: 15px auto;
    margin-bottom: 0;
    .rightSideOnly-navBlock_option {
        color: var(--text_neutral_weak);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border: none;
        padding: 3px 10px;
        cursor: pointer;
        min-width:125px;
        text-decoration: none;
        &.active {
            color: var(--text_neutral_ondark) !important;
            border-radius: 24px;
            border: 0.5px solid rgba(0, 0, 0, 0.04);
            background: var(--Gradient-Linear, linear-gradient(180deg, #0A6A9F 0%, #533C89 100%));
            box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
        }
        // @media only screen and (min-width: 400px) {
        //     padding: 7px;
        // }
    }
}

 /*- START: conversation -*/    
    .vConnectMainWrap {
        .chatarea {
            .chatListBlock {
                .chatList-person {
                    padding: 6px 12px;

                    &.personRead .chatList-person_messages {
                        font-weight: 400;
                    }
                    .chatList-person_messages {
                        font-weight: 500;
                    }
                    &_left {
                        // margin-left: 0;
                        .avatar.avatar-md {
                            height: 60px;
                            width: 60px;
                            flex: 0 0 60px;
                            border-radius: 30px;
                            .avatar-group-img-wrap .review-profile-inner-text {
                                font-size: 12px;
                            }
                            .avatar-group-img-wrap {
                                img {
                                    height: 100%;
                                    width: 60px;
                                }
                            }
                            &[data-avatar="1"] {
                                .avatar-group-img-wrap {
                                    height: 61px;
                                    width: 61px;
                                    .review-profile-inner-text {
                                        font-size: 14px;
                                    }
                                }
                            }
                            &[data-avatar="3"] {
                                .avatar-group-img-wrap:first-child {
                                    height: 61px;
                                    width: 61px;
                                }
                                .avatar-group-img-wrap img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                            &[data-avatar="4"] {
                                .avatar-group-img-wrap-text {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 14px;
                                }
                            }
                           
                        }
                    }
                    // .status {
                    //     position: absolute;
                    //     right: 13px;
                    //     bottom: 22px;
                    // }
                }
            }
        }
    }
   
    .vConnectMainWrap .pinnedGroupMobile .chatList-person:hover .chatList-person_time { display: none!important;}
    /*- END: conversation -*/

    .pinnedBlockWrap {
        overflow-x: auto;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding-left: 16px;
        margin-top: 8px;
        margin-bottom: 8px;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
        .pinnedBlock {
            margin: 8px 7px;
            .avatar.avatar-md {
                height: 60px;
                width: 60px;
                flex: 0 0 60px;
                border-radius: 30px;
                .avatar-group-img-wrap {
                    height: 60px;
                    width: 60px;
                    .review-profile-inner-text {
                        font-size: 14px;
                    }
                }
            }
            .pinnedName {
                color: #000;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-top: 4px;
                white-space: nowrap;
                overflow: hidden;
                max-width: 60px;
                text-overflow: ellipsis;
                text-align: center;
            }
        }
    }

    .chatList-group .accordion-header {
        display: none;
    }
    .pinnedGroupMobile {
        .chatList-person {
            .chatList-person_dotMenu,
            .chatList-person_right-bottom,
            .chatList-person_time,
            .status {
                display: none !important;
            }
        }

        .accordion-body {
            overflow-x: auto;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            padding-left: 16px !important;
            margin-top: 0px;
            margin-bottom: 8px;
            
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            .avatar {
                margin-right: 0 !important;
            }
            .chatList-person {
                display: flex;
                flex-direction: column;
                border-bottom: none !important;
                margin: 8px 5px;
                padding: 0 !important;
                width: 60px;
                &_right {
                    width: 100% !important;
                }
            }

            .chatList-person_right {
                &-top {
                    justify-content: center !important;
                }
            }

            .chatList-person_name {
                max-width: 100% !important;
                color: #000 !important;
                text-align: center;
                font-size: 12px !important;
                font-style: normal !important;
                font-weight: 800 ;
                line-height: 16px !important;
                margin-top: 4px;
                padding-right: 0px;
            }

        }
    }

//top right Bar
.vConnectMainWrap .chatarea .messagesArea-header { padding: 8px 16px;}
.vConnectMainWrap .chatarea .chatListBlock-searchWrap { order: 2; padding-top: 20px;}
.vConnectMainWrap .rightSideOnly-nav {order: 1;}
.chatList { order: 3; display: flex; flex-direction: column; height:calc(100% - 117px)!important;  }
.vConnectMainWrap .chatarea .chatListBlock .chatList-groupBlock { height: auto!important; flex-grow: 1; -ms-overflow-style: none;
    scrollbar-width: none; }
    .vConnectMainWrap .chatarea .chatListBlock .chatList-groupBlock::-webkit-scrollbar { display: none;}  
.vConnectMainWrap .chatarea .chatListBlock { display: flex; flex-direction: column;}
.chat-svg-icon { display: none;}
&.vagaroConnect .vConnectMainWrap .rightSideOnly-nav { width: auto;}

.vConnectMainWrap .chatarea .chatListBlock-searchBlock .btn-addToChat svg { flex: none;}
.vConnectMainWrap .chatList-group.pinnedGroupMobile .chatList-person.active { background-color: transparent!important;}
.block-status { order: 5; margin-top: 6px;}
.vConnectMainWrap .rightside-wrapper.client-details .personal-info { padding-top: 24px; padding-bottom: 10px;}
.vConnectMainWrap .rightside-wrapper .personal-info { padding-top: 24px;}
.vConnectMainWrap .chatarea .chatListBlock-searchBlock .btn-addToChat { border-radius: 100%; width: 40px; height: 40px; background: linear-gradient(180deg, #0A6A9F 0%, #533C89 100%);}
.vConnectMainWrap .chatarea .chatListBlock-searchBlock .btn-addToChat:hover {background:linear-gradient(139deg, #076A9F 45.18%, #533C89 127.99%);}
// .vConnectMainWrap .chatarea .chatListBlock-searchBlock .btn-addToChat:focus {background: linear-gradient(145deg, #076A9F -45.07%, #533C89 67.63%);}
//send button
.ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send { min-width: 80px; padding: 4px 16px; background: linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%)!important;}
.ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send:hover {background: linear-gradient(139deg, #076A9F 45.18%, #533C89 127.99%)!important;}
// .ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send:focus, .ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send:active {background:linear-gradient(145deg, #076A9F -45.07%, #533C89 67.63%)!important;}
.vConnectMainWrap .rightside .businessLocationBlock .locationList .locationToogle { font-size: 14px;}
.vConnectMainWrap .rightside .businessLocationBlock { padding-top: 6px;}
.vConnectMainWrap .rightSideOnly-nav { order: 0;}
.vConnectMainWrap .rightSideOnly-nav { width: 391px!important;}
.vConnectMainWrap .welcomeScreen { width: calc(100vw - 392px)!important;}
.vConnectMainWrap .welcomeScreen-block_title {
    margin-top: 20px;
    font-size: 22px;
}
.vConnectMainWrap .welcomeScreen-block_info {
    margin-top: 12px;
    font-size: 14px;
}
.welcomeScreen-block .app-hide { display: none;}
.welcomeScreen-block .aap-show { display:inline-block;}
.vConnectMainWrap {
    .upgradeBlockWrap {
        padding: 16px;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        display:block;
        order: 4;            
        .upgradeBlock {
            border-radius: 8px;
            background: var(--Gradient-Diagonal, linear-gradient(149deg, #076A9F 8.76%, #533C89 103.94%)) !important;
            padding: 16px;
            margin-bottom: 0;
            display: block;
            .textArea {
                &-title {
                    svg {
                        display: none;
                    }
                    color: var(--text_neutral_ondark) !important;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    margin-bottom: 4px;

                    .closeupgradeBlock {
                        position: absolute;
                        top: 30px;
                        right: 21px;
                        svg {
                            display: block;
                            path {
                                fill: var(--icon_neutral_ondark) !important;
                            }
                        }
                    }
                }
            }
            &-btnAreea {
                margin-top: auto;
                display: flex;
                align-items: self-end;
                justify-content: space-between;
                flex-direction: row;
                text-align: left;

                .textArea-info {
                    color: var(--text_neutral_ondark) !important;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    max-width: 194px;
                }
                
                .upgradeBlock-btn {
                    border-radius: 56px;
                    border: 1px solid var(--border_components_button_default);
                    background: var(--bkg_neutral_default);

                    span {
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        background: var(--Gradient-Diagonal, linear-gradient(149deg, #076A9F 8.76%, #533C89 103.94%));
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
}

}
}

body.vcMobile:has(#div_Swipe_Dropdown .dropdown-menu.show) .chatList-groupBlock, body.vcTab:has(#div_Swipe_Dropdown .dropdown-menu.show) .chatList-groupBlock {
    overflow: hidden !important;
}

/*-- START: New popUp modal for text Plan --*/
.upgradeText {
    // &-modal {}
    &-block {
        text-align: center;
        &_img {
            margin-bottom: 16px;
        }
        &_title {
            color: var(--text_neutral_default);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 8px;
        }
        &_info {
            color: var(--text_neutral_default);
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding: 0 40px;
        }
    }

}
.textingUnlocked {
    &-modal {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
    &-block {
        text-align: center;
        &_img {
            margin-bottom: 16px;
        }
        &_title {
            color: var(--text_neutral_default);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 8px;
        }
        &_info {
            color: var(--text_neutral_default);
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding: 0 40px;
        }
    }


}
.vcMobile {
    .textingUnlocked,
    .upgradeText {
        &-cancelbtn {
            display: none;
        }
        &-viewPlanBtn {
            margin-top: 0 !important;
        }
    }

}
.collapsibleDropDown {
    .textingUnlocked-block {
        &_img {
            max-width: 100px;
        }
    }
}
.alert-text {
    text-align: center;
    font-size: 12px;
    color: var(--text_alert_default);
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 2px;
    padding-top: 2px;
    background: var(--bkg_neutral_secondary) !important;
    a {
        text-decoration: underline !important;
        cursor: pointer;
    }

}
.vConnectMainWrap {
    .rightside .rightside-wrapper .contact-wrap {
        &.addNumberBlock {
            a {
                color: var(--text_blue_default) !important;
                cursor: pointer;
            }
        }
    
    }
    .typeChatWrap {
        &.textmarketing-composerwrap {
            .typeMsgText {
                position: absolute;
                width: calc(100% - 30px);
                top: 10px;
            }            
        }
    } 
}
/*-- END: New popUp modal for text Plan --*/
.customerChatWindow .vConnectMainWrap .smallChatBox-middle .chatarea .messagesArea-middle .messagesBox.messagesEditWrap {
    .messages {
        background: transparent !important;
        
        .ck-editor.ck-reset {
            border-radius: 15px;
        }
    }
}
/*- START: restricted text -*/
.restricted-alert {
    color: var(--text_alert_default);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    padding-bottom: 3px;
    background: var(--bkg_neutral_secondary)!important;
    a {
        text-decoration-line: underline !important;
        cursor: pointer;
        color: var(--text_alert_default) !important;
    }
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent.messagesEditWrap .messages .restricted-alert a {
    color: var(--text_alert_default) !important;
}
.vConnectMainWrap .restrictedText-composerwrap {
    .typeMsgText {
        top: 5px;
        position: absolute;
        width: calc(100% - 30px);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.messagesEditWrap {
    .restrictedText-alert {
        .ck.ck-editor__top,
        .ck.ck-editor__main {
            background: #FBECEB;
        }
    }
}

.vConnectMainWrap.chatActive.rightSideOnly.smallScreen .messagesBox.messagesEditWrap .ck.ck-reset.ck-editor.restrictedText-alert .ck.ck-toolbar.ck-toolbar_grouping {
    background: #FBECEB !important;
}

.mobileListmenuWrap .ck-editor.ck-reset.restrictedText-alert {
    border-color: var(--border_alert_default)!important;
}
.restrictedText-modal {
    max-width: 540px;
    .modal {
        &-header {
            padding: 12px 12px 0 20px;
            .modal-title {
                color: var(--text_neutral_default);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
        &-body {
            padding: 16px 20px 24px 20px;
        }
        &-footer {
            .btn.btn-primary {
                padding: 10px 29px;
            }
        }
    }
    .restricted-questionBlock {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .restricted-question {
        margin-bottom: 4px;
        color: var(--text_neutral_default);
        opacity: 0.80;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .restricted-answer {
        color: var(--text_neutral_default);
        opacity: 0.80;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

.vcMobile:not(.vcTab-portrait) {
    .restricted-alert {
        font-size: 16px;
        padding-left: 38px;
        padding-right: 38px;
    }
}
.vcMobile,
.vcTab,
.vcTab-portrait {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesEditWrap {
        .restricted-alert {
            position: relative;
            z-index: 9;
        }
    }
}
.restrictedWordHighlight {
    color: var(--text_alert_default);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
}
.vConnectMainWrap.rightSideOnly .collapsibleChat.restrictedText-composerwrap {
    border-top: none;
    .restricted-alert {
        padding-bottom: 16px;
    }

    .ck.ck-reset.ck-editor.ck-rounded-corners {
        border-top: 1px solid var(--border_neutral_strong) !important;
    }
    &.mobileActiveChat {
        .restricted-alert {
            padding-top: 5px;
            padding-bottom: 10px;
        }
        .mobileListmenuWrap {
            border-top: 1px solid var(--border_neutral_strong) !important;
        }

        .ck.ck-reset.ck-editor.ck-rounded-corners {
            border-top: none !important;
            
        }

    }
}

.customerChatWindow .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .ck-reset.ck-editor .ck-editor__main {
    border: 1px solid var(--border_alert_default);
}
.customerChatWindow .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .gifPluginBlock {
    z-index: 99;
}

.customerChatWindow {
    .vConnectMainWrap .smallChatBoxWrap .collapsibleChat {
        &.restrictedText-composerwrap {
            border-top: none;
            .typeMsgText {
                top: 0;
                padding-top: 8px;
            }
        }
    }
    .restrictedText-composerwrap {
        .restricted-alert {
            background: var(--bkg_neutral_secondary)!important;
            padding-bottom: 16px;
        }
        .mobileListmenuWrap .ckeditor_1 .ck-reset.restrictedText-alert {
            background: var(--bkg_neutral_default) !important;
            position: relative;
        }
        .restrictedText-alert {
            .ck.ck-editor__main {
                background: #fbeceb !important;
                border: 1px solid var(--border_alert_default);
            }
        }
    }
    &.ris-popup {
        &.modal-open {
            .fade.modal-backdrop {
                max-width: 370px;
                z-index: 9999;
                left: auto;
                right: 9px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .fade.modal {
                max-width: 370px;
                z-index: 9999;
                left: auto;
                right: 9px;
            }
        }
    }
}
.websitebuilder-loaded { 
    .customerChatWindow {
        .restrictedText-composerwrap,
        .messagesEditWrap {
            .restricted-alert {
                color: #C5392F !important;
                background: #f8f8f8 !important;
            }
        }
        .restrictedWordHighlight {
            color: #C5392F !important;
        }
        .messagesEditWrap  {
            .restrictedText-alert {
                border-color: #D83F34 !important;
            }

        }

        .restrictedText-composerwrap .mobileListmenuWrap .ckeditor_1 .ck-reset.restrictedText-alert {
            background: #fff !important;
        }
    }
    .vgn-modal-alert .modal-content {
        background: #fff !important;
    }
    .vgn-modal-alert .modal-footer {
        border-top: none !important;
        .btn.btn-primary {
            background: var(--bkg_green_default) !important; 
            border-color: var(--bkg_green_default) !important;
            color: #fff !important;

            &:hover {
                background: var(--bkg_components_button_green_hover) !important; 
                border-color: var(--bkg_components_button_green_hover) !important;
            }

        }

        // Mysite design changes for restricted text
        .btn.btn-primary.vcgradientbtn.vcprimary-btn {
            background: #3e8438 !important; 
            border-color: #3e8438 !important;
            color: #fff !important;

            &:hover {
                background: #387833 !important; 
                border-color: #387833 !important;
            }

        }
    }
    .customerChatWindow.ris-popup.modal-open .fade.modal-backdrop {
        right: 0 !important;
        max-width: 380px !important;
    }
    .mobileListmenuWrap .ck-editor.ck-reset.restrictedText-alert {
        border-color: #e7e7e7 !important;
    }
}
/*- END: restricted text -*/
/*- START: Empty State in box -*/
.emptyInboxStateCustomer {
    &-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        // height: calc(100% - 190px);
        justify-content: center;

        h3 {
            margin-top: 16px;
            margin-bottom: 4px;
            color: var(--text_neutral_default);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
        p {
            color: var(--text_neutral_secondary);
            font-size:  15px;
            font-style: normal;
            font-weight: 400;
            margin: 0;
        }
    }
}
.vConnectMainWrap .chatarea.emptyStateBlock {
    width: 319px;
    min-width: 319px;
    flex-grow: 0;

    .emptyInboxStateCustomer-wrap {
        padding-top: 24px;

        img {
            max-width: 50%;
        }
    }
}
.vcMobile {
    .emptyInboxStateCustomer {
        &-wrap {
            justify-content: start;
            margin-top: 26px;
        }
    }

    .chatarea {
        width: 100% !important;
    }
}
.vConnectMainWrap.rightSideOnly.smallScreen {
    .emptyInboxStateCustomer-wrap {
        height: calc(100vh - 181px);
    }
    .select2.select2-container.select2-container--default.select2-container--below.vcEmployeeSelect.ddlEmployee.select2-container--focus {
        height: 60px;
    }
}
.vcMobile {
    &.modal-open {
        .fade.modal-backdrop.show,
        .fade.modal.show {
            z-index: 999999;
        }
 
    }
}
 
/*- END: Empty State in box -*/

/*- START: Video upload -*/
.videoControlsMainWrap {
    position: relative;
    flex-grow: 1;
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;

    &.fullscreen {
        .videoWraper {
            height: 100vh !important;
        }
        > div {
            width: 100%;
            height: 100%;
        }
    }
    .videoControlsWrap {
        opacity: 0;
        transition: opacity 0.5s ease-out;
        -webkit-transition: opacity 0.5s ease-out;  
        -moz-transition: opacity 0.5s ease-in-out; 
        
    }

    &:hover {
        .videoControlsWrap {
            opacity: 1;
        }
    }
}
.videoMainwrap {
    .videoPlayer {
        width: 284px;
        // height: 180px;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        -webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
        box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
        background: #4b4a4ac2;
        .videoPlayerBlock {
            video {
                width: 100%;
            }
        }
        .Loading {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
        
    }
    
}

.loadingWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 40px);
    background: #4b4a4ac2;
    flex-wrap: wrap;
    flex-direction: column;
    .videoloader {
        display: block;
    }
    .videoloader {
        width: 28px;
        height: 28px;
        border: 3px solid #f5f5f5;
        border-bottom-color: var(--ButtonBackColor);
        border-radius: 50%;
        display: block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }
    .loaderText {
        margin-top: 10px;
        color: #fff;
        font-size: 14px;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    } 

    &.uploadFaild {
        cursor: pointer;
    }
}
.inChatLoading {
    // position: absolute;
    // top: 0;
    // left: 0;
    height: 100%;
    width: 100%;    
    .loadingWrap {
        height: 100%;
    }
}
.volumeMainWrapRange {
    background: #0000008a;
    position: absolute;
    bottom: 68px;
    z-index: 3;
    clear: both;
    transform: rotate(-90deg);
    left: -31px;
    padding: 5px;
    width: 90px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    border-radius: 10px;
}
.videoWrapInmessage {
    position: relative;
    z-index: 4;

    &:has(.videoDropdown.mobile-contextmenu.dropdown.show) {
        z-index: 5;
    }
    .videoControlsWrap {
        position: absolute;
        bottom: 0;
        background: #0000008a;
        padding: 5px;
        width: 100%;
        
        .videoRangeWrap {
            width: 100%;
            position: relative;

            input[type="range"] {
                -webkit-appearance: none;
                appearance: none; 
                width: 100%;
                cursor: pointer;
                outline: none;
                overflow: hidden;
                border-radius: 16px;
                height: 4px;
                
                &::-webkit-slider-runnable-track {
                    height: 4px;
                    background: #afafaf;
                    border-radius: 16px;
                }
                
                &::-moz-range-track {
                    height: 4px;
                    background: #afafaf;
                    border-radius: 16px;
                }
                
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none; 
                    height: 10px;
                    width: 10px;
                    background-color: #fff;
                    border-radius: 50%;
                    // border: 2px solid #f50;
                    box-shadow: -1000px 0 0 1000px #fff;
                    margin-top: -3px;
                }
                
                &::-moz-range-thumb {
                    height: 10px;
                    width: 10px;
                    background-color: #fff;
                    border-radius: 50%;
                    // border: 1px solid #f50;
                    box-shadow: -1000px 0 0 1000px #fff;
                }                  
            }
            .videoPreloadRange {
                position: absolute;
                top: 11px;
                z-index: 1;
                &::-webkit-slider-thumb {
                    background-color: #d5d5d5 !important;
                    box-shadow: -1000px 0 0 1000px #d5d5d5 !important;
                }
                &::-moz-range-thumb {
                    background-color: #d5d5d5 !important;
                    box-shadow: -1000px 0 0 1000px #d5d5d5 !important;
                }
            }
            .videoProgressRange {
                background: transparent !important;
                position: relative;
                z-index: 2;
                &::-webkit-slider-runnable-track {
                    background: transparent !important;
                }
                &::-moz-range-track {
                    background: transparent !important;
                }
                &::-ms-track {
                    background: transparent !important;
                }
            }
        }
        .volumeWrap {
            display: inline-block;
            position: relative;
            .volumeWrapRange {
                display: block !important;
                // position: absolute;
                // transform: rotate(-90deg);
                // left: -50px;
                // bottom: 81px;
            }
            input[type="range"] {
                -webkit-appearance: none;
                appearance: none; 
                width: 100%;
                cursor: pointer;
                outline: none;
                overflow: hidden;
                border-radius: 16px;
                
                &::-webkit-slider-runnable-track {
                    height: 4px;
                    background: #afafaf;
                    border-radius: 16px;
                }
                
                &::-moz-range-track {
                    height: 4px;
                    background: #afafaf;
                    border-radius: 16px;
                }
                
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none; 
                    height: 10px;
                    width: 10px;
                    background-color: #fff;
                    border-radius: 50%;
                    // border: 2px solid #f50;
                    box-shadow: -407px 0 0 400px #fff;
                    margin-top: -3px;
                }
                
                &::-moz-range-thumb {
                    height: 10px;
                    width: 10px;
                    background-color: #fff;
                    border-radius: 50%;
                    // border: 1px solid #f50;
                    box-shadow: -407px 0 0 400px #fff;
                }                  
            }
            &:hover {
                .volumeWrapRange {
                    display: block;
                }
            }
        }
        .videoSpeed {
            font-size: 12px;
            font-weight: 400;
            padding: 5px;
            text-align: center;
            margin: 0 5px;
            display: inline-block !important;
            background: transparent;
            color: #fff;
            border: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-image: none;
            &:hover {
                background: #0000008a;
            }

            .dropdown-toggle {
                margin: 0 auto;
                padding: 0;
                display: block;
                border: none;
                color: #fff;
                font-size: 12px;

                &:after {
                    display: none;
                }

                &:active,
                &:focus,
                &:hover,
                &.show {
                    border: none;
                    padding: 0;
                    box-shadow: none;
                    background-color: transparent;
                    border-color: transparent;
                    height: auto;
                }
            }
            .dropdown-menu {
                transform: translate3d(10px, -32.4px, 0px) !important;
                height: 85px;
                overflow: auto;
                min-width: 60px;
                background: #0000008a !important;
                &[x-placement='bottom-start'] {
                    transform: translate3d(10px, -32.4px, 0px) !important;
                    left: -42px !important;
                    top: -60px !important;
                    right: 0 !important;
                    bottom: 0 !important;
                }
                .dropdown-item.videoSpeedoption {
                    font-size: 12px;
                    font-weight: normal;
                    color: #fff !important;
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    .videoSpeedoptionValue {
                        margin-left: 5px;
                    }
                    &:hover {
                        background: transparent !important;
                    }
                    .fa-duotone.fa-check {
                        float: left;
                        opacity: 0;
                    }

                    &.selected {
                        .fa-duotone.fa-check {
                            opacity: 1;
                        }
                    }
                }

                &::-webkit-scrollbar {
                    width: 4px !important; 
                    background-color: #d9d9d9;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #fff;
                    border-radius: 10px;
                    position: relative;
                    z-index: 999;
                }
            }
        }
    }


    .firstPlaywrap {
        height: 175px;
        width: 300px;
    }
    
    &.multipal {
        display: flex;
        flex-wrap: wrap;
        .firstPlaywrap {
            flex-basis: 32%;
            margin-right: 10px;
            height: auto;
            &:nth-child(3) {
                margin-right: 0;
            }
        }
        .videoWraper {
            width: 100% !important;
            height: 100% !important;
        }
        &[data-video="2"] {
            .firstPlaywrap {
                flex-basis: 47.3% !important;
                width: 47.3% !important;
            }
        }
    }
}
.videoWrapInmessage.videoMainwrap {
    .videoPlayerBlock {
        width: 100%;
    }
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox-block {
    &.multipleVideoWrap {
        max-width: 80%;
    }
    .videoWrapInmessage.videoMainwrap {
        display: flex;
        flex-wrap: wrap;
        .videoPlayer {
            flex-basis: 32%;
            margin-right: 10px;
            margin-bottom: 10px;
            &:nth-child(3) {
                margin-right: 0;
            }
        }
        &[data-video="1"] {
            .videoPlayer {
                flex-basis: 100%;
            }
        }
    }
}

.videoWrapInmessage.videoMainwrap.uploadMultipalVideo {
    display: flex;
    flex-wrap: wrap;
    .videoPlayer {
        flex-basis: 31% !important;
        margin-right: 10px;
        margin-bottom: 10px;
        &:nth-child(3) {
            margin-right: 0;
        }
        video {
            object-fit: cover;
        }
    }
    .videoWraper {
        height: 95px !important;
    }
    .videoControlsMainWrap {
        .inChatLoading {
            height: 95px !important;
        }
    }
    

    &.moreThenthree {
        .videoPlayer {
            box-shadow: 0 0 3px 0 #0003;
            border-radius: 3px;
            .videoPlayerBlock {
                display: none;
            }
            .video-footer {
                border-radius: 0;
            }
        }
    }
}

.videoWrapInmessage.multipal {
    &[data-video="2"] {
        .videoWraper {
            height: 88px !important;
        }
        .videoControlsMainWrap {
            .inChatLoading {
                height: 88px !important;
            }
        }
    }
}

.videoControl {
    background: none;
    border: none;
    outline: none;
    color: #fff;
    font-size: 12px;
    color: #000;
    padding: 4px;
    opacity: 0.8;
    &-timing {
        display: inline-block;
        margin-top: 1px;
        &:hover {
            background: transparent !important;
        }
    }
    // &-playPush,
    // &-backword,
    // &-forword {
    //     width: 22px;
    // }
    &-valume {
        // width: 34px;
    }

    &-bottomPanel {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &:hover {
        background: rgba(0,0,0,.541);
    }
    &-copyUrl {
        margin-right: 5px;
        &:hover {
            background: transparent;
        }

        .fa-regular.fa-circle-check {
            color: var(--border_green_strong);
        }
    }
}
.vConnectMainWrap {
    .videoControl {
        svg,
        svg path {
            fill: #fff !important;
        }
    }
}
.videoControl-bottomPanel {
    .videoControl {
        color: #fff;
        vertical-align: middle;
    }
}
.copyUrlWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
}
.firstPlaywrap {
    margin-bottom: 10px;
    border-radius: 8px;
    // overflow: hidden;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
}
.videoDropdown {
    .dropdown-toggle {
        padding: 0;
        display: block;
        border: none;

        &:after {
            display: none;
        }

        &:active,
        &:focus,
        &:hover,
        &.show {
            border: none;
            padding: 0;
            box-shadow: none;
            background-color: transparent;
            border-color: transparent;
            height: auto;
        }
    }
    .dropdown-item {
        font-size: 14px;
    }    
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages {
    .videoDropdown {
        a.dropdown-item {
            color: var(--text_neutral_default) !important;
        }
    }
}
.video-footer {
    background: #FAFAFA;
    // position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .videoFileName {
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #000;
        display: flex;
        align-items: center;
        .videoFileNameBlock {
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
        }

        .fa-sharp.fa-regular.fa-circle-play {
            margin-right: 5px;
            color: var(--ButtonBackColor);
        }
        .loadingWrap {
            display: block;
            height: 20px !important;
            width: 20px !important;
            background: transparent !important;
            margin-right: 10px;
            // position: absolute;
            // top: 0;
            // left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .videoloader {
                height: 20px;
                width: 20px;
            }            
        }
    }
    .videoClose {
        background: none;
        border: none;
        outline: none;
        padding-right: 0;
        opacity: 0.8;
        &:hover {
            opacity: 1;
        }
    }
    .videoDropoptionButton {
        border: none;
        background: none;
        outline: none;
        padding: 0px 0px 0px 10px;
        color: var(--text_neutral_default) !important;
    }
}
.videoWraper {
    display: flex;
    height: 134px !important;
    width: 100% !important;
}
.videoControlsMainWrap {
    &.videoPlaying {
        video {
            object-fit: contain;
        }
    }
    &.fullscreen {
        video {
            object-fit: contain;
        }
    }
}
.firstPlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(0,0,0,.3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    z-index: 0;
    cursor: pointer;
    .videoControl.videoControl-playPush {
        background: rgba(61, 61, 61, .8);
        border: none;
        width: 50px;
        height: 50px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, .28);
        border-radius: 100%;
        padding: 10px;
        path {
            fill: #fff !important;
        }
    }

    &.fullScreen {
        background: transparent;
    }
    &.canvasWrap {
        background: #fff !important;
    }
    // .canvasOverlay {
    //     background: #fff;
    //     height: 100%;
    //     width: 100%;
    //     position: absolute;
    // }
    canvas.firstPlayImg {
        position: absolute;
        height: 100%;
    }
}
.loadtoFailText {
    background: rgba(75, 74, 74, 0.7607843137);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    padding: 5px;
    color: #e9e9e9;
    overflow: auto;
    h2 {
        font-size: 12px;
        line-height: 20px;
    }
    p {
        font-size: 10px;
        line-height: 16px;
    }
}
.messagesEditWrap {
    .videoMainwrap .videoPlayer {
        width: 100%;
        // height: 236px;
        .videoPlayerBlock {
            width: 100%;
        }
    }
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages {
    .videoWrapInmessage.multipal {
        .videoWraper {
            height: 94px !important;
            video {
                object-fit: cover;
            }
        }
        .inChatLoading {
            min-height: 94px !important;
        }
        .firstPlaywrap {
            flex-basis: 31%;
            width: 31%;
        }
    }
}
.ckeditor_1 {
    .videoMainwrap .videoPlayer {
        width: 266px;
        .videoWraper {
            height: 148px !important;
        }
        .Loading {
            display: flex;
            flex-direction: column;
            .loadingWrap {
                width: 100%;
                height: 121px;
            }
        }
    }
    
    .videoWrapInmessage.videoMainwrap.uploadMultipalVideo .videoWraper {
        height: 121px!important;
    }
    .videoWrapInmessage.videoMainwrap.uploadMultipalVideo .videoControlsMainWrap .inChatLoading {
        height: 121px !important;
    }
    .videoControlsMainWrap.fullscreen .videoWraper {
        height: 100% !important;
        width: auto !important;
        video {
            width: auto !important;
            margin: 0 auto;
        }
    }
    .videoWrapInmessage .videoControlsWrap .videoRangeWrap .videoPreloadRange {
        top: 10px;
    }
}
.videoControlsMainWrap.fullscreen {
    .firstPlay {
        display: none;
    }
    .videoControlsWrap {
        display: block !important;
    }
}

.ckeditor_2 {
    .videoControlsMainWrap {
        .inChatLoading {
            height: 128px;
        }
    }
    .loadingWrap {
        height: 134px;
        width: 100%;
    }
    .videoWrapInmessage.videoMainwrap.moreThenthree .videoPlayer {
        flex-basis: 31% !important;
    }
    .videoWrapInmessage.videoMainwrap.uploadMultipalVideo {
        .loadingWrap {
            height: 95px;
        }
    }
    .videoControlsMainWrap.fullscreen .videoWraper {
        height: 100% !important;
        width: auto !important;
        video {
            width: auto !important;
            margin: 0 auto;
        }
    }
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent.messagesEditWrap .messages a.videoSpeedoption.dropdown-item {
    color: var(--MenuFontColor) !important;
}
.videoAttached {
    .ck.ck-editor__main {
        max-height: 300px;
        overflow-y: auto !important;
        .ck.ck-content.ck-editor__editable {
            max-height: 100%;
            overflow: visible;
        }
    }
}
.vcMobile {
    .videoWraper {    
        video {
            object-fit: cover;
        }
    }
    .fade.show.tooltip {
        display: none;
    }
    .firstPlaywrap {
        max-width: 100%;
    }
    .videoWrapInmessage {
        z-index: 4;
        &[data-video="1"] {
            .firstPlaywrap {
                height: 172px;
            }
            .videoWraper {
                height: 129px;
            }
        }
    }
    .videooptionDropOpen {
        .videoWrapInmessage {
            z-index: 6;
        }
    }
    .videoControl-copyUrl {
        display: none;
    }
    .videoWrapInmessage.multipal .firstPlaywrap {
        flex-basis: 100% !important;
        width: 100% !important;
        margin-right: 0;
    }
    .videoWrapInmessage.videoMainwrap {
        .videoPlayer {
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: 10px;
            box-shadow: 0 0 3px 0 #0003;
            .loadingWrap {
                display: none;
            }
            .videoPlayerBlock {
                display: none;
                .videoControlsMainWrap {
                    display: none;
                }
            }
            .video-footer {
                .videoFileName {
                    display: flex;
                    align-items: center;
                    .fa-sharp.fa-regular.fa-circle-play {
                        font-size: 18px;
                    }
                    .videoFileNameBlock {
                        text-wrap: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .videoClose {
                    font-size: 18px;
                }
                .iconLoader {
                    .loadingWrap {
                        display: block;
                        height: 45px;
                        width: 40px;
                        // position: absolute;
                        // top: 0;
                        // left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .videoloader {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
   
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal {
        .videoControlsMainWrap {
    
            &.fullscreen {
                display: flex;
                align-items: center;
                justify-content: center;
                .videoControlsWrap {
                    left: 0;
                }
                .videoWraper {
                    height: 100% !important;
                }
            }
        }
    }
    .ckeditor_1 .videoMainwrap .videoPlayer {
        width: 230px;
        margin-right: 0;
    }
    
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal .firstPlaywrap {
        flex-basis: 100% !important;
        width: 100% !important;
        margin-right: 0;
    }
    .firstPlay .videoControl.videoControl-playPush {
        height: 40px;
        width: 40px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 20px;
            display: block;
        }
    }
    .loadingWrap .videoloader {
        border-bottom-color: #3B4789;
    }
    .video-footer .videoFileName .fa-sharp.fa-regular.fa-circle-play {
        color: #3B4789;
    }
    .videoWrapInmessage.videoMainwrap.uploadMultipalVideo {
        flex-direction: column;
    }

    &.videooptionDropOpenBody {
        .messagesArea-footer {
            display: none;
        }
        .messages.videooptionDropOpen {
            &:has(.videoDropdown.mobile-contextmenu.show.dropdown) {
                z-index: 99;
            }
        }
    }
}
.iosmobiledevice {    
    .vcMobile {
        .videoControlsMainWrap {
            .firstPlay {
                background: transparent;
            }
            // .videoControl.videoControl-playPush {
            //     transform: translate3d(-3px,0px,0px); 
            //     height: 70px;
            //     width: 70px;
            // }
        } 
    }
}
video::-webkit-media-controls {
    display: none !important;
    opacity: 0 !important; 
  }
video::-webkit-media-controls-start-playback-button {
    display: none !important;
}
html:not(.iosmobiledevice) {    
    .vcMobile {
        .videoControlsWrap {
            bottom: 50px;
            .videoControl {
                &-expand {
                    .fa-compress {
                        font-size: 16px;
                    }
                }

                &-leftArea {
                    margin-right: 10px;
                }
                &-rightArea {
                    margin-left: 10px;
                }
            }
        }
    }
}
 
.chatActive.rightSideOnly.smallScreen,
.rightSideOnly.smallScreen,
.customerChatWindow {
    .videoWrapInmessage.videoMainwrap {
        .loadingWrap,
        .videoPlayerBlock {
            display: none;
        }
        .videoPlayer {
            flex-basis: 100% !important;
            width: 184px;
            margin-right: 0 !important;
            margin-bottom: 10px;
        }
    }
    .video-footer {
        .loadingWrap {
            display: block !important;
        }
    }
    .videoWrapInmessage.multipal .firstPlaywrap {
        flex-basis: 100% !important;
        margin-right: 0;
    }
    .videoWrapInmessage .firstPlaywrap {
        width: 100%;
    }
    .messagesArea-middle .messagesBox-block {
        max-width: 80%;
    }
    .videoWraper {
        height: 127px !important;
    }
    .fullscreen {
        .videoWraper {
            height: 100vh !important;
        }
    }
    .videoWrapInmessage .firstPlaywrap {
        height: 170px;
    }
    // .videoControl {
    //     &-timing {
    //         display: none;
    //     }
    // } 

    .ckeditor_1 {
        .videoMainwrap .videoPlayer {
            width: 100%;
            .Loading .loadingWrap {
                display: none;
            }
        }
    }
    .ckeditor_2 {
        .videoWrapInmessage.videoMainwrap.moreThenthree .videoPlayer {
            flex-basis: 100% !important;
            margin-right: 0 !important;
        }
    }
    .videoControl {
        &:not(.videoControl.videoControl-playPush) {
            padding: 3px;
        }
        &-timing {
            display: inline-block !important;
        }
    }
    
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal .videoWraper {
        height: 127px !important;
    }
    
}

.customerChatWindow {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal .firstPlaywrap {
        flex-basis: 100% !important;
        width: 100% !important;
        margin-right: 0 !important;
    }
}
.vConnectMainWrap {
    &.chatActive.rightSideOnly.smallScreen,
    &.rightSideOnly.smallScreen,
    &.customerChatWindow {
        .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal .videoWraper {
            height: 127px !important;
        }
    }
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages.message-note-block {
    .videoWrapInmessage .videoControlsWrap .videoSpeed .dropdown-menu .dropdown-item.videoSpeedoption {
        color: #fff !important;
    }
}
.vConnectMainWrap {
    .chatActive.rightSideOnly.smallScreen {
        .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal .firstPlaywrap {
            flex-basis: 100% !important;
            margin-right: 0;        
        }
    }
}
.customerChatWindow {
    .fade.show.tooltip {
        z-index: 999999;

    }
    .vConnectMainWrap .smallChatBox-middle .messagesBox .messagesBox-block {
        max-width: 79%;
    }
    .videoWrapInmessage .firstPlaywrap {
        height: 173px;
    }
    // .videoControlsMainWrap .videoWraper {
    //     video {
    //         object-fit: cover;
    //     }
    // }
    .videoWrapInmessage .videoControlsWrap .videoSpeed {
        margin: 0 3px !important;
        padding: 5px 3px !important;
    }
}
@media only screen and (max-width: 1400px) {
    .videoWrapInmessage.multipal[data-video="2"] .firstPlaywrap,
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal .firstPlaywrap {
        flex-basis: 46% !important;
        width: 46% !important;
    }
    .videoWrapInmessage.multipal .firstPlaywrap:nth-child(3) {
        margin-right: 10px;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1400px) {
    .videoControl-bottomPanel .videoControl {
        &-timing {
            display: none;
        }
    }
    .videoWrapInmessage .firstPlaywrap {
        width: 100%;
    }
 }

 /*- START: mysite -*/
 body.vagaroConnect.customerChatWindow.websitebuilder-loaded {
    .video-footer .videoFileName .loadingWrap .videoloader {
        border: 3px solid #e1e1e1;
        border-bottom: 3px solid var(--wbBtnSecondaryBgColor) !important;
    }
    .dropdown .dropdown-menu {
        background: #fff !important;
    }
    .videoSpeed {
        &.dropdown .dropdown-menu {
            background: rgba(0,0,0,.541) !important;
        }
    }
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal .firstPlaywrap {
        flex-basis: 100% !important;
        width: 100% !important;
        margin-right: 0 !important;
    }
    .ck-editor.ck-reset .ck-editor__main .ck-editor__editable,
    .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred p,
    .ck.ck-editor__main {
        color: #333!important;
    }

    .messagesArea-footer .isTypingMain {
        background-color: #f8f8f8 !important;
    }
    .isTypingWrap-loader {
        background-color: #e7e7e7 !important;
    }
 }

 .vConnectMainWrap {
    &.chatActive.rightSideOnly.smallScreen,
    .rightSideOnly.smallScreen {
        .chatarea .messagesArea-middle .messagesBox .messages .videoWrapInmessage.multipal .firstPlaywrap,
        .videoWrapInmessage.multipal[data-video="2"] .firstPlaywrap {
            flex-basis: 100% !important;
        }
        .videoWrapInmessage {
            z-index: 0;
        }
    }
 }
 /*- END: mysite -*/
 .customerChatWindow {
     .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .ck-reset.ck-editor {
        position: relative
     }
 }
/*- END: Video upload -*/


.dropdown-menu.mention-popup {
    background: var(--bkg_neutral_default);
    width: 321px; border-radius: 10px; box-shadow: 0px 2px 10px 0px #0000001A;
    overflow: hidden; 
    // position: relative !important;
    .mention-popup_topArea {
        max-height: 240px;
        overflow: auto;
    }
    &[x-placement="bottom-start"] {
        transform: translate3d(2px, -105px, 0px) !important;
    }
    .dropdown-item {
        &.active {
            background: var(--bkg_neutral_secondary) !important;
        }
    }
}
.dropdown-menu.mention-popup a { padding: 0px;}
.dropdown-menu.mention-popup .dropdown-item-text { font-size: 15px; line-height: 20px; padding:16px 16px;  color: var(--text_neutral_default); }
.mention-everyone-row {
    display: flex;align-items: center; align-content: center; padding:6px 0px;  margin-left: 16px; margin-right: 16px; color: var(--text_neutral_default);
    .addMemnerBlock {
        svg {
            width: 18px;
        }
    }
 }
.mention-popup .icon-box { width: 28px; margin-right: 8px; text-align: center; font-size: 15px; }
.m-head-text { font-size: 15px; font-weight: 500;  color: var(--text_neutral_default);}
.m-disc-text { font-size: 12px; font-weight: 400; color: var(--text_neutral_default); }
// .dropdown-menu.mention-popup .avatar-group-img-wrap { width: 29px; height: 29px;}
.dropdown-menu.mention-popup .avatar {  
    margin-top: 0 !important;
}
.mentionListName {
    overflow: hidden;
    .m-head-text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
.sent.messagesBox.messagesEditWrap {
    .mention-popup {
        .mention-popup_topArea {
            max-height: 150px;
        }
    }    
}
.vConnectMainWrap .dropdown-menu.mention-popup .avatar[data-avatar="1"] .review-profile-inner-text { font-size: 12px;}

//employee-personal-detail
.profile-detail-box {border-radius: 4px;background: var(--bkg-neutral-bkg-neutral-default); position: fixed!important;  z-index: 99; max-height:70%; overflow: auto;}
.employee-personal-detail { width: 320px; padding: 16px; display: inline-flex;flex-direction: column;align-items: flex-start;
.contact-wrap a {align-items: baseline;display: flex;font-size: 15px;line-height: 20px;margin-bottom: 8px;position: relative; color: var(--text_neutral_default);  text-decoration: none;width: 100%;}
.contact-wrap a span {padding-left: 8px;}       
.employe-location-block {align-items: start;display: flex;} 
.employe-location-block .iconWrap {margin-right: 7px;} 
.locationList .location {color: #333;color: var(--Text-Primary);font-size: 15px;font-style: normal;font-weight: 400;line-height: 23px;margin: 6px 0;}
.locationList .location:first-child {margin: 0;}
.personal-info { padding-top: 16px; padding-bottom: 24px; text-align: center; width: 100%;}
.name-wrap h3 { font-size: 18px; line-height: 24px; font-weight: 500; margin-bottom: 8px; margin-top: 4px;}
.bottom-content-wrap-new { padding-left: 16px; padding-right: 16px;}
.block-status .active-status {border-radius: 16px;color: #fff;display: inline-block;font-size: 12px;font-weight: 400;
line-height: 16px;padding: 2px 8px;text-transform: capitalize;}
.block-status .active-status.status-gray {background-color: #777 !important;color: var(--text_neutral_ondark) !important;}
.block-status .active-status.status-red {background-color: var(--bkg_alert_default) !important;color: var(--text_neutral_ondark) !important;}
.block-status .active-status.status-yellow {background: var(--bkg_warning_default); color: var(--text_neutral_ondark) !important;}
.block-status .active-status.status-green {background: var(--bkg_green_default); color: var(--text_neutral_ondark) !important;}
.more-loaction {font-size: 12px;font-style: normal;font-weight: 400;line-height: 16px; color: var(--text_neutral_secondary) ;}
.btn-message {  
    padding: 8px 16px;
    align-items: center;  
    background-clip: text;
    color: var(--text_neutral_default);
    border-radius: 3px;
    border: solid 1px var(--border_neutral_default);
    display: inline-block;
    cursor: pointer;
     font-weight: 500; font-size: 15px; line-height: 20px;     justify-content: center; margin-top: 8px;}
}
.btn-message i { margin-right: 4px;}
.vcTab .employee-personal-detail .personal-info .avatar { width: 40px; height: 40px;}
.vcMobile {
    &:not(.vcTab-portrait) {
        .mentionListMainWrap {
            left: 0 !important;
            right: 0 !important;
            z-index: 99;
        }
    }

    .mentionListMainWrap {
        // left: 33px !important;
        .mention-popup.dropdown-menu {
            transform: none !important;
        }
    }
    .mention-everyone-row {
        border-bottom: 1px solid var(--border_neutral_default);
    }
    
    .mention-popup {
                
        .dropdown-item {
            &:last-child {
                .mention-everyone-row {
                    border-bottom: none;
                }
            }
        }
        .mention-popup_topArea {
            max-height: 213px;
            .dropdown-item {
                &:last-child {
                    .mention-everyone-row {
                        border-bottom: 1px solid var(--border_neutral_default);
                    }
                }
            }
        }
        
    }
    .mobileckMaxheight {
        .mention-popup {
            .mention-popup_topArea {
                max-height: 160px;
            }
        }
    }
    
}
.vcTab-portrait {
    .mentionListMainWrap {
        z-index: 9999;
    }
}

.vcTab {
    .mention-popup {
        .mention-popup_topArea {
            max-height: 150px;
        }
    }
}

.dropdown-menu.mention-popup {
    transform: none !important;
    position: static !important;
    inset: unset !important;
}
.dropdown-menu.mention-popup[x-placement=bottom-start] {
    transform: none !important;
    position: static !important;
    inset: unset !important;
}


@media (max-width: 767px) {
.dropdown-menu.mention-popup {
    margin: 0 auto;
    // padding-top: 8px;
    // position: fixed!important;
    // max-height: 75%;
    // padding-bottom: 8px;
    // inset: auto 0 0 0!important;
}
.border-bottomsolid { border-bottom: solid 1px var(--border_neutral_default);}
// .dropdown-menu.mention-popup .avatar-group-img-wrap { width: 41px; height: 41px;}
.dropdown-menu.mention-popup .avatar-md {  width: 40px; height: 40px;}
.dropdown-menu.mention-popup .dropdown-item-text { font-size: 15px; line-height: 20px; padding:12px 16px;  color: var(--text_neutral_default); }
.mention-everyone-row {display: flex;align-items: center; align-content: center; padding:8px 0px;  margin-left: 16px; margin-right: 16px; color: var(--text_neutral_default); }
.mention-popup .icon-box { width: 40px; margin-right: 8px; text-align: center; font-size: 16px; }
.m-head-text { font-size: 16px; font-weight: 500;  color: var(--text_neutral_default);}
.m-disc-text { font-size: 14px; font-weight: 400; color: var(--text_neutral_default); }
}
.vConnectMainWrap.chatActive.rightSideOnly.smallScreen {
    .mentionListMainWrap {
        left: 33px !important;
    }
}


//Setting widget Start
.widget-wrap { background-color: var(--bkg_neutral_default); padding: 24px; overflow: auto;}
.settingfooter { background-color: var(--bkg_neutral_secondary); box-shadow: 0px 1px 0px 0px var(--border_neutral_default) inset;
    justify-content: flex-end; gap: 16px; padding: 12px 24px;}
   // button 
    .vcconnect-secondary-btn {  padding: 8px 16px;    background-color: var(--bkg_components_button_neutral_default);     border: 1px solid var(--border_neutral_strong) !important;
        color: var(--text_neutral_default);}
        .vcconnect-secondary-btn:hover, .vcconnect-secondary-btn:focus, .vcconnect-secondary-btn.active {border: 1px solid var(--border_neutral_strong);
            color: var(--text_neutral_default)!important; background-color: var(--bkg_components_button_neutral_hover)!important;}
    .vcconnect-primary-btn { padding: 8px 16px; background-color: var(--bkg_components_button_green_default);
        border-color: var(--border_green_default);
        color: var(--text_neutral_ondark);}
        .vcconnect-primary-btn:hover,  .vcconnect-primary-btn:focus {background-color: var(--bkg_components_button_green_hover);
            border-color: var(--bkg_components_button_green_hover);
            color: var(--text_neutral_ondark);}
 

/*- START: Reaction -*/
.vConnectMainWrap {
    .chatarea {
        .messagesArea-middle {
            .messagesBox {
                
                    vg-user {
                        font-weight: 700;
                        display: inline;
                    }
                    .editControl {
                        padding: 0 !important;
                        border: none !important;

                        i {
                            color: var(--icon_eutral_default);

                            &.fa-face-smile-plus {
                                font-size: 18px;
                            }
                            &.fa-pen,
                            &.fa-trash {
                                font-size: 15px;
                            }
                            &.fa-ellipsis {
                                font-size: 16px;
                            }
                        }

                        &:first-child {
                            padding-left: 0 !important;
                            margin-left: 0 !important;
                        }

                        &:last-child {
                            padding-right: 0 !important;
                            margin-right: 0 !important;
                        }

                        &Wrap {
                            top: -35px !important;
                            border-radius: 8px !important;
                            background: var(--bkg_neutral_default) !important;
                            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
                            padding: 8px 10px;
                            min-width: 198px !important;
                            display: flex !important;
                            align-items: center;
                            max-width: max-content;
                        }
                        &-primary {
                            display: flex;
                            align-items: center;
                            .editControl {
                                // margin: 0 2px;
                                width: 30px;
                                height: 24px;
                                text-align: center;
                                // padding: 2px 4px !important;
                                display: flex !important;
                                align-items: center;
                                justify-content: center;
                                img {
                                    width: 24px;
                                }
                                &:hover,
                                &.selected {
                                    background: var(--bkg_neutral_tiertiary);
                                    border-radius: 5px;
                                }

                                &.reactEmojiWrap {
                                    .dropdown-toggle {
                                        border: none;
                                        margin: 0 auto;
                                        color: var(--text_neutral_secondary) !important;
                                    }
                                }
                                &.reactEmojiWrap {
                                    padding: 2px 4px !important;
                                    color: var(--text_neutral_default)!important;
                                    i {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                        &-secondary {
                            display: flex;
                            align-items: center;
                            border-left: 1px solid var(--border_neutral_default);
                            // padding-left: 8px;
                            // margin-left: 8px;
                            .editControl {
                                margin: 0 1px;
                                width: auto;
                                text-align: center;
                                padding: 2px 6px !important;
                                &:hover {
                                    background: var(--bkg_neutral_tiertiary);
                                    border-radius: 5px;                                    
                                }
                            }

                            &_text {
                                display: none;
                            }

                            .editControl.dropdown {
                                position: static;
                                .dropdown-toggle {
                                    padding: 0;
                                    display: block;
                                    border: none !important;
        
                                    &:after {
                                        display: none;
                                    }
        
                                    &:active,
                                    &:focus,
                                    &:hover,
                                    &.show {
                                        border: none;
                                        padding: 0;
                                        box-shadow: none;
                                        background-color: transparent;
                                        border-color: transparent;
                                        color: transparent;
                                        height: auto;
                                        outline: none;
                                    }
                                }
                                .dropdown-menu {
                                    transform: translate3d(0px, 45px, 0px) !important;
                                    .dropdown-item {
                                        color: var(--text_neutral_default) !important;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 20px;

                                        i {
                                            color: var(--icon_neutral_default);
                                            font-size: 18px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;
                                            margin-right: 8px;
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                
            }
        }
    }
}
.ck-editor {
    vg-user {
        color: #2A90D6;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}
.reactedWrap {
    // position: absolute;
    // left: 7px;
    // bottom: -24px;
    z-index: 4;
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    margin-top: -7px;
    .reactionBlock {
        height: 28px;
        // width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        

        .reaction {            
            border: 1px solid var(--border_neutral_default);
            background: var(--bkg_neutral_default);            
            margin-right: 4px;
            cursor: pointer;
            border-radius: 8px;
            img {
                min-width: 15px;
            }
            &.active {
                border: 1px solid var(--MenuBackColor);
            }
            &-inner {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                padding: 4px 4px;
            }
            &.plus {
                height: 100%;
                padding: 4px 4px;
            }
        }

        .reaction-count {
            color: var(--text_neutral_default);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-left: 4px;
        }

        

    }

}
.messagesEditWrap {
    .reactedWrap {
        display: none;
    }
}
.sent.messagesBox {
    .reactionMessageMainBlock {
        .reactedWrap {
            margin-left: 7px;
        }
    }
}
.vConnectMainWrap .chatarea .messagesArea-middle {
    .messagesBox {
        &.recived .messages {
            position: relative;
            .reactedWrap {
                left: auto;
                right: 7px;
                flex-direction: row-reverse;
                flex-wrap: nowrap;
            }
        }
    }
}
.reactionPopHover {
    border: 1px solid var(--border_neutral_default);
    background: var(--bkg_neutral_default);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    top: -8px !important;
    z-index: 9999;
    width: 200px;
    .reactionTitleForMobile {
        display: none;
    }
    &.bs-popover-top {
        top: auto!important;
        margin-bottom: -8px;
    }

    .avatar { 
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-color: #CDCDCD !important;
        border-radius: 10em;
        // position: absolute;
        top: 0;
        left: 0;
        width: 2.8em;
        height: 2.8em;
        position: relative;
    
        img {
            width: 100%;
            // height: 100%;
        }
    
        .review-profile-inner-text {
            // color: var(--text_neutral_default)!important;
            color: #333;
        }
    
        &-sm {
            height: 28px;
            width: 28px;
            flex: 0 0 28px;
            border-radius: 15px;
    
            .avatar-group-img-wrap-text {
                font-size: 8px;
                line-height: 12px;
            }
    
            .avatar-group-img-wrap {
                height: 28px;
                width: 28px;
    
                .review-profile-inner-text {
                    font-size: 8px;
                }
            }
    
    
            &[data-avatar="1"] .avatar-group-img-wrap {
                height: 29px;
                width: 29px;
                top: -1px;
                left: -1px;
    
                // img {
                //     height: 30px;
                //     width: 30px;
                // }
            }
        }
    
        &[data-avatar="1"] {
            .review-profile-inner-text {
                font-size: 15px;
            }
        }
    
    
    }
    .popover {
        &-arrow {
            display: none;
        }
        &-header {
            border-radius: 3px;            
            color: var(--text_neutral_secondary);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            background: transparent !important;
            // padding: 8px 0;
            border-bottom: none;
            padding: 12px 16px 6px 16px;
            &::before {
                display: none;
            }
        }
        &-body {
            padding: 4px 16px;
            max-height: 150px;
            overflow: auto;
            .reactedPersonBlock {
                .reactedPerson {
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    padding: 4px 0;
                    &-name {
                        color: var(--text_neutral_default);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        margin-bottom: 0;
                        margin-left: 8px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    &-emoji {
                        margin-left: 5px;
                        margin-top: 3px;
                    }
                    .avatar {
                        .review-profile-inner-text {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

}
.customerChatWindow {
    .reactionPopHover {
        &[data-popper-placement="bottom-end"] {
            right: -50px !important;
        }
    }
}
.pophoverOverlay {
    display: none;
}
.vcMobile:not(.vcTab-portrait) {
    .vConnectMainWrap {
        .chatarea {
            .messagesArea-middle {
                .messagesBox {
                    
                        .editControl {
                            &Wrap {
                                flex-direction: column;                            
                            }
                            &-primary,
                            &-secondary {
                                width: 100%;
                                background: var(--bkg_neutral_default);
                            }
                            &-primary {
                                justify-content: space-between;
                                padding: 12px 16px;
                                border-radius: 10px 10px 0 0;
                                border-bottom: 1px solid var(--border_neutral_default);
                                margin-bottom: 1px;
                                &:only-child {
                                    border-radius: 10px;
                                    border-bottom: none;
                                }
                            }
                            &-secondary {
                                flex-direction: column;
                                align-items: start;
                                border-left: none;
                                padding-left: 0;
                                margin-left: 0;
                                border-radius: 0 0 10px 10px;
                                &_text {
                                    display: block;
                                }

                                .editControl.show.dropdown {
                                    padding-left: 0 !important;
                                    width: 100%;
                                    .dropdown-toggle.show {
                                        display: none !important;
                                    }
                                    .dropdown-menu.show {
                                        position: static !important;
                                        transform: translate3d(0,0px,0) !important;
                                        box-shadow: none;
                                    }
                                }
                                .dropdown-item {
                                    padding: 12px 16px;
                                    font-weight: 500 !important;
                                    border-bottom: 1px solid var(--border_neutral_default);
                                    line-height: 29px !important;
                                    i {
                                        color: var(--icon_neutral_default);
                                        font-size: 18px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }
                                }
                                .editMobile,
                                .deleteMobile {
                                    padding: 12px 16px !important;
                                    border-bottom: 1px solid var(--border_neutral_default) !important;
                                    width: 100%;
                                    margin: 0;
                                    i {
                                        color: var(--icon_neutral_default);
                                        font-size: 18px !important;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }
                                    .editControl-secondary_text {
                                        font-weight: 500 !important;
                                        font-size: 16px;
                                    }
                                    
                                }
                                .editControl {
                                    &:last-child {
                                        border-bottom: none !important;
                                    }
                                }
                            }
                        }
                    
                    .reactedWrap {
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
    }

    .reactionPopHover {
        bottom: 0 !important;
        top: auto !important;
        z-index: 99;
        transform: none !important;
        left: 0 !important;
        width: 100%;
        min-width: 100%;        
        border: none;
        height: 100%;

        background: rgba(24, 19, 43, 0.21);
        .reactionContentWrap {            
            min-width: 100%;
            max-width: 100%;
            width: 100%;
            padding: 0;
            border-radius: 20px 20px 0px 0px;
            border: 16px solid #F8F8F8;
            background-color: #F8F8F8 !important;
            position: fixed;
            bottom: 0;
        }
        
        .popover {
            &-header {
                color: var(--color_neutral_Primary);
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                padding: 12px 0;
                // border-bottom: 1px solid var(--border_neutral_default);
                background-color: #F8F8F8 !important;
                
            }
            &-body {
                padding-left: 0;
                padding-right: 0;
                .reactedPersonBlock {
                    // border-radius: 10px;
                    // overflow: hidden;
                    background: transparent !important;
                }
                .reactedPerson {
                    margin: 0;
                    padding: 12px 16px;
                    border-bottom: 1px solid var(--border_neutral_default);
                    background: #fff;
                    // border-radius: 10px;

                    &-img {
                        height: 30px;
                        width: 30px;
                        flex: 0 0 30px;
                        &[data-avatar="1"] .avatar-group-img-wrap {
                            height: 31px;
                            width: 31px;
                            flex: 0 0 31px;
                            .avatar-group-member {
                                object-fit: cover;
                                overflow-clip-margin: unset;
                                vertical-align: top;
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                    &-name {
                        margin-left: 16px;
                        color: var(--color_neutral_Primary);
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                    }

                    &:only-child {
                        border-radius: 10px;
                        border-bottom: none;
                    }

                    &:nth-child(1):not(:only-child) {
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;                        
                    }
                    &:nth-last-child(1):not(:only-child) {
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                        border-bottom: none;
                    }

                }
            }
        }

        // &::after {
        //     content: '';
        //     background: #f00;
        //     opacity: 0.5;
        //     position: fixed;
        //     height: 100vh;
        //     width: 100vw;
        //     left: 0;
        //     top: 0;
        // }
    }

    .reactionTitleForMobile {
        display: inline-block;
        .reaction {
            margin-right: 8px;
            img {
                width: 20px;
            }
            &-count {
                margin-right: 5px;
            }
        }

    }

    .reactedWrap .reactionBlock .reaction {
        border-radius: 20px;
    }

    // &:not(.vcTab-portrait) {
    //     .mention-popup {
    //         max-height: 60%;
    //     }
    // }
}
.reactionMessageMainBlock {
    display: flex;
}
.sent.messagesBox {
    .reactionMessageMainBlock {
        justify-content: end;
    }
}
.messagesEditWrap .reactionMessageWrap {
    width: 100%;
}
.reactionMessageWrap {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    
    .reactedWrap {
        flex: 1 1 auto;
        min-width: 100%;
        max-width: 100% !important;
    }

    &:has(.reactedWrap) {
        .messages {
            min-width: 100%;
        }
    }
}
.recived.messagesBox {
    .reactedWrap {
        display: flex;
        justify-content: end;
    }
}


.emoji-banner-popup { width: 100%; max-width: 500px;   
    .emoji-banner-wrap { width: 100%;}
    &.modal-mb-half .modal-header { padding-top: 0px!important;}
     .modal-header button.btn-close { display: block; right: 23px; top: 23px;   font-weight: 900; }
     .modal-header button.btn-close::after {color: var(--icon_neutral_secondary) !important;}
     .modal-header button.btn-close:hover::after {color: var(--icon_neutral_default) !important;}
     .emoji-banner-wrap .emoji-img-box { text-align: center;}
    .emoji-banner-wrap .emoji-img-box img { max-width: 100%; margin-bottom: 16px; max-height: 190px; margin-top: 0; }
    .emoji-banner-heading { color: var(--text_neutral_default); font-size: 24px; font-style: normal; font-weight: 600;
    line-height:28px;  letter-spacing: 0px;}
    .emoji-banner-desc { color: var(--text_neutral_default);font-size: 16px; font-style: normal; font-weight: 400;
    line-height: 20px; }
    } 



.vcMobile,
.vcTab {
    //emoji popup design
    .emoji-banner-popup { width: 100%; max-width: 500px;   
        .emoji-banner-wrap { width: 100%;}
        &.modal-mb-half .modal-header { padding-top: 0px!important;}
        &.modal-mb-half .modal-body { padding-bottom: 8px;}
         .modal-header button.btn-close { display: block; right: 23px; top: 23px;   font-weight: 900; }
         .modal-header button.btn-close::after {color: #333!important;}
         .emoji-banner-wrap .emoji-img-box { text-align: center;}
        .emoji-banner-wrap .emoji-img-box img { max-width: 100%; margin-bottom: 16px; max-height: 200px; margin-top: 0px;  }
        .emoji-banner-heading { color: var(--text_neutral_default, #333); font-size: 24px; font-style: normal; font-weight: 600;
        line-height:28px;  letter-spacing: 0px;}
        .modal-footer { border: 0;}
        .emoji-banner-desc { color: var(--text_neutral_default, #333);font-size: 16px; font-style: normal; font-weight: 400;
        line-height: 20px; }
        } 
    // .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox {
    //     &:has(.editControlmainWrap .editControlWrap) {
    //         z-index: 99;
    //     }
    // }
    .reactedWrap .reactionBlock .reaction {
        border-radius: 20px;
        position: relative;        
        border: 1px solid var(--border_neutral_default);
        &-inner {
            background: var(--bkg_neutral_default);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            padding: 4px 6px;
        }
        &.active {
            padding: 1px;
            border: none;            
            background: linear-gradient(180deg, #0A6A9F 0%, #533C89 100%);
        }
    }
}


@media only screen and (min-width:768px) and (max-width:1023px) and (orientation:portrait) {
    .vcTab-portrait {
    .emoji-banner-popup  { 
    .emoji-banner-wrap img{ max-height: 200px; margin-top: 0px; max-width: auto; margin-left: auto; margin-right: auto; } 
        }
    }
    }
.websitebuilder-loaded {
    .chatarea .messagesArea-middle .messagesBox .editControlWrap {
        background: #fff !important;
    }
}
.customerChatWindow {
    .reactedWrap .reactionBlock .reaction.active {
        border-color: var(--border_theme_flamingo_default);
    }
}
.vcTab {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControlmainWrap {
        z-index: 3;
    }
    .reactionMessageWrap .reactedWrap {
        position: relative;
        z-index: 5;
    }
//emoji poup design 
    .emoji-banner-popup .modal-content {border-bottom-left-radius: 0; border-bottom-right-radius: 0;}
    .emoji-banner-popup.modal-dialog-centered { margin: 0 auto; align-items: flex-end; min-height: 100vh;}
    .emoji-banner-popup.modal-dialog { -webkit-transform: translate(0, 100%) !important; 
        -ms-transform: translate(0, 100%) !important;
        -o-transform: translate(0, 100%) !important;
        transform: translate(0, 100%) !important;}
        .emoji-banner-popup.modal-dialog .modal-footer .vcgradientbtn { width: 100%; margin: 0; }
        .show .emoji-banner-popup.modal-dialog {  opacity: 1; -webkit-transform: translate(0, 0%) !important;
            -ms-transform: translate(0, 0%) !important;
            -o-transform: translate(0, 0%) !important;
            transform: translate(0, 0%) !important;}
}
/*- START: right side details in pop hover -*/
.vcMobile .vConnectMainWrap.rightSideOnly.chatActive.detailsActive .rightSlide .rightside {
    .messageBtnwrap {
        margin: 16px 16px 0 16px;
        .btn.btn-message {
            padding: 8px 16px;
        }
    }
}
.employeeDetailPophover {
    border: none;
    border-radius: 4px;
    overflow: hidden;
    width: 320px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    
    &Wrap {
        z-index: 9999;
    }
    .popover-arrow {
        display: none;
    }
    .rightside {
        max-width: 320px;        
    
        .dropdown-toggle {
            white-space: normal;
        }
    
        .businessLocation {
            &Wrap {
            }
    
            &Block {
                display: flex;
                align-items: start;
    
                .iconWrap {
                    margin-right: 7px;
                    .fa-store {
                        color: var(--text_neutral_default) !important;
                        font-size: 14px;
                        vertical-align: middle;
                    }
                }
    
                .locationList {
                    margin: 0;
    
                    .location {
                        color: var(--Text-Primary, #333);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        // line-height: 23px;
                        margin-bottom: 8px;
    
                        &:first-child {
                            margin-top: 0;
                        }
    
                        &:nth-child(n + 9) {
                            margin-bottom: 5px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
    
                    .locationToogle {
                        color: var(--text-neutral-text-neutral-secondary, #6D6E6F);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    
    .right-mobileHeaderDetailBlock {
        display: none;
    }
    
    .rightside-wrapper {
        background-color: white;
        border-left: none;
        overflow-y: hidden;
        min-height: 260px;
    
        .right-mobileHeader {
            &DetailBlock {
                align-items: center;
                justify-content: start;
                border-bottom: 1px solid var(--border_neutral_default);
            }
    
            &-back {
                padding: 15px;
    
                .fa-angle-left {
                    // font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
    
            &-detail {
                width: calc(100% - 45px);
                color: var(--text-neutral-text-neutral-default);
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
    
    
    
        &.client-details {
             .services-list-wrap {
                margin-bottom: 28px;
             }
    
            .personal-info {
                padding: 16px;
            }
    
            .bottom-content-wrap {
                padding-top: 0;
                margin-top: 10px;
                // max-height: calc(100vh - 284px);
            }
        }
    
        &.employee-detail {
            .personal-info {
                padding: 16px;
                padding-bottom: 2px;
                padding-top: 32px;
            }
    
            .bottom-content-wrap {
                padding-top: 0;
            }
        }
    
        .bottom-content-wrap {
            overflow-y: auto;
            padding: 16px 32px;
            max-height:200px;
            /*height: calc(100vh - 283px);*/
            /*height: calc(100vh - 215px);*/
        }
        .messageBtnwrap {
            margin: 0 16px 24px 16px;
        }
    
        .btn-message {
           margin: 0 auto;
           border-radius: 3px;
           border: 1px solid #CDCDCD;
           background: #FFF;
           padding: 8px 16px;
           margin-top: 8px;
           font-weight: 500;
        }
        .services-list-wrap {
             margin-bottom: 0px;
    
            .dropdown {
                .dropdown-toggle {
                    &::after {
                        display: none;
                    }
                }
            }
    
            &:last-child {
                margin-bottom: 0;
            }
    
            &.upcoming-appt-wrap {
                .appointment-detail {
                    display: inline-block;
                    padding: 0;
                    width: 100%;
                    // &:hover {
                    //     background: #F8F8F8;
                    // }
                    .dropdown-toggle {
                        padding: 0;
                    }
    
                    .service-wrap {
                        background-color: #0EB9A4;
                        padding: 13px 4px 22px;
                        border-left: 8px solid;
                        border-radius: 5px;
                        position: relative;
                        width: 100%;
    
                        h3,
                        p {
                            color: #333;
                        }
    
                        &::before,
                        &::after {
                            content: '';
                            background-color: #7AA6BA;
                            position: absolute;
                            width: 100%;
                            left: 0px;
                            display: none;
                        }
    
                        &::before {
                            height: 10px;
                            top: 0;
                            border-top-right-radius: 5px;
                        }
    
                        &::after {
                            height: 20px;
                            bottom: 0;
                            border-bottom-right-radius: 5px;
                        }
    
                        h3 {
                            font-weight: 600;
                            font-size: 15px;
                        }
    
                        p {
                            margin-bottom: 0;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 16px;
                        }
    
                        &.class-wrap {
                            background-color: #E6499A;
                            padding: 5px 4px 6px;
                            color: white;
                            margin-bottom: 6px;
    
                            &::before,
                            &::after {
                                display: none;
                            }
    
                            .bottom-icons {
                                color: black;
    
                                .count {
                                    background-color: white;
                                    border-radius: 50%;
                                    padding: 3px;
                                    height: 25px;
                                    width: 25px;
                                    display: flex;
                                    justify-content: center;
                                    margin-top: 0;
    
                                    span {
                                        border: 1px solid #CDCDCD;
                                        background-color: transparent !important;
                                        color: black;
                                        border-radius: 50%;
                                        padding: 2px 6px;
                                        margin-top: 0;
                                    }
                                }
    
                                span {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
    
                    .bottom-icons {
                        text-align: right;
                        display: flex;
                        justify-content: flex-end;
                        // align-items: flex-start;
                        align-items: center;
    
                        &Wrap {
                            display: flex;
                            align-items: end;
                            justify-content: end;
                        }
    
                        .serviceIcon {
                            margin: 0 2px;
                            height: 18px;
                            width: 18px;
                            display: inline-block;
                            font-size: 12px;
                            text-align: center;
                            line-height: 18px;
                            padding: 0;
    
                            &.name-icon {
                                padding: 0;
                            }
    
                            &:last-child {
                                margin-right: 4px;
                            }
    
                            &-padding {
                                &.name-icon {
                                    padding: 0 2px;
                                    min-width: 22px;
                                }
                            }
    
                            i {
                                font-size: 17px;
                                vertical-align: bottom;
                            }
    
                            &.name-icon {
                                width: auto;
                            }
    
                            &.singleName {
                                width: 18px;
                            }
    
                            &.vIcon {
                                width: auto;
                            }
    
                            &.dollarSign {
                                line-height: 14px;
    
                                .fa-dollar-sign {
                                    font-size: 11px;
                                }
                            }
                        }
    
                        &::after {
                            content: '';
                            background-color: black;
                            width: 60px;
                            position: absolute;
                            bottom: 4px;
                            height: 2px;
                            z-index: 9;
                            border-radius: 1px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
    
                        span {
                            margin-right: 4px;
                            /*&:last-child,*/
                            color: #333;
    
                            &.name-icon {
                                // margin-right: 0;
                                background-color: #333333;
                                color: white;
                                border-radius: 3px;
                                padding: 1px 5px;
                                font-size: 12px;
                                font-weight: 600;
                                // line-height: 16px;
    
                                &.radius-style {
                                    border-radius: 50%;
                                }
    
                                i.fas {
                                    color: white !important;
                                }
                            }
                        }
                    }
                }
            }
    
            &.purchases-wrap {
                .appointment-detail {
                    padding: 8px;
                    position: relative;
    
                    &:hover {
                        background: #F8F8F8;
                    }
    
                    h3 {
                        font-size: 15px;
                    }
    
                    .dropdown {
                        position: absolute;
                        right: 0;
                        display: none;
    
                        &-toggle {
                            cursor: pointer;
                            margin: 1px 8px 0 1px;
                        }
                    }
    
                    &:hover {
                        .dropdown {
                            display: block;
                        }
                    }
                }
            }
    
            &.pastAppointmentsWrap {
                .appointment-detail {
                    position: relative;
    
                    &:hover {
                        background: #F8F8F8;
                    }
    
                    .dropdown {
                        position: absolute;
                        right: 0;
                        display: none;
    
                        &-toggle {
                            cursor: pointer;
                            margin: 1px 8px 0 1px;
                        }
                    }
    
                    &:hover {
                        .dropdown {
                            display: block;
                        }
                    }
                }
            }
        }
    
        .profile-wrapper {
            .profile-image {
                .avatar {
                    margin-bottom: 4px !important;
                    cursor: pointer;
                }
            }
    
            .name-wrap {
                h3 {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 7px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                    cursor: pointer;
                    color: var(--text_neutral_default) !important;
                }
            }
    
            .membership {
                &-wrap {
                    margin: 0 auto;
                    border-radius: 3px;
                    background-color: var(--bkg-neutral-bkg-neutral-strong, #333);
                    max-width: 100%;
                    display: inline-block;
                    padding: 2px 8px;
                    margin-bottom: 8px;
                }
    
                &-nameWrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    i {
                        color: var(--icon-neutral-icon-neutral-ondark, #FFF);
                        font-size: 14px;
                        font-weight: 400;
                        margin-right: 5px;
                    }
                }
    
                &-name {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                    color: var(--text-neutral-text-neutral-inverse, #FFF);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin: 0;
                }
            }
            // .membership-name {
            //     background-color: #333333;
            //     padding: 2px 8px;
            //     border-radius: 3px;
            //     color: white;
            //     display: inline-flex;
            //     align-items: center;
            //     margin-bottom: 8px;
            //     svg {
            //         margin-right: 5px;
            //         // margin-top: 2px;
            //         flex: 0 0 14px;
            //     }
            //     h5 {
            //         font-size: 12px;
            //         font-style: normal;
            //         font-weight: 400;
            //         line-height: 16px;
            //         text-overflow: ellipsis;
            //         overflow: hidden;
            //         white-space: nowrap;
            //         width: 115px;
            //     }
            // }
        }
    
        .contact-wrap {
            a {
                font-size: 15px;
                line-height: 20px;
                text-decoration: none;
                display: flex;
                align-items: baseline;
                word-break: break-all;
                width: 100%;
                margin-bottom: 8px;
                position: relative;
                color: var(--text_neutral_default)!important;
                // height: 20px;
    
                // &:last-child {
                //     margin-bottom: 0;
                // }
    
                span {
                    padding-left: 8px;
                }
    
                i,
                svg {
                    /*margin-right: 7px;*/
                    height: 16px;
                    width: 16px;
                    // position: absolute;
                    // left: 4px;
                    // top: 3px;
                }
    
                .contact-icon {
                }
                // &.contact-wrap-emailWrap {
                //     margin-bottom: 0;
                // }
            }
    
            .norights {
                pointer-events: none;
            }
    
            &-emailText {
                // var(--text_blue_default)!important
                //color: #2a90d6;
                &:hover {
                    text-decoration: underline;
                }
            }
    
            @media screen and (min-width: 768px) {
                &-callWrap {
                    //pointer-events: none;
                }
            }
        }
    
        .block-status {
            .active-status {
                padding: 2px 8px;
                border-radius: 16px;
                font-size: 12px;
                line-height: 16px;
                color: white;
                display: inline-block;
                text-transform: capitalize;
                font-weight: 400;
    
                &.status {
                    &-red {
                        background-color: #D83F34;
                    }
    
                    &-yellow {
                        background-color: #FF9F08;
                    }
    
                    &-green {
                        background-color: var(--bkg_green_default);
                    }
    
                    &-gray {
                        background-color: #777777;
                    }
                }
            }
        }
    
        .appointment-detail {
            padding: 12px 8px;
            display: flex;
            align-items: flex-start;
            //gap: 8px;
            position: relative;
            cursor: pointer;
    
            &:hover {
                background: #F8F8F8;
            }
    
            &:has(.service-wrap.serviceCard) {
                &:hover {
                    background: transparent;
                }
            }
    
    
            .service-icon {
                padding: 8px;
                //gap: 8px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 32px;
                flex: 0 0 32px;
                margin-right: 8px;
    
                &.icon {
                    display: flex;
    
                    &-green {
                        background-color: #A4E8B9;
                    }
    
                    &-greenIou {
                        background-color: #68C4A0;
                    }
    
                    &-blue {
                        background-color: #96C8EB;
                    }
    
                    &-purple {
                        background-color: #A391C5;
                    }
    
                    &-orange {
                        background-color: #F69681;
                    }
    
                    &-light-purple {
                        background-color: #C3BFDF;
                    }
    
                    &-pink {
                        background-color: #EFBBD6;
                    }
    
                    &-yellow {
                        background-color: #CBDD4E;
                    }
                }
    
                i {
                    font-size: 15px;
                }
            }
    
            .service-wrap {
                max-width: 83%;
                width: 78%;
                cursor: pointer;
    
                &.serviceCard {
                    max-width: 100%;
                    width: 100%;
                    min-height: 90px;
    
                    .serviceProviderName {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
    
                    .addOn {
                        i {
                            margin: 0 3px;
                        }
                    }
    
                    &.status {
                        &-Accepted {
                            border-color: var(--bkg_status_accepted);
                            background: var(--bkg_status_accepted);
                        }
    
                        &-Show {
                            border-color: var(--bkg_status_show);
                            background: var(--bkg_status_show);
                        }
    
                        &-NoShow {
                            border-color: var(--bkg_status_noshow);
                            background: var(--bkg_status_noshow);
                        }
    
                        &-ServiceInProgress {
                            border-color: var(--bkg_status_inprogress);
                            background: var(--bkg_status_inprogress);
                        }
    
                        &-ServiceComplete {
                            border-color: var(--bkg_status_complete);
                            background: var(--bkg_status_complete);
                        }
    
                        &-AwaitingConfirmation {
                            border-color: var(--bkg_status_awaiting);
                            background: var(--bkg_status_awaiting);
                        }
    
                        &-Confirmed {
                            border-color: var(--bkg_status_confirmed);
                            background: var(--bkg_status_confirmed);
                        }
    
                        &-ReadytoStart {
                            border-color: var(--bkg_status_ready);
                            background: var(--bkg_status_ready);
                        }
    
                        &-NeedAcceptance {
                            border-color: var(--bkg_status_requested);
                            background: var(--bkg_status_requested);
                        }
    
                        &-Deleted {
                        }
    
                        &-Cancel {
                        }
    
                        &-Denied {
                        }
    
                        &-PersonalOff {
                        }
                    }
    
                    &.cleanupTime {
                        &::before {
                            display: none;
                        }
    
                        &::after {
                            display: block;
                            background-color: rgba(0,0,0,0.15);
                            left: -7px;
                            border-bottom-left-radius: 5px;
                            right: auto;
                            width: calc(100% + 7px);
                        }
                    }
                }
    
                .dropdown-toggle {
                    padding: 0;
                }
    
                h3 {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 0;
                    // max-width: 90%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 400;
                }
    
                .date-wrap {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
    
            .date {
                padding: 8px 0px 4px;
                font-size: 12px;
                line-height: 16px;
            }
        }
    
        .accordion-body {
            padding: 0;
        }
    
        .accordion-item {
            background-color: transparent;
            border: none;
    
            .accordion-header {
                position: relative;
    
                .accordion-button {
                    padding: 8px 0;
                    background-color: transparent;
                    color: #333333;
                    box-shadow: inset 0 calc(-1 * 1px) 1px #cdcdcd;
    
                    span {
                        text-transform: uppercase;
                        font-size: 12px;
                        // font-variant: all-small-caps;
                        font-weight: 500;
                        line-height: 16px;
                    }
    
                    &.collapsed {
                        &::after {
                            content: '\f0da';
                        }
                    }
    
                    &::after {
                        content: '\f0d7';
                        position: absolute;
                        top: 50%;
                        right: -2px;
                        transform: translateY(-50%);
                        width: 16px;
                        height: 16px;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 900;
                        font-size: 16px;
                        background-image: none;
                    }
                }
            }
        }
    
        .carousel-control {
    
            &-prev,
            &-next {
                width: auto;
                opacity: 1;
            }
    
            &-prev {
                left: -5px;
            }
    
            &-next {
                right: 5px;
            }
    
            &-prev-icon,
            &-next-icon {
                background-image: none;
                width: auto;
                height: auto;
    
                &::before {
                    position: absolute;
                    top: 50%;
                    color: #6D6E6F;
                    transform: translateY(-50%);
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 900;
                }
            }
    
            &-prev-icon {
    
                &::before {
                    content: '\f053';
                }
            }
    
            &-next-icon {
    
                &::before {
                    content: '\f054';
                }
            }
        }
    
        .popup-note-wrap {
            width: 100%;
    
            .note {
                background-color: #FBECEB;
                border: 1px solid #D83F34;
                border-radius: 8px;
                padding: 8px;
                margin: 0 12px;
    
                p {
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 16px;
                    word-break: break-word;
                }
            }
        }
    
        .member-list-wrapper {
            .personal-info {
                padding: 16px 16px 0;
            }
    
            .bottom-content-wrap {
                padding-top: 0;
                height: calc(100vh - 162px);
            }
    
            h3 {
                margin-bottom: 0 !important;
            }
    
            .right-icon {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
    
            #groupNameInput {
                border: 1px solid;
                padding: 8px;
                border-radius: 3px;
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
                width: 100%;
                // background: ;
    
                & + .right-icon {
                    margin-right: 1px;
                }
            }
    
            .name-wrap {
                display: flex;
                margin-bottom: 24px;
                gap: 12px;
                justify-content: center;
    
                h3 {
                    font-size: 20px;
                }
    
                .right-icon {
                    margin-left: 9px;
                    opacity: 0.8;
    
                    &:hover {
                        opacity: 1;
                    }
                }
    
                .groupNameWrap {
                    max-width: 100%;
                }
            }
    
            .member-header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
    
                h3 {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }
    
                .right-icon {
                    margin-right: 4px;
                    opacity: 0.8;
    
                    &:hover {
                        opacity: 1;
                    }
                }
            }
    
            .profile-image {
                margin-bottom: 8px;
    
                .avatar-group {
                    // left: 50%;
                    // transform: translateX(-50%);
                    margin: 0 auto;
                }
            }
    
            .chatList {
    
                &-person {
                    padding: 4px 0;
                    border-bottom: none;
                    margin-bottom: 8px;
    
                    .avatar {
                        height: 28px;
                        width: 28px;
                        flex: 0 0 28px;
                        border: 1px solid var(--Border-Outline, #CDCDCD) !important;
                        margin-right: 8px;
    
                        .review-profile-inner-text {
                            font-size: 12px;
                            height: 26px;
                            width: 26px;
                            font-weight: 500;
                        }
                    }
    
                    &_name {
                        font-weight: 400;
                        line-height: 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 90%;

                    }
    
                    &_number {
                        text-align: left !important;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                    }
    
                    &_right {
                        display: inline-block;
                        text-align: left;
                        width: 85%;
                    }
    
                    &_dotMenu {
                        position: absolute;
                        right: 4px;
                        top: 5px;
                        text-decoration: none;
                        color: var(--icon-neutral-icon-neutral-default, #333);
                        // display: none;
    
                        .dropdown-toggle {
                            padding: 4px 5px 0 5px;
                            display: block;
                            border: none;
    
                            .fa-solid.fa-ellipsis-vertical {
                                color: var(--text_neutral_default);
                            }
    
                            &:after {
                                display: none;
                            }
    
                            &:active,
                            &:focus,
                            &:hover,
                            &.show {
                                border: none;
                                padding: 4px 5px 0 5px;
                                box-shadow: none;
                                background-color: transparent;
                                border-color: transparent;
                                color: transparent;
                                height: auto;
                            }
                        }
    
                        .dropdown {
                            &-menu {
                                border-radius: 3px;
                                border: 1px solid var(--border-neutral-border-neutral-default, #E7E7E7);
                                background: var(--bkg-neutral-bkg-neutral-default, #FFF);
                                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
                                padding: 0;
                            }
    
                            &-item {
                                padding: 12px;
    
                                &:hover {
                                    background: #F8F8F8;
                                }
                            }
                        }
                    }
                    // &_dotMenu {
                    //     .btn {
                    //         border: none;
                    //         &.show {
                    //             display: flex !important;
                    //         }
                    //     }
                    //     .dropdown-toggle {
                    //         padding: 0;
                    //         display: block;
                    //         &:after {
                    //             display: none;
                    //         }
                    //         &:active,
                    //         &:focus,
                    //         &:hover,
                    //         &.show {
                    //             border: none;
                    //             padding: 0;
                    //             box-shadow: none;
                    //             background-color: transparent;
                    //             border-color: transparent;
                    //             color: transparent;
                    //             height: auto;
                    //         }
                    //     }
                    // }
                }
            }
            /*Edit Group detail*/
            &.edit-group {
                .name-wrap {
                    gap: 8px;
                    margin-bottom: 25px;
                }
    
                .bottom-content-wrap {
                    .members-list-wrap {
                        padding: 0 16px;
    
                        .member-header {
                            .right-icon {
                                margin-right: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
    .avatar {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-color: #CDCDCD !important;
        border-radius: 10em;
        // position: absolute;
        top: 0;
        left: 0;
        width: 2.8em;
        height: 2.8em;
        position: relative;

        img {
            width: 100%;
            // height: 100%;
        }

        .review-profile-inner-text {
            // color: var(--text_neutral_default)!important;
            color: #333;
        }

        &-sm {
            height: 30px;
            width: 30px;
            flex: 0 0 30px;
            border-radius: 15px;

            .avatar-group-img-wrap-text {
                font-size: 8px;
                line-height: 12px;
            }

            .avatar-group-img-wrap {
                height: 30px;
                width: 30px;

                .review-profile-inner-text {
                    font-size: 8px;
                }
            }


            &[data-avatar="1"] .avatar-group-img-wrap {
                height: 31px;
                width: 31px;
                top: -1px;
                left: -1px;

                img {
                    height: 30px;
                    width: 30px;
                }
            }
        }

        &-md {
            height: 40px;
            width: 40px;
            flex: 0 0 40px;
            border-radius: 20px;
        }

        &[data-avatar="1"] {
            .review-profile-inner-text {
                font-size: 15px;
            }
        }
        // &-sm {
        //     &[data-avatar="1"] {
        //         .review-profile-inner-text {
        //             font-size: 10px;
        //         }
        //     }
        // }

        &[data-avatar="2"] {
            .avatar-group-img-wrap {
                &:first-child {
                    left: 0 !important;
                    width: 50%;
                    height: 41px;
                    img {
                        height: 100%;
                        left: 0;
                    }
                }
                &:nth-child(2) {
                    left: 50% !important;
                    width: 50%;
                    height: 41px;
                    img {
                        height: 100%;
                        left: 0 !important;
                    }
                }
            }            
        }

        &-group {
            background: var(--bkg-blue-bkg-blue-weak, #BDDDF2);
            border: 1px solid var(--Blue-Light, #96C8EB);
        }

        &-group-img-wrap,
        &-group-member {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;

            img {
                height: 40px;
                width: 40px;
                max-width: 100%;
                vertical-align: top;
                object-fit: cover;
                overflow-clip-margin: unset;
            }
        }

        &-group-img-wrap {
            overflow: hidden;
            /*border: 1px solid #96C8EB !important;*/
            background: var(--bkg-blue-bkg-blue-weak, #BDDDF2);
            height: 41px;
            width: 41px;
            top: -1px;
            left: -1px;

            div {
                height: 100%;

                .review-profile-inner-text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                }
            }

            &-text {
                color: var(--Text-Primary, #333);
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                text-align: left;
                padding-left: 2px;
            }
        }

        &-group {
            top: 0;
            left: 0;
            overflow: hidden;
            position: relative;
            // z-index: 1;


            &[data-avatar="2"] .avatar-group-img-wrap:first-child {
                left: -25%;
            }

            &[data-avatar="2"] .avatar-group-img-wrap:nth-child(2) {
                left: 50%;
            }

            &[data-avatar="2"] .avatar-group-img-wrap:nth-child(2) .avatar-group-member {
                left: -25%;
            }

            &[data-avatar="3"] .avatar-group-img-wrap:first-child {
                left: 25%;
            }

            &[data-avatar="3"] .avatar-group-img-wrap:nth-child(2),
            &[data-avatar="3"] .avatar-group-img-wrap:nth-child(3) {
                left: 0;
                width: 50%;
                height: 50%;
            }

            &[data-avatar="2"] {
                .avatar-group-img-wrap {
                    &:nth-child(2) {
                        border-left: 1px solid var(--Blue-Light, #96C8EB);
                    }
                }
            }


            &[data-avatar="3"] {
                .avatar-group-img-wrap {
                    top: 0;
                    left: 0;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    &:nth-child(2) {
                        // border-bottom: none;
                        border-right: 1px solid var(--Blue-Light, #96C8EB);
                        border-bottom: 1px solid var(--Blue-Light, #96C8EB);
                    }

                    &:nth-child(3) {
                        top: 50%;
                        border-right: 1px solid var(--Blue-Light, #96C8EB);
                    }
                }
            }

            &[data-avatar="4"] {
                .avatar-group-img-wrap {
                    border: none;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    &:nth-child(1) {
                        top: 0;
                        left: 0;
                        border-right: 1px solid var(--Blue-Light, #96C8EB);
                        border-bottom: 1px solid var(--Blue-Light, #96C8EB);
                    }

                    &:nth-child(2) {
                        top: 0;
                        border-bottom: 1px solid var(--Blue-Light, #96C8EB);
                    }

                    &:nth-child(3) {
                        left: 0;
                        border-right: 1px solid var(--Blue-Light, #96C8EB);
                    }

                    &:nth-child(4) {
                        left: 0;
                    }
                }
            }

            &[data-avatar="4"] .avatar-group-img-wrap {
                width: 50%;
                height: 50%;
            }

            &[data-avatar="4"] .avatar-group-img-wrap:nth-child(2),
            &[data-avatar="4"] .avatar-group-img-wrap:nth-child(4) {
                left: 50%;
            }

            &[data-avatar="4"] .avatar-group-img-wrap:nth-child(3),
            &[data-avatar="4"] .avatar-group-img-wrap:nth-child(4) {
                top: 50%;
            }
        }
    }
    .rightside.stenciled {
        .businessLocationBlock {
            display: none;
        }
    }
}
.vcMobile:not(.vcTab-portrait) {
    .btn.btn-message {
        width: 100%;
        border: none;
        border-radius: 10px;
        background: var(--bkg_neutral_default);
        // margin-top: 24px;
        font-size: 16px;
        .messageBtnWrapForMobile {
            background: var(--Connect-Primary-Button-Default, linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .employeeDetailPophover {
        height: 100%;
        width: 100%;
        min-width: 100%;
        &Wrap {
            width: 100%;
            height: 100%;
            transform: translate3d(0px, 0px, 0px) !important;
        }
        .rightside {
            height: 100%;
            width: 100%;
            max-width: 100%;
            .rightside-wrapper {
                width: 100%;
                height: 100%;
                background-color: #f8f8f8 !important;
                .personal-info {
                    padding-top: 24px !important;
                    .avatar {
                        width: 96px;
                        height: 96px;
                        border-radius: 100%;
                        &[data-avatar="1"] .avatar-group-img-wrap {
                            width: 97px;
                            height: 97px;
                            img {
                                width: 96px;
                                height: 96px;
                            }
                        }
                    }
                    .name-wrap h3 {
                        margin-bottom: 3px;
                    }
                }
                .btn.btn-message {
                    width: 100%;
                    border: none;
                    border-radius: 10px;
                    background: var(--bkg_neutral_default);
                    margin-top: 24px;
                    font-size: 16px;
                    .messageBtnWrapForMobile {
                        background: var(--Connect-Primary-Button-Default, linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%));
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                .right-mobileHeaderDetailBlock {
                    display: flex;
                    height: 44px;
                    .right-mobileHeader-back {
                        min-width: 44px;
                        height: 44px;
                        display: flex;
                        vertical-align: middle;
                        justify-content: center;
                        text-decoration: none;
                        font-size: 20px;
                    }
                    .right-mobileHeader-detail {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        flex-grow: 1;
                        text-align: center;
                        margin-right: 44px;
                    }
                }
                .services-list-wrap .block-status {
                    padding-top: 4px;
                }

                &.employee-detail {
                    .bottom-content-wrap {
                        max-height: 100%;
                        overflow: auto;
                        padding: 16px !important;
                        background-color: #fff;
                        border-radius: 10px;
                        margin: 0px 16px 16px 16px;
                    }
                }
            }
        }
    }
}
/*- END: right side details in pop hover -*/

.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox {
    .editControlmainWrap {
        .editControl-primary {
            .editControl:not(.reactEmojiWrap):hover {
                transform: scale(1.2);
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
        }
    }
    .messages {
        .editControlmainWrap {
            top: 0;
            opacity: 0;
            min-width: max-content;
            position: absolute;
            z-index: 10;
        }
        &:hover {
            .editControlmainWrap {
                top: -35px;
                opacity: 1;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
        }
    }
    &.recived {
        .reactionMessageWrap {
            .editControlmainWrap {
                top: 0;
                opacity: 0;
                min-width: max-content;
                position: absolute;
                z-index: 10;
            }

            &:hover {
                
                    > .editControlmainWrap {
                        top: -16px;
                        // z-index: 9;
                        opacity: 1;
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                    }
                
                    .reactedWrap:hover ~ .editControlmainWrap {
                        opacity: 0;
                        top: 0;                        
                    }
            }

        }
    }

}
.reactedWrap .reactionBlock .reaction:hover {
    .reaction-emoji {
        animation: reactionAnimation 1s linear;        
        // -webkit-transition: all 0.3s ease;
        // transition: all 0.3s ease;
    }
}

@keyframes reactionAnimation {
    0% {
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    } 100% {
        transform: scale(1);
    }
}
.vcMobile:not(.vcTab-portrait) {
    .editControlmainWrap {
        position: fixed !important;
        top: auto !important;
        bottom: 0;
        z-index: 50;
        width: 100%;
        
        &:has(.editControlWrap) {
            background: rgba(24, 19, 43, 0.21);
            height: 100%;                
        }
        .editControlWrap {
            // position: static !important;
            width: 100%;
            max-width: 100% !important;
            
        }
    }
    .vConnectMainWrap .chatarea .chatListBlock .chatList-person:not(.personRead) {
        .chatList-person_right-bottom {
            padding-right: 35px;
            .isTypingMain {
                width: 100%;
            }
        }
    }
}
.vcTab {
    .employeeDetailPophoverWrap {
        .emp-back-arrow {
            display: none;
        }
    }
}
.vConnectMainWrap .chatarea .chatListBlock .chatList-person_right {
    .chatList-person_time {
        .mention-teg {
            display: none;
        }
    }
}
.vcTab {
    .chatList-person_time {
        &:has(.mention-teg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .mention-teg {
                position: static;
                margin-left: 5px;
                display: block !important;
            }
        }
    }
    .chatList-person_right-bottom {
        .mention-teg {
            display: none;
        }
    }
}
.vcMobile {
    .chatList-person_time {
        .mention-teg {
            display: none;
        }
    }
    .chatList-person:not(.personRead) {
        .chatList-person_right-bottom {
            .mention-teg {
                right: 30px;
            }
        }

    }
}

/*- END: Reaction -*/

/*- START: Is typing -*/

.messagesArea-footer {
    .isTypingMain {
        padding: 10px 0px;
        background: var(--bkg_neutral_secondary) !important;
    }
    .typeChatWrap.collapsibleChat {
        &:has(.isTypingMain){
            border-top: none;
        }
    }
}

.isTypingWrap {
    display: flex;
    align-items: center;
    z-index: 9;
    &:not(:only-child) {
        margin-bottom: 8px;
    }    
    &:last-child {
        margin-bottom: 8px;
    }
    &:only-child {
        margin-bottom: 0;
    }
    &-text {
        margin-left: 10px;
        &_personList {
            font-size: 12px;
            color: var(--text_neutral_secondary);
            margin-left: 8px;
        }
    }

    &-loader {
        display: flex;
        align-items: center;
        border-radius: 7px;
        background: var(--bkg_neutral_tiertiary);
        padding: 5px 6px 3px 6px;
        height: 24px;
        .loaderDot {
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background-color: #A2A0A2;
            color: #A2A0A2;
            animation: typeLoader 1.5s infinite;
            animation-fill-mode: both;
            &-1 {
                
                animation-delay: 0s;
            }
            &-2 {
                margin-left: 8px;
                animation-delay: 0.2s;
            }
            &-3 {
                margin-left: 8px;
                animation-delay: .4s;
            }
        }
        
      }
      
      @keyframes typeLoader {
        0% {
            opacity: .1;
            transform:translatey(0);
          }
          20% {
            opacity: 1;
              transform:translatey(-2px);
          }
          100% {
            transform:translatey(0);
            opacity: .1;
        
          }
      }
}
.vConnectMainWrap .chatarea .chatListBlock .chatList-person {
    .isTypingWrap-loader {
        height: 20px;
    }
}

.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages {
    .mention-teg {
        right: -20px;
        top: 10px;
    }
}

.vcMobile {
    .messagesArea-footer {
        .isTypingMain {
            padding-left: 50px;
            padding-bottom: 0;
            
            &.aiTypinginChat {
                padding-left: 16px;
            }
        }
    }
}
.vConnectMainWrap.rightSideOnly.smallScreen {
    .messagesArea-footer .isTypingMain {
        bottom: 59px;
    }
}

/*- END: Is typing -*/

/*- START: More reaction for mobile -*/
.emojiPickerReactionFormobile {
    position: fixed;
    z-index: 50;
    width: 100%;
    left: 0;
    bottom: 0;
    background: rgba(24, 19, 43, 0.21);
    display: flex;
    align-items: end;
    justify-content: center;
    
    &:has(.swipeUp) {
        height: 100%;
    }
    em-emoji-picker {
        width: 100%;
        height: 100%;
        min-height: 100%;
        max-width: 100%;
    }
    .emojiWrap {
        width: 100%;
        > div {
            height: 100%;
        }
       
        &.swipeUp {
            div {
                height: 100%;
                em-emoji-picker {
                    height: 100%;
                    min-height: 100%;
                }
            }
        }
    }
   
      
}
#emoji_picker_Wrap {
    transition: height 0.3s ease-in-out;
}


/*- END: More reaction for mobile -*/
/*-- START: Customer side send button changes --*/
@media only screen and (max-width: 1024px) {
    .customerChatWindow-mobile,
    .vcTab-vagaroapp {
        &.vagaroConnect.customerChatWindow.websitebuilder-loaded {
            .typeChatWrap.collapsibleChat.mobileActiveChat #sendbutton1 {
                background: #de3a3a !important;
            }
            .mobileListmenuWrap .dropdown-toggle i {
                color: #de3a3a !important;
            }
        }
        .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .typeMsgText {
            position: static;
            margin-left: 0;
        }
        .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox {
            .messages {
                .editControlmainWrap {
                    display: none;
                }
                &:hover {
                    .editControlmainWrap {
                        display: block;
                    }
                }
            }
            &.recived .reactionMessageWrap {
                .editControlmainWrap {
                    display: none;
                }
                &:hover > .editControlmainWrap {
                    display: block;
                } 
            }
        }
        .messagesBox.messagesEditWrap {
            .ckeditor_2 {
                width: 100%;
            }
        }
        &.customerChatWindow {
            .typeChatWrap.collapsibleChat {
                .mobileListmenuWrap .mobileListmenu.dropdown {
                    display: block;
                }
            }
        }
        .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .ck-reset.ck-editor {
            box-shadow: none;
            flex-direction: row-reverse;
            align-items: end;
            justify-content: start;
            .ck-editor__top {
                margin-bottom: 0;
                width: auto;
                margin-left: 8px;
            }
            .ck-editor__main .ck-editor__editable {
                margin-right: 0;
            }
        }
        .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .ck-reset.ck-editor .ck-toolbar .cke_button.cke_button_Send {
            display: block !important;
            border-radius: 100%;
            font-size: 0;
            // position: absolute;
            right: 0px;
            bottom: 10px;
            min-height: 35px;
            min-width: 35px;
            height: 35px;
            width: 35px;
            max-width: min-content;
            margin-right: 0px !important;
            background: var(--border_theme_flamingo_default);
            padding: 0 9px 0 8px !important;
            &::before {
                content: "\f061";
                font-size: 18px;
                font-family: "Font Awesome 5 Pro";
                font-weight: 900;
                line-height: 37px;
            }
        }
        .ckeditor_1 {
            .ck.ck-toolbar>.ck-toolbar__items {
                display: none;
            }
        }
        .mobileListmenuWrap {
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: start;
            background: #fff;
            .dropdown-toggle:hover,
            .dropdown-toggle:focus,
            .dropdown-toggle:active {
                padding: 0;
                border: none;
                outline: none;
            }

            .dropdown-toggle {
                // background: linear-gradient(180deg, #09699F 0%, #533B88 100%);
                // background-clip: text;
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                
                    padding: 0px;
                    width: 32px;
                    height: 32px;
                    font-size: 24px;
                    font-weight: 900;
                    color: #DE3A3A;
                    font-family: "Font Awesome 6 Pro";
                    margin-left: 5px;
                    z-index: 1;
                    position: relative;
                    // margin-right: -10px;
                    // top: 12px;
                    bottom: 0;
                    border: none;
                    outline: none;
                i {
                    color: var(--border_theme_flamingo_default);
                }
            }
            .dropdown-menu {
                position: fixed !important;
                width: 100%;
                transition: all 0.2s !important;
                opacity: 0 !important;
                visibility: hidden !important;
                display: block !important;
                border-radius: 0px;
                inset: auto 0 -100% 0 !important;
                background-color: #f8f8f8 !important;
                border: none !important;
                border-radius: 0 !important;
                border-top-left-radius: 20px !important;
                border-top-right-radius: 20px !important;
                padding: 16px;
                a {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 12px 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    background-color: #fff;
                    border-top: solid #e7e7e7 1px;
                    &:first-of-type {
                        border-top-left-radius: 10px !important;
                        border-top-right-radius: 10px !important;
                    }
                    &:last-of-type {
                        border-bottom-left-radius: 10px !important;
                        border-bottom-right-radius: 10px !important;
                    }
                    .fa-regular {
                        width: 32px;
                        height: 32px;
                        text-align: center;
                        margin-right: 4px;
                        font-size: 20px;
                        line-height: 32px;
                    }
                    &.useCamera,
                    &.vagaroLinks {
                        display: none;
                    }

                }
            }
            .mobileListmenu {
                margin-top: 13px;
            }
            .mobileListmenu.dropdown.show .dropdown-menu {
                transform: translateY(0) !important;
                visibility: visible !important;
                opacity: 1 !important;
                display: block;
                inset: auto 0 0% 0 !important;
            }
        }
        .ckeditor_1 {
            flex: 1 1;
            max-width: calc(100% - 38px);
        }
        .vConnectMainWrap.rightSideOnly.chatActive .collapsibleChat.mobileActiveChat .ck-editor {
            justify-content: start;
        }
        .vConnectMainWrap.rightSideOnly.chatActive .collapsibleChat.mobileActiveChat .ck-editor__main {
            width: 86% !important;
            background: var(--bkg_neutral_default) !important;
            border: 1px solid #e7e7e7 !important;
            border-radius: 20px !important;
            overflow: hidden;
        }
        .typeChatWrap.collapsibleChat {
            #sendbutton1 {
                display: none !important;
            }
            .ck-reset.ck-editor .ck-editor__main {
                width: 100%;
            }
            &.mobileActiveChat {
                #sendbutton1 {
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px !important;                    
                }
                .ck-reset.ck-editor .ck-editor__main {
                    width: 100%;
                }
            }
        }
    }
}
/*-- END: Customer side send button changes --*/

// START: For colapcibal chat
.vagaroConnect.collapsibleDropDown {
    &:not(.vcTab-portrait),
    &:not(.vcTab),
    &:not(.vcMobile) {
        .employeeDetailPophover .rightside-wrapper {
            min-height: auto;
            &.employee-detail {
                .personal-info {
                    padding-top: 16px;
                }
            }
            .messageBtnwrap {
                margin-bottom: 10px;
            }
            .bottom-content-wrap {
                padding-bottom: 0;
            }
        }
    }
    .dropdown-menu.mention-popup {
        .mention-popup_topArea {
            max-height: 145px;
        }
    }
}
.vConnectMainWrap.rightSideOnly.smallScreen {
    .mentionListMainWrap {
        right: 32px !important;
        left: auto !important;
    }
}
.keywordopen.vcTab {
    .dropdown-menu.mention-popup {
        .mention-popup_topArea {
            max-height: 110px;
        }
    }    
}

.avatarClickDetailPage {
    .messageBtnwrap {
        margin: 0 16px 16px 16px;
    }
    
    .rightside-wrapper .btn.btn-message {
        width: 100%;
        border: none;
        border-radius: 10px;
        background: var(--bkg_neutral_default);
        margin-top: 24px;
        font-size: 16px;
    }
}
.vcTab {
    .avatarClickDetailPage .messageBtnwrap {
        margin-bottom: 0;
    }
}
.vcTab-portrait,
.vcTab {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControl-primary .editControl {
        width: 35px;
        height: 29px;
        font-size: 18px;
    }
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages:hover .editControlmainWrap {
        top: -40px;
    }
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControl i.fa-pen,
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControl i.fa-trash {
        font-size: 17px;
    }    
}
.mention-popup .mention-popup_topArea .dropdown-item.onlyoneMember .mention-everyone-row {
    border: none;
}
.vcAndroidTab {
    .mention-popup .mention-popup_topArea {
        max-height: 150px;
    }
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.recived .reactionMessageWrap .editControlmainWrap {        
        left: 0;
    }
}
 // END: For colapcibal chat

 /*- START: VC card design -*/
 .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages {
     &.vcCard {
        border-radius: 8px;
        border: 1px solid #CDCDCD;        
        padding: 12px;        
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        .v-card {
            // display: flex;
            // align-items: center;
            position: relative;
            z-index: 3;
            .avatar {
                margin-top: 0;
                .avatar-group-member {
                    height: 100%;
                }
            }
            .avatar-sm .avatar-group-img-wrap .review-profile-inner-text {
                font-size: 15px;
            }
        }
    
        .vcCard {
            &-welcomeText {
                font-weight: 400;
            }
            &-title {
                margin-right: 10px;
                max-width: calc(100% - 37px);
                display: flex;
                align-items: center;
                &_icon {
                    border-radius: 20px;
                    background: var(--bkg-category-bkg-category-dawn, #C3BFDF);
                    height: 32px;
                    width: 32px;
                    padding: 8px;
                    display: inline-block;
                    margin-right: 8px;
        
                    svg {
                        vertical-align: top;
                    }
                }
        
                &_name {
                    display: inline-block;
                    font-size: 16px;
                    margin-bottom: 0px;
                    
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                }
            }
            &-iconWrap {
                display: flex;
                align-items: center;
                .fa-address-card {
                    font-size: 24px;
                    color: var(--text_neutral_default);
                    vertical-align: middle;
                    margin-top: 1px;
                }
            }
            &-business_name {
                font-size: 12px;
                font-weight: 400;
                color: var(--text_neutral_secondary);
                line-height: 12px;
                display: none;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }
            &-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                background: var(--bkg_neutral_default) !important;
                color: var(--text_neutral_default) !important;
                padding: 10px;
                border-radius: 8px;
            }
            
        }
    
        &-button {
            border-radius: 3px;
            border: 1px solid var(--border-components-button-border-components-button-default, #CDCDCD);
            background: var(--bkg-components-button-bkg-components-button-neutral-hover, #F8F8F8);
            padding: 8px 16px;
            color: var(--text-neutral-text-neutral-default, #333);
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            display: block;
            margin-top: 8px;
            text-decoration: none;
            text-align: center;
        }
    }
 }
 /*- END: VC card design -*/

 /*- START: Live stream design -*/
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .messages {
    .liveStreamBlock {
       border-radius: 8px;
    //    border: 1px solid #CDCDCD;        
    //    padding: 12px;
        z-index: 9;
        position: relative;
       font-size: 15px;
       font-style: normal;
       font-weight: 500;
       line-height: 20px;
    //    .v-card {
    //        // display: flex;
    //        // align-items: center;
    //        position: relative;
    //        z-index: 3;
    //        .avatar {
    //            margin-top: 0;
    //            .avatar-group-member {
    //                height: 100%;
    //            }
    //        }
    //        .avatar-sm .avatar-group-img-wrap .review-profile-inner-text {
    //            font-size: 15px;
    //        }
    //    }
   
       .liveStreamBlock {
           &-welcomeText {
               font-weight: 400;
           }
           &-title {
               margin-right: 10px;
               max-width: calc(100% - 37px);
               display: flex;
               align-items: center;
               color: var(--text_neutral_default) !important;
               a {
                    color: var(--text_neutral_default) !important;
               }
               &_icon {
                   border-radius: 20px;
                   background: var(--bkg-category-bkg-category-dawn, #C3BFDF);
                   height: 32px;
                   width: 32px;
                   padding: 8px;
                   display: inline-block;
                   margin-right: 8px;
       
                   svg {
                       vertical-align: top;
                   }
               }
       
               &_name {
                   display: inline-block;
                   font-size: 16px;
                   margin-bottom: 0px;
                   
                   overflow: hidden;
                   text-overflow: ellipsis;
                   white-space: nowrap;
                   width: 100%;
               }
           }
           &-iconWrap {
               display: flex;
               align-items: center;
               .fa-address-card {
                   font-size: 24px;
                   color: var(--text_neutral_default);
                   vertical-align: middle;
                   margin-top: 1px;
               }
           }
           &-business_name {
               font-size: 12px;
               font-weight: 400;
               color: var(--text_neutral_secondary);
               line-height: 12px;
               display: none;

               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
               width: 100%;
           }
           &-footer {
               display: flex;
               align-items: center;
               justify-content: space-between;
               margin-top: 10px;
               background: var(--bkg_neutral_default) !important;
               color: var(--text_neutral_default) !important;
               padding: 10px;
               border-radius: 8px;
           }
           
       }
   
       &-button {
           border-radius: 3px;
           border: 1px solid var(--border-components-button-border-components-button-default, #CDCDCD);
           background: var(--bkg-components-button-bkg-components-button-neutral-hover, #F8F8F8);
           padding: 8px 16px;
           color: var(--text-neutral-text-neutral-default, #333);
           font-size: 15px;
           font-style: normal;
           font-weight: 500;
           line-height: 20px;
           display: block;
           margin-top: 8px;
           text-decoration: none;
           text-align: center;
       }
   }
}
.liveStreamCallButton {
    cursor: pointer;
}
.customerChatWindow {
    &.inCommingCall.modal-open {
        .fade.modal.show,
        .fade.modal-backdrop.show {
            z-index: 9999;
            width: 370px;
            right: 9px;
            left: auto;
        }
        .fade.modal-backdrop.show {
            border-radius: 9px 9px 0 0;
        }
        .livestream-modal {}
    }
}
/*- END: Live stream design -*/

 /*- START: GIF changes for mobile-*/
.vcMobile:not(.vcTab-portrait) {
    .vConnectMainWrap .messagesArea-footer .gifPluginBlock {
        bottom: 0;
    }
    .reactGiphySearchbox-componentWrapper {
        margin: 0 auto;
    }

    .giffyWrap.swipeUp .reactGiphySearchbox-listWrapper {
        height: 88vh !important;
    }
    #giffyFormobile {
        transition: height 0.3s ease-in-out;
    }
    &.keywordopen {
        .giffyWrap.swipeUp {
            height: calc(100vh - 292px) !important;
        }
        .welcomeScreen {
            .giffyWrap.swipeUp {
                height: calc(100vh - 340px) !important;
            }
        }
    }
    .welcomeScreen {
        .giffyWrap.swipeUp {
            height: calc(-54px + 100vh) !important;
        }
    }
    // .messagesEditWrap .gifPluginBlock {
    //     top: auto !important;
    //     bottom: 1px !important;
    // }
    .vConnectMainWrap.rightSideOnly .gifPluginBlock {
        bottom: 0;
    }
}

 /*- END: GIF changes for mobile-*/
.mention-teg { position: absolute; right: 12px; bottom: 16px; z-index: 1; color: var(--text_alert_default);
    text-align: center;
    font-family: "Font Awesome 6 Pro";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.emoji-banner-open .modal-backdrop {
    z-index: 99999 !important;
}

.emoji-banner-open .modal {
    z-index: 999999 !important;
}
/*- END: Video upload -*/

@keyframes aiBGcolorAnimation {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 200% 0%;
    }
}

.controlaiWrap {
    border-radius: 8px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    // background: linear-gradient(131deg, #415CF6 24.51%, #9F0CD4 73.22%);
    background: linear-gradient(135deg, var(--text_ai_secondary) 0%, var(--text_ai_secondary) 20%, var(--text_ai_default) 44%, var(--text_ai_secondary) 78%, var(--text_ai_secondary) 100%);
    padding: 1px;

    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;

    background-size: 200% 200% !important;
    transform-origin: right;
    animation-name: aiBGcolorAnimation;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &Block {
        border-radius: 7px;
        background: linear-gradient(0deg, rgba(159, 12, 212, 0.10) 0%, rgba(159, 12, 212, 0.10) 100%), var(--bkg_neutral_default);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        &-textIconWrap {
            display: flex;
            align-items: center;
        }
        &-iconWrap {
            margin-right: 8px;
            i {
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                // background: linear-gradient(131deg, #415CF6 24.51%, #9F0CD4 73.22%);
                background: linear-gradient(135deg, var(--text_ai_secondary) 0%, var(--text_ai_secondary) 20%, var(--text_ai_default) 44%, var(--text_ai_secondary) 78%, var(--text_ai_secondary) 100%);                
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;


                -webkit-transition: all 0.1s linear;
                transition: all 0.1s linear;

                background-size: 200% 200% !important;
                transform-origin: right;
                animation-name: aiBGcolorAnimation;
                animation-duration: 4s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
        }
        &-text {
            color: var(--text_neutral_default);
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            margin-right: 16px;
        }
        &-button {
            .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
                border-radius: 3px;
                border: 1px solid var(--border_neutral_strong);
                background: var(--bkg_components_button_neutral_default);
                color: var(--text_neutral_default);
                padding: 8px 16px;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                &:hover {
                    background: var(--bkg_components_button_neutral_hover);
                }
            }
        }
    }
}



.vConnectMainWrap.chatActive.rightSideOnly.smallScreen {
    .messagesArea-middle.tackControlActive {
        height: calc(100vh - 189px);
    }

}
.vConnectMainWrap.rightSideOnly.smallScreen .messagesArea-middle {
        &:has(~.messagesArea-footer .typeChatWrap.collapsibleChat .isTypingMain) {
                padding-bottom: 50px;
        }
    }

.lastModified {
    color: var(--text_blue_default);
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    // float: right;
    display: block;
    text-align: right;
    cursor: pointer;
    padding: 24px;
    &:hover {
        color: var(--text_blue_default);
        text-decoration: underline;
    }
    &:empty {
        display: none;
    }
}

/*-- START: Ai Design --*/
.aiResponce {
    
    &Block {        
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        &-text {
            color: var(--text_neutral_default);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-right: 4px;
        }

        &-iconWrap {
            width: 30px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .fa-thumbs-down {
                font-size: 16px;                
                color: var(--text_neutral_default);
            }
        }
    
    }
    &Wrap {
        border-radius: 8px;
        background: var(--bkg_neutral_default);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        padding: 8px 10px;
        cursor: pointer;

        &:hover {
            background: var(--bkg_components_button_neutral_hover);
        }
        
        &.active {
            .aiResponceBlock-iconWrap {
                background: var(--bkg_neutral_tiertiary);
                border-radius: 5px;

            }
        }
    }
}
.aiAvatar {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(131deg, #415CF6 24.51%, #9F0CD4 73.22%);
    color: #fff;
    font-size: 12px;
}
.aiIconForPersonArea {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    // background: linear-gradient(131deg, #415CF6 24.51%, #9F0CD4 73.22%);
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;

    background: linear-gradient(135deg, var(--text_ai_secondary) 0%, var(--text_ai_secondary) 20%, var(--text_ai_default) 44%, var(--text_ai_secondary) 78%, var(--text_ai_secondary) 100%);                
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;

    background-size: 200% 200% !important;
    transform-origin: right;
    animation-name: aiBGcolorAnimation;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.messagesBox.aiMessagesBox {
    .messageBottomWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .feedbackSubmited {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: var(--text_neutral_secondary);
            margin-right: 15px;
        }
    }
}
.vConnectMainWrap .chatarea .messagesArea-middle {
    &.tackControlActive {
        height: calc(100vh - 232px);
    }
}
.vcTab,
.vcTab-portrait,
.vcMobile {
    .messagesBox.aiMessagesBox .messageBottomWrap .feedbackSubmited {
        z-index: 2;
    }
}

// .vcMobile:not(.vcTab-portrait) {
//     .aiResponceMobileWrap {
//         background: rgba(24, 19, 43, 0.21);
//         position: fixed;
//         bottom: 0;
//         top: auto;
//         height: 100%;
//         width: 100%;
//     }
//     .aiResponceWrap {
//         width: 100%;
//         border-radius: 10px;
//         padding: 12px 16px;
//         box-shadow: none;
//         position: absolute;
//         bottom: 0;
//         border-radius: 20px 20px 0px 0px;
//         background: #F8F8F8;
//         .aiResponceBlock {
//             flex-direction: row-reverse;
//             justify-content: start;
//             padding: 12px 16px;
//             background: var(--bkg_neutral_default);
//             &-text {
//                 font-size: 16px
//             }
//             &-iconWrap {
//                 height: 28px;
//                 width: 28px;
//                 margin-right: 4px;
//                 .fa-thumbs-down {
//                     font-size: 18px;
//                 }
//             }
//         }
//     }
// }

.editControl-secondary:has(.editControl.aiResponceWrap) {
    width: 100%;
    border-left: none !important;
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControlWrap:has(.editControl.aiResponceWrap) {
    min-width: unset !important;
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControlmainWrap:has(.editControl.aiResponceWrap):hover {
    .editControlWrap {
        background: var(--bkg_components_button_neutral_hover) !important;
    }
}
.vcMobile:not(.vcTab-portrait) .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControl-secondary {
    &:has(.editControl.aiResponceWrap) {
        border-radius: 10px;
    }
}
.vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControl-secondary .editControl.aiResponceWrap {
    width: 100% !important;
    background: transparent !important;
    box-shadow: none;
    padding: 0 !important;
    border-radius: 10px !important;
    .aiResponceBlock {
        &-iconWrap {
            i {
                font-size: 16px;
            }
        }
        .editControl-secondary_text.editControlText.aiResponceBlock-text {
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.vcMobile:not(.vcTab-portrait) {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControlWrap .editControl-secondary .editControl.aiResponceWrap {
        .aiResponceBlock {
            width: 100%;
            padding: 12px 16px;
            flex-direction: row;
            &-iconWrap {
                width: 28px;
                height: 28px;
                margin-right: 4px;
                i {
                    font-size: 18px;
                    font-weight: 400;
                    margin-right: 0;
                }
            }
            &-text {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
            }
        }
    }
}

.vcMobile  {
    .newFeatureAiPopup {
        &.modal-mb-half {
            .modal-header button.btn-close {
                display: block;
            }
            .modal-footer {
                gap: 0;
                border: none;
            }
        }
        .modal-body {
            .ai-banner-popup-box {
                margin-bottom: 16px;
                text-align: center;
                img {
                    max-width: 100%;
                }
            }
        }
    }
}
.ai-banner-popup-box {
    margin-bottom: 16px;
    text-align: center;
    img {
        max-width: 100%;
    }
}
.vcTab {
    .newFeatureAiPopup {
        align-items: end;
        margin-top: 0;
        height: 100vh;
        margin-bottom: 0;

        &.modal-mb-half {
            .modal-header button.btn-close {
                display: block;
            }
            .modal-footer {
                gap: 0;
                border: none;
                .btn {
                    margin-left: 0;
                    width: 100%;
                    + .btn {
                        margin-top: 16px;
                    }
                }
            }
        }
        .modal-body {
            .ai-banner-popup-box {
                margin-bottom: 16px;
                text-align: center;
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

.vcTab-portrait.vcMobile {
    .vConnectMainWrap .chatListBlock .chatList-person:not(.personRead) {
        .aiIconForPersonArea {
            margin-right: 20px;
        }
    }
}

.vcTab {
    .vConnectMainWrap .chatarea .chatListBlock .chatList-person.aiistyping {
        .chatList-person_time {
            right: 30px;
            bottom: 17px;
        }

    }

}

.vConnectMainWrap.chatActive.rightSideOnly.smallScreen  {
    .messagesArea-footer .isTypingMain {
        padding-left: 10px;
        padding-right: 10px;
    }
}
body:not(.vcMobile) {
    .vConnectMainWrap.rightSideOnly .collapsibleChat {
        &:has(.controlaiWrap) {
            // width: calc(100% - 1px);
            width: 100%;
            bottom: 1px;
            right: 1px;
        }
        .controlaiWrap {
            margin: 4px;
        }
    }
}

.vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail.aiCardWrap,
.vConnectMainWrap .rightside-wrapper .appointment-detail.aiCardWrap {
    cursor: default;
    &:hover {
        background: transparent !important;
        // .mobile-contextmenu.dropdown {
        //     display: none;
        // }
        .fa-solid.fa-ellipsis-vertical {
            display: none;
        }
    }
    .mobile-contextmenu.serviceCardWrap {
        pointer-events: none;
    }
    .service-wrap {
        cursor: default;
    }
}

.vcTab,
.vcTab-portrait,
.vcMobile {
    .vConnectMainWrap .rightside-wrapper .appointment-detail.aiCardWrap {        
        .mobile-contextmenu.dropdown {
            pointer-events: none;
            .dropdown-menu.show {
                display: none !important;
            }
        }        
    }
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap,
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.purchases-wrap {
        .appointment-detail.aiCardWrap {
            .mobile-contextmenu.dropdown {
                display: none;
            }

        }
    }
}
.vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap,
.vConnectMainWrap .rightside-wrapper .services-list-wrap.purchases-wrap {
    .appointment-detail.aiCardWrap {
        .mobile-contextmenu.dropdown {
            display: none;
        }

    }
}
/*-- END: Ai Design --*/

/*-- START: Address Book Design changes --*/
.vcMobile {
    .rightSideOnly-nav:not(.inboxPage-header),
    .employee-multilocation-select.select2-container .select2-search--dropdown,
    .select2-container.select2-container--default.vcEmployeeSelect.ddlSelectCustomer1 .select2-results__options,
    .select2-container.select2-container--default.vcEmployeeSelect .select2-results {
        background: var(--bkg_neutral_secondary) !important;
    }
    .ddlSelectCustomer1.select2-container.select2-container--default.vcEmployeeSelect .select2-search--dropdown {
        border: none !important;
        padding-bottom: 14px !important;
    }
    .addressBook {
        &MainWrap {}
        &-initialMainBlock {
            border: none !important;
            .initialBlock {
                padding: 0 0 5px 8px !important;
            }
        }
        &-initialBlockWrap {
            list-style: none;
            .select2-results__option {
                border: none !important;

                label {
                    width: 100%;
                    padding: 11px 0;
                    border-bottom: 1px solid var(--border_neutral_strong);
                }
                .avatar.avatar-sm {
                    height: 28px;
                    width: 28px;
                    flex: 0 0 28px;
                    .avatar-group-img-wrap {
                        border: 1px solid var(--border_neutral_strong);
                        background: var(--bkg_neutral_tiertiary);
                        height: 29px;
                        width: 29px;
                        img {
                            height: 28px;
                            width: 28px;
                        }
                    }
                    .review-profile-inner-text {
                        color: var(--text_neutral_default);
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            .select2-results__option:not(.addressBook-initialLiBlock) {
                background: var(--bkg_neutral_default) !important;
                &:first-child {
                    border-radius: 10px 10px 0 0;
                }
                &:last-child {
                    border-radius: 0 0 10px 10px;
                    label {
                        border-bottom: none;
                    }
                }
                &:only-child {
                    border-radius: 10px;
                    label {
                        border-bottom: none;
                    }
                }
            }
        }
        &-initialLiBlock {}
    }
    .employee-multilocation-select .select2-results ul li.addressBook-initialMainBlock {
        padding: 16px 16px 0 16px !important;
    }
    .employee-multilocation-select .select2-results ul.addressBook-initialBlockWrap li.select2-results__option {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
/*-- END: Address Book Design changes --*/


.customerChatWindow {
    .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .ck-reset.ck-editor .ck-toolbar .cke_button .cke_button_icon {
        &.cke_button_icon-upload {
            margin-top: 2px;
        }
    }
    .vConnectMainWrap .smallChatBoxWrap .collapsibleChat .ck-reset.ck-editor .ck-toolbar .cke_button:not(.custom-emoji) {
        display: flex;
        align-items: center;
    }
}
.cursorDefault {
    cursor: default !important;
}

body.vagaroConnect {
    background: var(--bkg_neutral_default) !important;
}

.vcMobile,
.vcTab {
    .newFeatureAiPopup.modal-mb-half .modal-footer {
        flex-direction: column-reverse;
        .btn {
            margin-top: 12px;
            & + .btn {
                margin-top: 0;
            }
        }
    }
}

@import url(./Responsive.scss);
@import url(VcSettingPage.scss);


//setting page design
.leftside-menu.SettingMenu {
    // margin-bottom: 16px;
    border-top: 1px solid var(--border_neutral_default);
    margin-top: 10px !important;
}
.minWidthForOkButton {
    min-width: 80px;
}
.leftside.leftside-open nav.nav.flex-column.leftside-menu {
    margin-top: 16px;
}
.aiImportantNote {
    color: var(--text_blue_default);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    padding: 12px 16px;
    border-radius: 3px;
    background: var(--bkg_blue_weak);
    margin-top: 24px;
}
.vConnectMainWrap {
    &.bottom-show-footer {
        height: calc(100vh - 50px);
    }
}
.connect-settingWrap {
    height: calc(100vh - 50px);
    background: var(--bkg_neutral_default);
    display: flex;
    flex-direction: column;
    width: 100%;
    // max-width: 1440px;
}

.connect-setting-left {
    min-width: 169px;
    border-right: solid 1px;
    background: var(--bkg_neutral_default) !important;
    border-color: var(--border_neutral_default) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10;
    transition: all 0.3s ease-in;
    .leftside-menu {
        .leftside-menu_options {
            padding: 8.5px 16px 7.5px 19px;
            i {
                margin-right: 10px;
            }
            &.widget-menu {
                padding: 8.5px 16px 7.5px 21px;
                i {
                    margin-right: 11px;
                }
            }
        }
    }
}

.connet-mid-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    // flex-grow: 1; overflow: auto;
}

.connect-settingWrap .leftside-toggle {
    padding-left: 20px;
    padding-top: 15px;
    text-decoration: none;
    padding-bottom: 9px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.connect-right-wrapper {
    // margin-left: 169px;
    // padding: 24px 24px 15px 24px;
    background: var(--bkg_neutral_secondary) !important;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    margin-bottom: auto;
}
.vagaroConnect {
    .connect-right-wrapper {
        .salsePageMainWrap {
            background: var(--bkg_neutral_default);
        }
    }
    &.vcMobile,
    &.vcTab-portrait,
    &.vcTab {
        .connect-right-wrapper {
            .salsePageMainWrap {
                background: var(--bkg_neutral_secondary) !important;
            }
        }
    }
}

.connect-ai-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.ai-banner-left {
    width: 50%;
    position: relative;
    .vcconnect-primary-btn {
        padding: 10px 16px;
    }
    .vcconnect-secondary-btn {
        padding: 10px 16px;
    }
}

.ai-banner-right {
    text-align: center;
    width: 50%;
}

.banner-heading {
    color: var(--text_neutral_default);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
}

.banner-discription-text {
    color: var(--text_neutral_default);
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 133.333% */
}

.bottom-show-footer {
    .connect-setting-left {
        bottom: 60px;
    }

    .connect-right-wrapper {
        // padding-bottom: 60px;
    }
}

.settingfooter {
    width: 1219px;
    max-width: 100%;
    background-color: var(--bkg_neutral_secondary);
    box-shadow: 0px 1px 0px 0px var(--border_neutral_default) inset;
    display: flex;
    position: fixed;
    bottom: 51px;
    left: 0;
    right: 0;
    z-index: 99;
    // max-width: 1440px;
    justify-content: flex-end;
    gap: 16px;
    padding: 12px 24px;
    border: solid 1px var(--border_neutral_default);
}

// button 
.vcconnect-secondary-btn {
    padding: 10px 16px;
    background-color: var(--bkg_components_button_neutral_default);
    border: 1px solid var(--border_neutral_strong) !important;
    color: var(--text_neutral_default);
}

.vcconnect-secondary-btn:hover,
.vcconnect-secondary-btn:focus,
.vcconnect-secondary-btn.active {
    border: 1px solid var(--border_neutral_strong);
    color: var(--text_neutral_default) !important;
    background-color: var(--bkg_components_button_neutral_hover) !important;
}

.vcconnect-primary-btn {
    padding: 10px 16px;
    background-color: var(--bkg_components_button_green_default);
    border-color: var(--border_green_default);
    color: var(--text_neutral_ondark);
}

.vcconnect-primary-btn:hover,
.vcconnect-primary-btn:focus {
    background-color: var(--bkg_components_button_green_hover) !important;
    border-color: var(--bkg_components_button_green_hover) !important;
    color: var(--text_neutral_ondark) !important;
}

.desktop-hide {
    display: none;
}

.connect-settingWrap {
    .switch-checkboxhtml {
        padding-left: 65px;
        min-height: 30px;
    }

    .switch-checkboxhtml>label {
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        font-weight: 400;
        padding-top: 6px;
        position: relative;
        margin-bottom: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .access-level-description {
            float: none;
            width: auto;
            display: block;
            letter-spacing: 0.1px;
        }
    }

    .switch-checkboxhtml>label.padding-top0 {
        padding-top: 3px;
    }

    .switch-checkboxhtml>input[type="checkbox"] {
        display: none;
    }

    .switch-checkboxhtml>input[type="checkbox"]+label:before {
        background-color: var(--bkg_components_toggle_bkg);
        border: solid 1px var(--border_neutral_strong);
        content: '';
        height: 30px;
        width: 50px;
        overflow: hidden;
        cursor: pointer;
        top: 0;
        left: -65px;
        position: absolute;
        border-radius: 50px;
        -webkit-transition: background-color 200ms ease 0s;
        transition: background-color 200ms ease 0s;
    }

    .switch-checkboxhtml>input[type="checkbox"]+label:after {
        content: '';
        height: 28px;
        width: 28px;
        overflow: hidden;
        cursor: pointer;
        top: 1px;
        left: -64px;
        position: absolute;
        background-color: var(--bkg_components_toggle_icon);
        box-shadow: 1px 0 3px 0 rgba(var(--modal_box_shadow), 0.15);
        border-color: var(--border_neutral_strong);
        border-radius: 100%;
        -webkit-transition: -webkit-transform 100ms ease-in 0s;
        transition: transform 100ms ease-in 0s;
        -webkit-transform: translateX(0) translateY(0) translateZ(0);
        -moz-transform: translateX(0) translateY(0) translateZ(0);
        -ms-transform: translateX(0) translateY(0) translateZ(0);
        -o-transform: translateX(0) translateY(0) translateZ(0);
        transform: translateX(0) translateY(0) translateZ(0);
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .switch-checkboxhtml>input[type="checkbox"]:checked+label:before {
        background-color: var(--bkg_components_button_green_default);
        border-color: var(--border_green_default);

    }

    .switch-checkboxhtml>input[type="checkbox"]:checked+label:after {
        -webkit-transform: translateX(20px) translateY(0) translateZ(0);
        -moz-transform: translateX(20px) translateY(0) translateZ(0);
        -ms-transform: translateX(20px) translateY(0) translateZ(0);
        -o-transform: translateX(20px) translateY(0) translateZ(0);
        transform: translateX(20px) translateY(0) translateZ(0);
    }

    .switch-checkboxhtml>input[type="checkbox"]:disabled+label:before {
        opacity: 0.3;
    }

    .switch-checkboxhtml>input[type="checkbox"]:disabled+label,
    .switch-checkboxhtml>input[type="checkbox"]:disabled+label:before,
    .switch-checkboxhtml>input[type="checkbox"]:disabled+label:after {
        cursor: default;
    }

    .access-level-title {
        position: relative;
        margin-bottom: 6px;
        padding-top: 0px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        color: var(--text_neutral_default);
        word-wrap: break-word;
        word-break: break-word;
    }

    .access-level-description {
        word-break: break-word;
        word-wrap: break-word;
        margin-bottom: 2px;
        color: var(--text_neutral_secondary);
        font-size: 15px;
        font-weight: 400;
    }

    .div-with-switch {
        padding-bottom: 20px;
        padding-top: 23px;
        border-bottom: 1px solid var(--border_neutral_default);
        border-top: 1px solid var(--border_neutral_default);
    }

    .margintop24 {
        margin-top: 25px;
    }

    .marginbottom24 {
        margin-bottom: 24px;
    }

    .ai-box-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        width: 100%;
    }

    .ai-setting-box {
        display: flex;

        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
    }

    .customredio-box-singal {
        padding-left: 0px;
        height: 100%;
        width: 100%;


        >label {
            padding: 13px;
            border-radius: 5px;
            position: relative;
            font-size: 16px;
            border: 2px solid var(--border_neutral_strong);
            background: var(--bkg_neutral_default);
            height: 100%;
            cursor: pointer;
            display: inline-block;
            font-weight: 400;
            margin-bottom: 0;
            vertical-align: top;
            word-wrap: break-word;
            width: 100%;

            padding-left: 46px;
            padding-right: 10px;
        }


        >input[type="radio"] {
            display: none;
        }

        >input[type="radio"]+label::before {
            background-color: var(--bkg_neutral_default);
            border: solid 1px var(--border_neutral_strong);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            top: 14px;
            position: absolute;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            content: "";
            cursor: pointer;
            float: left;
            height: 20px;
            margin-left: -32px;
            width: 20px;
        }

        >input[type="radio"]:checked+label {
            border: 2px solid var(--border_blue_default);
            background: var(--bkg_components_button_ghost_hover);
        }
        >input[type="radio"]:not(:disabled)+label:hover {
            border-color: var(--border_blue_default);
            // background: var(--bkg_components_button_ghost_hover);
        }

        >input[type="radio"]:disabled:checked+label {
            border: 2px solid var(--border_neutral_strong) !important;
            cursor: not-allowed;
            background: var(--border_neutral_default) !important;
        }
        >input[type="radio"]:disabled+label,
        >input[type="radio"]:disabled+label::before,
        >input[type="radio"]:disabled+label::after {
            cursor: not-allowed;
            opacity: 0.8;
        }

        >input[type="radio"]:checked+label::before {
            -webkit-transition: all ease 0.2s;
            -moz-transition: all ease 0.2s;
            transition: all ease 0.2s;
            margin-left: -32px;
            text-align: center;
            color: white;

            content: "";
            border-color: var(--icon_blue_default);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 20px;
            width: 20px;
            top: 14px;
            position: absolute;
        }

        >input[type="radio"]:checked+label:after {
            // background: white;
            color: var(--icon_blue_default);
            // -webkit-border-radius: 50%;
            // -moz-border-radius: 50%;
            content: '\f058';
            // border-radius: 50%;
            // width: 6px;
            // height: 6px;
            font-weight: 700;
            font-family: "Font Awesome 6 Pro";
            position: absolute;
            font-size: 20px;
            left: 14px;
            top: 11px;
        }

        >input[type="radio"]:disabled+label::before {
            -webkit-transition: all ease 0.2s;
            -moz-transition: all ease 0.2s;
            transition: all ease 0.2s;
            margin-left: -32px;
            text-align: center;
            color: white;

            content: "";
            border-color: var(--text_neutral_secondary);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 20px;
            width: 20px;
            top: 14px;
            position: absolute;
        }

        >input[type="radio"]:disabled+label:after {
            // background: white;
            color: var(--text_neutral_secondary) !important;
            background: none !important;
            -webkit-text-fill-color: unset !important;

        }
    }

    .ai-setting-banner {
        padding-top: 41px;
        padding-bottom: 40px;
    }

    .ai-badge-success {
        display: inline;
        font-size: 12px;
        font-weight: 400;
        padding: 2px 8px;
        border-radius: 16px;
        line-height: 16px;
        text-align: center;
        background-color: var(--bkg_green_default);
        color: var(--text_neutral_ondark);
        margin-left: 10px;
        text-transform: none;
        // position: absolute;
        // top: 0;
        // left: 0;
    }

    .ai-badge-warning {
        display: inline;
        font-size: 12px;
        font-weight: 400;
        padding: 2px 8px;
        border-radius: 16px;
        line-height: 16px;
        text-align: center;
        background-color: var(--bkg_warning_default);
        color: var(--text_neutral_ondark);
        margin-left: 10px;
        text-transform: none;
        // position: absolute;
        // top: 0;
        // left: 0;
    }

    .ai-boxt-heading {
        font-size: 15px;
        color: var(--text_neutral_default);
        font-weight: 500;
        line-height: 20px;
    }

    .ai-boxt-description {
        font-size: 15px;
        color: var(--text_neutral_secondary);
        font-weight: 400;
        line-height: 20px;
        margin-top: 4px;
    }

    .setting-page-heading {
        padding-bottom: 16px;
        border-bottom: solid 1px var(--border_neutral_default);
        color: var(--text_neutral_secondary);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        text-transform: uppercase;
    }

    .deactivate-wrap {
        padding: 16px 0;
        display: none;
    }

    .deactivate-btn {
        text-align: center;
        border-radius: 56px;
        padding: 11px 16px;
        border: 2px solid #D83F34;
        background: #FFF;
        color: #C5392F;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        width: 100%;
    }

}

.hide-div-desktop {
    display: none;
}

.setting-text {
    color: var(--text_neutral_secondary);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-left: 10px;
}

//for mobile and ipad app only 
.vcTab,
.vcMobile {

    .ai-banner-popup {
        width: 100%;
        max-width: 500px;

        .ai-banner-popup-wrap {
            width: 100%;
        }

        &.modal-mb-half .modal-header {
            padding-top: 0px !important;
        }

        &.modal-mb-half .modal-body {
            padding-bottom: 8px;
        }

        .modal-footer .btn+.btn {
            margin-top: 16px;
        }

        .modal-footer .btn.vcgradient-border-btn {
            height: 50px;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .modal-header button.btn-close {
            display: block;
            right: 23px;
            top: 23px;
            font-weight: 900;
        }

        .modal-header button.btn-close::after {
            color: #333 !important;
        }

        .ai-banner-popup-wrap .ai-banner-popup-box {
            text-align: center;
        }

        .ai-banner-popup-wrap .ai-banner-popup-box img {
            max-width: 100%;
            margin-bottom: 16px;
            margin-top: -17px;
        }

        .ai-banner-popup-heading {
            color: var(--text_neutral_default, #333);
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0px;
        }

        .modal-footer {
            border: 0;
        }

        .ai-banner-popup-desc {
            color: var(--text_neutral_default, #333);
            font-family: "Proxima Nova";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .switch-checkboxhtml.switch-mobile-right {
        padding-left: 0;
        padding-right: 65px;

        label {
            display: block;
        }

        input[type="checkbox"]+label:before {
            right: -65px;
            left: auto
        }

        input[type=checkbox]:checked+label:after {
            -webkit-transform: translateX(-20px) translateY(0) translateZ(0);
            -moz-transform: translateX(-20px) translateY(0) translateZ(0);
            -ms-transform: translateX(-20px) translateY(0) translateZ(0);
            -o-transform: translateX(-20px) translateY(0) translateZ(0);
            transform: translateX(-20px) translateY(0) translateZ(0);
        }
    }

    .vcgradient-border-btn {
        position: relative;
        // padding: 14px 16px;
        border-radius: 56px;
        border: none !important;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        background: var(--Gradient-Linear, linear-gradient(180deg, #076A9F 0%, #533C89 100%));

        //  
        &:before {
            content: "";
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            background-color: white;
            border-radius: 56px;
        }

        & span {
            background-clip: text;
            background: var(--Gradient-Linear, linear-gradient(180deg, #076A9F 0%, #533C89 100%));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            z-index: 1;
            height: 100%;
        }
    }

    .settingfooter {
        .vcconnect-primary-btn.vcgradientbtn,
        .vcconnect-secondary-btn.vcgradient-border-btn {
            padding: 8px 16px !important;
            min-width: 120px;
        }
    }

    .pro-text-hide-content {
        display: block;
        padding-left: 8px;
        padding-right: 8px;
    }

    .connect-settingWrap .customredio-box-singal>label {
        padding-left: 52px;
    }

    .connect-settingWrap .customredio-box-singal>input[type=radio]+label::before {
        width: 24px;
        height: 24px;
        margin-left: -36px;
    }

    .connect-settingWrap .customredio-box-singal>input[type=radio]:checked+label:after {
        font-size: 24px;
        left: 16px;
        background: -webkit-linear-gradient(#076A9F, #533C89);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .connect-settingWrap .customredio-box-singal>input[type=radio]:checked+label {
        background: linear-gradient(#EAF4FB, #EAF4FB) padding-box, linear-gradient(180deg, #076A9F 0%, #533C89 100%) border-box;
        border-radius: 10px;
        border: 2px solid transparent;
    }

    .desktop-text-hide {
        display: none;
    }

    .btn-takecontrol {
        position: relative;
        padding: 10px 16px;
        border-radius: 56px;
        border: none;
        font-size: 18px;
        line-height: 20px;
        background: var(--Gradient-Linear, linear-gradient(180deg, #076A9F 0%, #533C89 100%));

        //  
        &:before {
            content: "";
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            background-color: white;
            border-radius: 56px;
        }

        & span {
            background-clip: text;
            background: var(--Gradient-Linear, linear-gradient(180deg, #076A9F 0%, #533C89 100%));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            z-index: 1;
        }
    }

    // Mobile and tablet working hours
    .mobile-working-days-wrap {
        width: 100%;
        background-color: var(--bkg_neutral_default);
        &:not(.hour-card-box) {
            margin-top: 28px;
        }

        &.daysBlockDisabled {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;

        }

        .m-working-row {
            color: #000;
            border-bottom: solid 1px var(--border_neutral_default);
            padding: 20px 0 20px 8px;
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
        }

        .m-time {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;

            flex: 1 0 0;

            text-align: right;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        .days {
            font-size: 16px;
            font-weight: 500;
        }

        .m-dot {
            border-radius: 10px;
            border: 1px solid var(--border_neutral_strong);

            width: 8px;
            height: 8px;

        }

        .m-dot.active {
            background: -webkit-linear-gradient(#076A9F, #533C89);
            border: 0;
        }

        .m-working-row:last-child {
            border-bottom: 0;
            padding-bottom: 5px;
        }

        .m-arrow-right {
            display: flex;
            width: 16px;
            height: 16px;
            font-size: 15px;
            font-weight: 900;
            padding: 0.5px 4px 0.5px 2px;
            justify-content: center;
            align-items: center;
        }

    }

    .connect-settingWrap .switch-checkboxhtml>input[type=checkbox]:checked+label:before {
        background: -webkit-linear-gradient(#076A9F, #533C89);
        border-color: #076A9F;
    }

    .switch-checkboxhtml.switch-mobile-right input[type=checkbox]+label:after {
        left: auto;
        right: -44px;
    }

    .switch-checkboxhtml.switch-mobile-right input[type=checkbox]:checked+label:after {
        transform: translateX(20px) translateY(0) translateZ(0);
    }

    .hour-card-box {
        border-radius: 5px;
        padding-bottom: 15px;

        .m-working-row {
            padding-left: 0;
            padding-right: 8px;
        }

        .margin-left16 {
            margin-left: 16px;
        }

        .padding-left16 {
            padding-left: 16px;
        }

        .switch-checkboxhtml {
            margin-right: auto;
            width: 100%;
        }

        .switch-checkboxhtml>label {
            display: block;
            margin-bottom: 0;
        }

        .access-level-title {
            margin-bottom: 0;
            padding-top: 6px;
        }

    }

}

//Mobile
.vcMobile {
    .show-div-mob-app {
        display: block;
    }

    .mob-app-hide {
        display: none;
    }
}


@media only screen and (min-width:768px) and (max-width:1023px) and (orientation:portrait) {
    .vcTab-portrait {
        .ai-banner-popup {
            .ai-banner-wrap img {
                max-width: auto;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.connect-settingWrap .messagesArea {
    border: 0px !important;
    border-bottom: solid 1px #cdcdcd !important;
    min-height: 44px;
    padding: 0;
    position: relative;
    z-index: 5;
}

.connect-settingWrap .messagesArea-header_nameArea {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 1px var(--border_neutral_default);
}

.connect-settingWrap .messagesArea-header_back {
    min-width: 44px;
    height: 44px;
    align-items: center;
    text-align: center;
    display: grid !important;
    margin-right: 0;
    text-decoration: none;
    font-size: 20px;
    text-decoration: none;
    font-weight: 900;
}

.connect-settingWrap .messagesArea-header_name {
    overflow: hidden;
    color: var(--text_neutral_default);
    text-align: center;
    text-overflow: ellipsis;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 10px;

}

.connect-settingWrap .messagesArea-header_moreVertical {
    min-width: 44px;
    min-height: 44px;
}

.ai-slider-wrapper {
    width: 100%;
    padding: 8px 0px 16px 0px;
    // margin-bottom: 15px;
}

.ai-slider-banner {
    width: 100%;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
    max-width: 153px;
    padding: 12px;
    border-radius: 8px;
    // height: 220px;
    margin-top: 62px;
    background: linear-gradient(131deg, #415CF6 24.51%, #9F0CD4 73.22%);
}

.ai-slider-banner img {
    margin-top: 8px;
    margin-bottom: 3px;
    margin-left: 6px;
    margin-right: auto;
}

.ai-slider-heading {
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 3px;
}

.ai-slider-description {
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0 0 8px 0;
    padding: 0;
    letter-spacing: -0.05px;
}

.ai-try-button {
    background-color: var(--bkg_neutral_default)!important;
    padding: 8px 0px 8px 0px;
    display: flex;
    width: 100%;
    text-decoration: none;
    border-radius: 3px;
    height: 40px;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 8px;
    &:hover {
        background-color: var(--bkg_components_button_neutral_hover) !important;
    }
}

.bannerslider .slick-dots li {
    margin: 0;
}

.bannerslider .slick-dots li button:before {
    font-size: 11px;
    opacity: 1;
    color: #e7e7e7;
}

.bannerslider .slick-dots li.slick-active button:before {
    color: #9a9a9a;
}

.upgrade-banner {
    padding: 16px;
    height: 282px;
    margin-left: 8px;
    margin-right: 8px;
    background: linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%);
    border-radius: 8px;
    text-align: center;
}

.upgrade-banner img {
    margin: 0 auto 8px auto;
}

.upgrade-banner .ai-slider-heading {
    margin-bottom: 3px;
}

.vcMobile:not(.vcTab-portrait) {
    .settingfooter {
        flex-direction: column;
        position: sticky;
        bottom: 0;
    }

    .margin-bottom8 {
        margin-bottom: 10px;
    }

    .connect-settingWrap .access-level-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    .connect-settingWrap .access-level-description {
        font-size: 12px;
        line-height: 16px;
    }

    .mob-wiget2 .div-with-switch {
        margin-top: 16px;
        padding-top: 16px;
        padding-bottom: 0;
        // border-top: 0px
        border-bottom: none;
    }

    .connect-settingWrap .customredio-box-singal>label {
        padding-right: 16px;
        width: 100%;
        border-radius: 10px;
        border-width: 2px;
    }

    .connect-settingWrap .ai-boxt-heading {
        font-size: 16px;
    }

    .connect-settingWrap .ai-boxt-description {
        font-size: 16px;
    }

    .connect-settingWrap .setting-page-heading {
        padding-top: 16px;
        margin: 0;
        border-bottom: 0;
        padding-bottom: 16px;
        font-size: 18px;
        line-height: 24px;
        color: var(--text_neutral_default);
    }

    .white-bg-box {
        background-color: var(--bkg_neutral_default);
        border-radius: 10px;
        padding: 16px;
    }

    .connect-settingWrap .ai-box-wrapper {
        flex-direction: column;
    }

    .vcMobile {
        .connect-setting-left {
            display: none;
        }

        .takecontrol-banner {
            flex-direction: column;
            padding: 16px 8px;
        }
    }

    .btn-text-row {
        display: flex;
        margin-bottom: 12px;
        align-items: center;
    }

    .btn-takecontrol {
        width: 100%;
        padding: 12px 16px !important;
    }
}

//For ipad css
//ipad potrait mode only 
@media (min-width: 768px) and (max-width: 992px) {
    .vcTab-portrait {
        .connect-settingWrap .access-level-title {
            font-size: 16px;
        }

        .connect-ai-banner {
            flex-direction: unset;
        }

        .mob-wiget2 {
            margin-top: 16px;
        }

        .ai-banner-right {
            padding: 0;
            width: 50%;
            margin-top: 0px;
        }

        .ai-banner-left {
            width: 50%;
        }

        .banner-discription-text {
            font-size: 18px;
            font-weight: 500;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .banner-heading {
            font-size: 24px;
            font-weight: 600;
            padding-top: 16px;
        }
    }

}

//For ipad css Both 
@media (min-width:768px) {


    .vcTab,
    .vcTab-portrait {
        .ai-setting-banner .vcconnect-secondary-btn {
            padding: 8px 16px;
            border: solid 2px #D83F34 !important;
            border-radius: 56px;
            color: #D83F34 !important;
            font-size: 18px;
            font-weight: 500;
        }

        .custom-hours-popup .btn.btn-primary.vcgradientbtn.vcprimary-btn {
            height: 40px;
        }

        .connect-right-wrapper .vcgradientbtn {
            padding: 8px 16px !important;
            font-size: 18px !important;
            font-weight: 500;

            letter-spacing: 0em;
            text-align: center;
            border-radius: 56px;
            width: auto;
        }

        .vcprimary-btn {
            background: linear-gradient(148.99deg, #076A9F 8.76%, #533C89 103.94%) !important;
            border: none;
            color: #FFF !important;

            &:hover {
                background: linear-gradient(139.35deg, #076A9F 45.18%, #533C89 127.99%) !important;
                transition: 0.5s all ease;
            }

            &:focus,
            &:active {
                background: linear-gradient(144.78deg, #076A9F -45.07%, #533C89 67.63%) !important;
            }
        }

        .settingfooter {
            gap: 16px;
        }

        .connect-settingWrap .div-with-switch {
            margin-top: 16px;
        }

        .settingfooter .vcgradientbtn {
            width: auto !important;
            // padding: 19px 8px !important;
        }

        .ipad-white-bg {
            border-radius: 10px;
            background-color: #fff;
            border: 0;
            padding: 16px;
            margin-top: 16px;
        }

        .ipad-white-bg .setting-page-heading {
            text-transform: capitalize;
            padding: 0;
            margin: 0 0 16px 0;
            color: #333;
            border: 0;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
        }

        .connect-settingWrap .div-with-switch {
            padding: 16px;
            // margin: 0;
            border-radius: 10px;
            background-color: #fff;
            border: 0;
        }

        .connect-right-wrapper {
            background-color: #f8f8f8;
        }

        .ipad-app-hide {
            display: none;
        }

        .ipad-app-show {
            display: block;
        }

        .takecontrol-banner,
        .takecontrol-border {
            border-radius: 15px;
        }

        // .vcgradient-border-btn {
        //     min-width: 120px;
        //     height: 24px;
        //     padding: 20px 8px;
        // }

        .settingfooter .vcprimary-btn {
            min-height: 24px;
            // padding: 19px 8px !important;
            min-width: 120px;
        }

        .settingfooter {
            background-color: #fff !important;
            border: 0px !important;
            box-shadow: none !important;
            justify-content: center;
        }
    }


    .vcTab {
        .connect-settingWrap .access-level-title {
            font-size: 16px;
        }

        .banner-discription-text {
            line-height: 24px;
            font-size: 18px;
            font-weight: 500;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        .banner-heading {
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
        }

        .connect-settingWrap .ai-setting-banner {
            padding-top: 16px;
        }

        .connect-settingWrap .div-with-switch {
            margin-top: 16px;
            padding: 16px;
        }

        //ai popup 
        // .ai-banner-popup::before { position: fixed; left: 0; top: 0; bottom: 0; right: 0; content: '';  z-index: 0;}
        .ai-banner-popup .modal-content {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .ai-banner-popup.modal-dialog-centered {
            margin: 0 auto;
            align-items: flex-end;
            min-height: 100vh;
        }

        .ai-banner-popup.modal-dialog {
            -webkit-transform: translate(0, 100%) !important;
            -ms-transform: translate(0, 100%) !important;
            -o-transform: translate(0, 100%) !important;
            transform: translate(0, 100%) !important;
        }

        .ai-banner-popup.modal-dialog .modal-footer .vcgradientbtn {
            width: 100%;
            margin: 0;
        }

        .show .ai-banner-popup.modal-dialog {
            opacity: 1;
            -webkit-transform: translate(0, 0%) !important;
            -ms-transform: translate(0, 0%) !important;
            -o-transform: translate(0, 0%) !important;
            transform: translate(0, 0%) !important;
        }
    }

}

.bussiness-hour-wrapper {
    max-width: 555px;
    margin-top: 24px;

    .form-control {
        font-size: 14px;
        border-color: var(--border_neutral_default);
        font-weight: 400;
        padding: 10px;
        color: var(--text_neutral_default);
        background-color: var(--bkg_neutral_default);
    }

    .form-control:focus {
        border-color: var(--border_blue_default);
    }

    .form-control:disabled {
        border-color: var(--border_neutral_default);
        background: var(--bkg_components_button_neutral_pressed);
        color: var(--text_neutral_default);
        opacity: 0.7;
        cursor: not-allowed;
    }

    .bus-working-hoursList {

        padding-bottom: 16px;

        .icontimepicker {
            width: 19px;
            height: 20px;
            right: 6px;
            top: 10px;
            position: absolute;
        }

        .icontimepicker:before {
            color: var(--text_neutral_secondary);
            font-size: 15px;
            content: "\f017";
            position: absolute;

            left: 0px;
            top: 0px;
            font-weight: 400;
            font-family: "Font Awesome 6 Pro";
            line-height: 19px;
        }

        @media screen and (max-width: 768px) {
            min-width: 100%;
        }

        .bus-header-row {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            padding: 16px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-color: var(--bkg_neutral_secondary) !important;
            border: 1px var(--border_neutral_strong) solid !important;
            color: var(--text_neutral_secondary) !important;
        }

        .bus-row-content {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;


            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }

        .bus-column-content-small {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            min-width: 100px;
            margin-right: 16px;
            padding: 0px;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-flex: 0;
            -webkit-flex: 0 auto;
            -ms-flex: 0 auto;
            flex: 0 auto;
        }

        .bs-table-header-text {
            color: var(--text_neutral_secondary);
        }

        .bs-table-row {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            padding: 16px;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            border-style: none solid solid;
            flex-wrap: wrap;
            border: solid 1px var(--border_neutral_strong);
            border-top: 0;
            position: relative;



            &:hover {
                .bs-copy-to-all {
                    opacity: 1;
                }
            }

            .requiredErrorText {
                margin-left: 201px;
                width: auto;
                display: inline-block;
                padding-top: 3px;
                color: var(--text_alert_default);
                font-size: 12px;

            }
        }

        .bs-table-row:last-of-type {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .bs-row-content {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;


            .switch-checkboxhtml {
                label {


                    .day {
                        font-size: 16px;
                        padding: 0px;
                        margin-right: 0px;
                        -webkit-box-pack: justify;
                        -webkit-justify-content: space-between;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        color: var(--text_neutral_default);




                    }
                }
            }

            .bs-row-content-input {
                padding: 8px;
                border: 0px;
                width: 100%;
                color: #333333;



            }

            @media screen and (max-width: 767px) {
                width: 100%;
                margin: 0px;
            }
        }

        .bs-field-icon {
            top: 0;
            height: 100%;
            content: "";
            font-family: "Font Awesome 6 Pro";
            font-size: 16px;
            line-height: 1;
            padding: 8px;
            color: var(--text_neutral_secondary);
            cursor: pointer;
            &::before {
                height: 100%;
                line-height: 40px;
                top: 0;
            }

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
                &::before {
                    cursor: not-allowed;
                }
            }
        }

        .bs-field-border.disabled .bs-row-content-input+.icontimepicker:before {
            color: #b3b3b3 !important;
        }

        .timepickerbox {
            width: 100%;
            position: relative;
        }

        .bs-column-content-small {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            min-width: 100px;

            padding: 0px;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-flex: 0;
            -webkit-flex: 0 auto;
            -ms-flex: 0 auto;
            flex: 0 auto;

            &.mobileHide {
                @media screen and (max-width: 767px) {
                    display: none
                }
            }




            &.first {
                padding-left: 0px;
                width: 200px;
                flex-shrink: 0;

                @media screen and (max-width: 767px) {
                    width: 100px;
                }
            }
        }

        .bs-column-content-small.disabled {
            color: #b3b3b3;
        }

        .bs-switch-body {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 50px;
            height: 30px;
            margin-right: 15px;
            padding-left: 0px;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            border-style: solid;
            border-width: 1px;
            border-color: rgba(0, 0, 0, 0.1);
            border-radius: 50px;
            background-color: #e7e7e7;
            cursor: pointer;
        }

        .bs-field-border {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-flex: 1;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;


            align-items: center;

            &.disabled {
                border-color: rgb(205, 205, 205);
                background: rgb(231, 231, 231);
                color: rgb(51, 51, 51);

                .bs-row-content-input {
                    background: rgb(231, 231, 231);
                    cursor: not-allowed;
                }

                .bs-field-icon {
                    color: #808080;
                    cursor: not-allowed;
                }
            }


        }

        .desh-text {
            padding-left: 12px;
            padding-right: 12px;
            font-size: 12px;
            text-align: center;
            color: var(--text_neutral_default)
        }

        .bs-delete-row-icon {
            padding: 4px;
            font-family: "Font Awesome 6 Pro";
            color: #2a90d6;
            font-size: 18px;

            &.bs-copy-to-all {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                opacity: 0;
                width: 100px;
                flex-shrink: 0;


            }
        }

        .bs-copy-to-all {
            font-family: inherit;
            color: #2a90d6;
            font-size: 14px;
            white-space: nowrap;
            cursor: pointer;
        }
    }

    .bus-working-hoursList .bs-field-border.disabled .bs-row-content-input::placeholder {
        color: #b3b3b3;
    }

    .bus-working-hoursList .bs-row-content .bs-row-content-input::placeholder {
        color: #9a9a9a;
    }

    .bus-working-hoursListPreview {
        padding-top: 0px;
        padding-left: 50px;
        padding-bottom: 8px;



        .bs-table-row {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            padding: 4px 16px;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            border-style: none solid solid;
            border: 0px;
            font-size: 15px;
            line-height: 20px;


            &:hover {
                .bs-copy-to-all {
                    opacity: 1;
                }
            }
        }

        .bs-row-content {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            margin-right: 8px;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;


        }

        .bs-column-content-small {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            min-width: 200px;
            margin-right: 16px;
            padding: 0px;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-flex: 0;
            -webkit-flex: 0 auto;
            -ms-flex: 0 auto;
            flex: 0 auto;






        }

        .bs-field-border {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }

    .bus-working-hoursList .bs-field-border.TelerikDatePicker .RadTimePicker input[type='text'][disabled] {
        color: rgb(179, 179, 179);
        -webkit-text-fill-color: rgb(179, 179, 179);
    }

    .bus-working-hoursList .bs-field-border.TelerikDatePicker .RadTimePicker .riTextBox.riEmpty {
        color: #9a9a9a
    }

    .div-with-switch.day-switch-border0 {
        border: 0px;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .custom-w-input.ng-valid-required .errorhide,
    .custom-w-input.ng-invalid-required~.errorhide {
        display: block;
        margin-bottom: 0px;
        margin-top: 0;
    }

    .spaceIncrese {
        padding-bottom: 24px;
        padding-top: 24px;
    }

    .custom-w-input.ng-valid-required.ng-pristine .errorhide {
        display: none
    }

    .addresswithcountry.bus-Address .select2-container.select2-container-multi.custom-w-input {
        width: calc(100% - 67px);
    }
}


.slider-space {
    // margin-bottom: 40px
}
// .connect-setting-left {
//     .slider-space {
//         margin-bottom: 0px
//     }   
// }

.hours-row-active {
    cursor: not-allowed;
    border-color: var(--border_neutral_default);
    background: var(--bkg_components_button_neutral_pressed);
}

.hours-row-active .datetimeicon::before {
    cursor: not-allowed;
}

// .datetimeicon::before{
//     font-family: "Font Awesome 6 Pro" !important;
//     top: 4px !important;
// }

.bottom-show-footer {
    .connect-settingWrap {
        height: calc(100vh - 111px);
    }
}
.vConnectMainWrap .leftside-toggle.settingBack {
    padding: 17px 20px 10px 13px;
    &:hover {
        opacity: 0.8;
    }
}

.vcMobile {
    .connect-settingWrap {
        .ai-badge-success,
        .ai-badge-warning {
            position: static;
            .statusText {
                display: none;
            }
        }
    }
    .bottom-show-footer {
        overflow: hidden;
        .connect-settingWrap {
            // min-height: calc(100vh - 146px);
        }
        .connet-mid-wrapper {
            overflow: auto;
        }
        .settingfooter {
            position: sticky;
            bottom: 0;
        }
    }
    .connect-right-wrapper {
        background: var(--border_neutral_secondary) !important;
    }
    .connect-settingWrap {
        height: 100vh;
    }
    .controlaiWrap {
        margin: 12px 16px;
        border-radius: 24px;
        &Block {
            border-radius: 24px;
            flex-wrap: wrap;
            padding: 16px 8px;
            &-text {
                font-size: 16px;
            }
            &-textIconWrap {
                margin-bottom: 14px;
            }
            &-button {
                width: 100%;
                .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
                    border-radius: 24px;
                    padding: 0 !important;
                    font-size: 20px;
                    border: none;
                    .vcsecondaryInner {
                        width: 100%;
                        padding: 13px 16px;
                    }
                }
            }
        }
    }
    .connect-settingWrap .setting-page-heading {
        text-transform: none;
    }
    &.vcTab-portrait {
        .connect-settingWrap {
            .ai-badge-success .statusText,
            .ai-badge-warning .statusText {
                display: inline-block;
            }
        }
        .controlaiWrap {
            border-radius: 15px;
            &Block {
                border-radius: 14px;
                flex-wrap: nowrap;
                &-textIconWrap {
                    margin-bottom: 0;
                }
                &-text {
                    font-size: 16px;
                }
                &-button {
                    width: auto;
                    .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
                        font-size: 18px;
                        .vcsecondaryInner {
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }
        .bottom-show-footer {
            overflow: hidden;
            .connet-mid-wrapper {
                overflow: auto;
            }
            .connect-settingWrap {
                // height: 100vh;
                height: calc(100vh - 15px);
            }
            .settingfooter {
                position: sticky;
                bottom: 0px;
                flex-direction: row;
            }
        }
        // .modal.show .modal-mb-half.modal-dialog,
        // .modal.in .modal-mb-half.modal-dialog {
        //     bottom: 16px;
        // }
    }
}

.vcTab {
    .settingPageTopBannerWrap .topBannerRightArea-imgDesktop {
        max-width: 80%;
    }
    .connect-settingWrap {
        .ai-badge-success,
        .ai-badge-warning {
            position: static;
        }
    }
    .connect-settingWrap {
        height: 100vh;
        border-top: 1px solid var(--border_neutral_default);
    }
    .bottom-show-footer {
        // .connect-settingWrap {
        //     height: calc(100vh - 30px);
        // }
        .settingfooter {
            position: sticky;
            bottom: 0px;
        }
    }
    .controlaiWrap {
        border-radius: 15px;
        margin: 1px 8px;
        &Block {
            border-radius: 14px;
            flex-wrap: nowrap;
            &-textIconWrap {
                margin-bottom: 0;
            }
            &-text {
                font-size: 16px;
            }
            &-button {
                width: auto;
                .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
                    font-size: 18px !important;
                    padding: 0 !important;
                    border-radius: 56px;
                    .vcsecondaryInner {
                        padding: 8px 16px;
                    }
                }
            }
        }
    }
    .bottom-show-footer {
        overflow: hidden;

        .connet-mid-wrapper {
            overflow: auto;
        }
    }
}
.vcTab-portrait.vcMobile {
    .connect-settingWrap .ai-box-wrapper {
        flex-direction: row;
    }
    // .connect-right-wrapper {
    //     padding: 24px 16px 16px 16px;
    // }
    .connect-settingWrap {
        background: var(--bkg_neutral_secondary);
        .ai-setting-banner {
            padding-top: 16px;
            padding-bottom: 0;
            .banner-heading {
                padding-top: 0;
                line-height: 26px;
            }
        }
    }
    .connect-settingWrap .setting-page-heading {
        text-transform: none;
    }
}
.vcTab {
    .connectAiSettingPageHeader {
        display: none;

        &.custom-hours-details {
            display: block;
        }
    }
    .vConnectMainWrap .chatList-person.personRead.aiReplyed .chatList-person_time {
        right: 30px;
        bottom: 17px;
    }
    .connect-settingWrap .setting-page-heading {
        text-transform: none;
    }
}
.mobileTimeValidation {
    margin-top: 15px;
    background: var(--bkg_neutral_default);
    border-radius: 5px;
    padding: 15px;
    vertical-align: middle;
    p {
        margin: 0;
        color: var(--text_alert_default);
    }
}

// .connectSettingPage {
//     &.vcMobile.vcTab-portrait,
//     &.vcTab {
//         .modal-dialog {
//             bottom: 24px;
//         }
//     }
// }

/*-- START: Time Picker --*/
.vagaroConnect {
    .ui-timepicker-container.ui-timepicker-standard {
        background: var(--bkg_neutral_default) !important;

        .ui-menu-item.ui-timepicker-selected a,
        .ui-menu-item.ui-timepicker-selected a:hover {
            background: var(--ButtonBackColor) !important;
            color: var(--MenuFontColor) !important;
        }
        .ui-menu-item a {
            color: var(--text_neutral_default) !important;
            &:hover {
                background: var(--bkg_neutral_tiertiary) !important;
            }
        }
    }
    .ui-timepicker-viewport {
        &::-webkit-scrollbar-thumb {
            background-color: var(--bkg_components_selector_disabled) !important;
        }
        &::-webkit-scrollbar-track {
            background-color: var(--bkg_neutral_tiertiary) !important;
        }
    }
}
/*-- END: Time Picker --*/
.vcTab-portrait,
.vcMobile,
.vcTab {
    .connect-settingWrap .messagesArea-header_nameArea {
        display: flex;
    }
    .salsePageMainWrap {
        width: 100%;
    }
    .connect-settingWrap .customredio-box-singal > label {
        border-radius: 10px;
    }
    &.vcAndroidTab {
        .settingfooter {
            bottom: 0 !important;
        }

        .connect-settingWrap .customredio-box-singal>input[type=radio]:checked+label:after,
        .connect-settingWrap .customredio-box-singal>input[type=radio]:checked+label:after {
            font-size: 30px;
        }
        .modal-dialog,
        &.connectSettingPage.vcTab .modal-dialog {
            bottom: 0;
        }
    }
}

.vcMobile:not(.vcTab-portrait) {
    .settingfooter {
        .vcgradient-border-btn {
            padding: 15px 16px !important;
        }
        .vcgradientbtn.vcprimary-btn,
        .vcgradientbtn.vcsecondary-btn.aiCancelBtn .vcsecondaryInner{
            padding: 13px 16px !important;
        }
    }
}

.vcMobile:not(.vcTab-portrait) {
    .newFeatureAiPopup {
        // .modal-header,
        .modal-body {
            padding-top: 0;
        }
        .modal-header button.btn-close {
            font-weight: 900;
            // font-size: 15px;
            right: 24px;
            opacity: 1;
        }
    }

}
.vcTab-portrait.vcMobile {
    .vConnectMainWrap.bottom-show-footer {
        .connet-mid-wrapper {
            height: calc(100% - 109px);
        }
    }
}

.vcTab {
    .aiActiveModal {
        .modal-footer {
            .btn.btn-secondary.vcgradientbtn.vcsecondary-btn,
            .btn.btn-primary.vcgradientbtn.vcprimary-btn {
                height: auto;
            }
        }
    }
}

.clickEventNoneOndeActiveAi {
    pointer-events: none;
}


/*- START: Salse pade Design -*/
.salsePageMainWrap {
    min-height: 100%;
    max-width: 100%;
    width: 1050px;
    .mob-wiget2 {
        padding: 24px !important;
        position: relative;
        z-index: 9;
    }
}

.settingPageTopBannerWrap {
    // display: flex;
    position: relative;
    // margin-top: 26px;
    .topBannerLeftArea,
    .topBannerRightArea {
        z-index: 1;
    }
    .topBannerLeftArea {
        margin-left: 76px;
        margin-top: 42px;
        .brandingName {
            display: flex;
            align-items: center;
            color: var(--text_neutral_secondary);
            font-size: 14.439px;
            font-style: normal;
            font-weight: 700;
            line-height: 22.46px; /* 155.556% */
            text-transform: uppercase;
            margin-bottom: 5px;
        }
        &-title {
            color: var(--text_neutral_default);
            font-size: 28.877px;
            font-style: normal;
            font-weight: 700;
            line-height: 32.086px; /* 111.111% */
        }
        &-info {
            // color: #000;
            color: var(--text_neutral_default);
            font-size: 14.439px;
            font-style: normal;
            font-weight: 400;
            line-height: 16.043px; /* 111.111% */
            margin-bottom: 22px;
            padding-right: 10px;
        }
        .aiActiveBtn {
            padding: 10px 18px;
        }
    }
    .topBannerRightArea {
        margin-left: 27px;
        margin-top: 2px;
        .brandingName {
            display: none;
        }
        &-imgDesktop {
            display: block;
        }
        &-imgMobile {
            display: none;
        }
    }
    .waveBGSetting {
        position: absolute;
        width: 100%;
        height: 259px;
        z-index: 0;
        bottom: 0px;
        &-desktop {
            display: block;
            width: 100%;
        }
        &-mobile {
            display: none;
        }
    }
}
.aiFeaturesWrap {
    margin-top: 96px;
    .aiFeaturesBlock {
        display: flex;
        align-items: center;
        width: 100%;

        &-img,
        &-text {
            width: 100%;
        }
        &-text {
            &_title {
                // color:#000;
                color: var(--text_neutral_default);
                font-size: 28.877px;
                font-style: normal;
                font-weight: 700;
                line-height: 32.086px; /* 111.111% */
            }
            &_info {
                // color: #000;
                color: var(--text_neutral_default);
                font-size: 14.439px;
                font-style: normal;
                font-weight: 400;
                line-height: 16.043px; /* 111.111% */
            }
        }

        &.poweredAi {
            .aiFeaturesBlock-img {
                margin-left: 73px;
                img {
                    max-width: 447px;
                }
            }
            .aiFeaturesBlock-text {
                margin: 0px 36px 0px 80px;
            }
        }
        &.customizeAi {
            margin-top: 96px;
            .aiFeaturesBlock-text {
                max-width: 413px;
                margin-top: 3px;
                margin-left: 124px;
                &_title {
                    margin-right: 70px;
                }
            }
            .aiFeaturesBlock-img {
                margin-left: 53px;
                max-width: 413px;
            }
        }
        &.custExperience {
            margin-top: 70px;
            padding-bottom: 124px;
            .aiFeaturesBlock-text_info {
                margin-left: -1px;
                padding-right: 36px;
            }
            .aiFeaturesBlock-img {
                margin: 7px 54px 0 89px;
            }
            .aiFeaturesBlock-text {
                margin-top: 32px;
                margin-left: 9px;
            }
            .custExperience-desktop {
                display: block;
            }
            .custExperience-mobile {
                display: none;
            }
        }
    }
}

.vcTab,
.vcTab-portrait,
.vcMobile {

    .connect-settingWrap.daySelectionPage {
        
        .salsePageMainWrap {
            padding: 24px;
        }
    }

    .salsePageMainWrap .mob-wiget2,
    .deactivate-wrap {
        padding: 24px;
        position: relative;
    }
    .lastModified {
        padding: 10px;
    }
    .settingPageTopBannerWrap {
        .topBannerRightArea {
            margin: 0;
            text-align: center;
        }
        .topBannerLeftArea {
            margin: 0;
            // &-title,
            // &-info {
            //     text-align: center;
            // }
        }
    }
    .settingPageTopBannerWrap {
        // .waveBGSetting {
        //     display: none;
        // }

        .topBannerLeftArea {
            margin-left: 24px;
        }
    }
    .aiFeaturesWrap {
        margin-top: 56px;
        .aiFeaturesBlock {
            flex-direction: column;
            &-img,
            &-text {
                text-align: center;
            }
            &.poweredAi {
                .aiFeaturesBlock-img {
                    margin-left: 0;
                }
                .aiFeaturesBlock-text {
                    margin: 0;
                }
            }
            &.customizeAi {
                flex-direction: column-reverse;
                margin-top: 56px;
                .aiFeaturesBlock-text,
                .aiFeaturesBlock-img {
                    margin: 0;
                }
                .aiFeaturesBlock-text {
                    max-width: 100%;
                    &_title {
                        margin-right: 0;
                    }
                }
            }
            &.custExperience {
                margin-top: 56px;
                .aiFeaturesBlock-text,
                .aiFeaturesBlock-img {
                    margin: 0;
                }
            }
        }
    }
}
.vcMobile {
    .aiFeaturesWrap {
        margin-top: 0;
    }
    .settingPageTopBannerWrap .topBannerRowWrap {
        .col-md-6 {
            width: 100%;
        }

    }
}
.vcTab-portrait,
.vcTab {
    .lastModified {
        padding-bottom: 40px;
        padding-right: 25px;
    }
}
.vcTab-portrait {
    .lastModified {
        padding-bottom: 20px;
    }
}

.vcTab {
    // .bottom-show-footer .settingfooter {
    //     bottom: 15px;
    // }
    .vConnectMainWrap .chatarea .messagesArea-middle.tackControlActive {
        height: calc(100vh - 168px);
        &::after {
            height: calc(100vh - 160px);
        }
    }
    .salsePageMainWrap .mob-wiget2 {
        padding-top: 0 !important;
    }
    .btn.vcconnect-primary-btn.vcgradientbtn.vcprimary-btn.aiActiveBtn {
        border-radius: 3.209px;
        background: var(--bkg_green_default) !important;
        padding: 12px 12px !important;
        color: var(--text_neutral_ondark);
        font-size: 14.439px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.043px;
        max-width: 100%;
    }
    .aiFeaturesWrap .aiFeaturesBlock {
        &.poweredAi,
        &.customizeAi,
        &.custExperience {
            flex-direction: row;
            padding: 0 24px;
        }

        .custExperience-mobile {
            display: none;
        }
    }
}

.vcMobile,
.vcTab-portrait {
    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle {
        height: calc(100% - 187px) !important;
        padding-bottom: 24px !important;
    }
    
    .connect-right-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .topBannerRowWrap {
        padding: 0 34px;
    }
    .connect-settingWrap.aiIsInActive {
        .connet-mid-wrapper {
            background: var(--bkg_neutral_default);
        }
    }
    .connect-right-wrapper {
        padding: 0;
    }
    .settingPageTopBannerWrap {
        margin-top: 0;
        background: var(--bkg_neutral_tiertiary);
        .topBannerLeftArea {
            margin: 0;
            &-title {
                margin-top: 14px;
                margin-bottom: 14px;
                text-align: center;

                color: var(--text_neutral_default);
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
            }
            &-info {
                text-align: center;
                margin-bottom: 14px;

                color: var(--text_neutral_default);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
            }
            .brandingName {
                display: none;
            }
        }
        .topBannerRowWrap {
            flex-direction: column-reverse;
        }
        .topBannerRightArea {
            .brandingName {
                display: block;
                color: var(--text_neutral_default);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: uppercase;
                margin-top: 33px;
                margin-bottom: 30px;
            }
            &-imgDesktop {
                display: none;
            }
            &-imgMobile {
                display: block;
                margin: 0 auto;
            }
        }
        .waveBGSetting {
            display: block;
            bottom: -78px;
            top: auto;
            height: 176px;
            &-desktop {
                display: none;
            }
            &-mobile {
                display: block;
                width: 100%;
                background: var(--bkg_neutral_tiertiary);
            }
        }
    }
    .btn.vcconnect-primary-btn.vcgradientbtn.vcprimary-btn.aiActiveBtn {
        border-radius: 3.209px;
        background: var(--bkg_green_default) !important;
        padding: 12px 12px !important;
        margin: 0 auto;
        color: var(--text_neutral_ondark);
        font-size: 14.439px;
        font-style: normal;
        font-weight: 500;
        line-height: 16.043px;
        max-width: 100%;
    }
    .salespage-middleWrap {
        position: relative;
        z-index: 3;
        margin-top: 80px;
        padding-left: 40px;
        padding-right: 40px;
    }
    .aiFeaturesWrap {
        .aiFeaturesBlock {
            &-text {
                &_title {
                    color: var(--text_neutral_default);
                    text-align: center;
                    font-size: 16.043px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 19.251px;
                    margin: 14px 0;
                }
                &_info {
                    color: var(--text_neutral_default);
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 0;
                }
            }
            &-img {
                img {
                    margin: 0 auto;
                }
            }
            &.poweredAi {
                .aiFeaturesBlock {
                    &-text {
                        padding: 0 20px;
                    }
                    &-img {
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
            &.customizeAi {
                margin-top: 53px;
                .aiFeaturesBlock {
                    &-text {
                        padding: 0 30px;
                        &_title {
                            margin: 8px 0;
                        }
                    }
                }
            }
            &.custExperience {
                margin-top: 53px;
                margin-bottom: 34px;
                .aiFeaturesBlock {
                    &-text {
                        padding: 0 38px;
                        &_title {
                            margin: 12px 0;
                        }
                    }
                }

                .custExperience {
                    &-desktop {
                        display: none;
                    }
                    &-mobile {
                        display: block;
                    }
                }
            }
        }
    }
}
.vcMobile:not(.vcTab-portrait) {
    .connect-right-wrapper {
        padding: 0;
    }
    .salsePageMainWrap .mob-wiget2 {
        padding: 24px 16px 0px 16px !important;
        position: relative;
    }
    .deactivate-wrap {
        padding: 16px 24px 24px 24px;
        position: relative;
    }
}



.vcTab-portrait.vcMobile,
.vcMobile {
    .waveBGSetting-mobile {
        .waveBGSetting-mobileWaveBottom {
            fill: var(--bkg_neutral_secondary);
        }
    }
    .settingPageTopBannerWrap .topBannerLeftArea {
        .btn.vcconnect-secondary-btn {
            margin: 0 auto;
        }
    }
}
.vcgradientbtn.aiCancelBtn {
    background-color: var(--bkg_components_button_neutral_default) !important;
    border: 1px var(--border_neutral_strong) solid !important;
    color: var(--text_neutral_default) !important;
}
.vcMobile,
.vcTab,
.vcTab-portrait  {
    .vConnectMainWrap {
        &.bottom-show-footer {
            height: 100vh;
        }
    }
    .settingfooter {
        width: 100%;
        max-width: 100%;
    }
    .vcgradientbtn.aiCancelBtn {
        padding: 0 !important;
        min-width: 120px;
        .vcsecondaryInner {
            width: 100%;
            padding: 8px 16px;
        }
    }    
}
.vcMobile:not(.vcTab-portrait) {
    .modal-mb-half .modal-footer {
        gap: 0;
    }
    .settingfooter {
        .btn + .btn {
            margin-top: 12px;
        }
    }
    .aiFeaturesWrap .aiFeaturesBlock.custExperience {
        margin-bottom: 0;
        padding-bottom: 70px;
    }
    .connect-settingWrap.daySelectionPage .salsePageMainWrap {
        padding: 24px 16px 16px 16px;
    }
}
.slick-slider.bannerslider {
    .slick-dots {
        position: static;
    }
}

.vcTab-browser {
    .vConnectMainWrap {
        .chatListBlock.convRight.convRight-open,
        .leftside.leftside-open {
            height: calc(100vh - 114px) !important;
        }
        .chatarea .chatListBlock .chatList-groupBlock {
            height: calc(100vh - 254px) !important;
        }
    }
    .topBannerRowWrap {
        .topBannerLeftArea {
            margin-left: 0;
        }
    }
    .aiFeaturesWrap {
        .aiFeaturesBlock {
            &-text {
                margin-left: 0 !important;
                &_title {
                    color: var(--text_neutral_default);
                    font-size: 16.043px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 19.251px;
                    margin: 14px 0;
                }
                &_info {
                    color: var(--text_neutral_default);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 0;
                }
            }
            &-img {
                margin-left: 0;
                img {
                    margin: 0 auto;
                }
            }
            &.poweredAi {
                .aiFeaturesBlock {
                    &-text {
                        padding: 0 20px;
                    }
                    &-img {
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
            &.customizeAi {
                margin-top: 53px;
                .aiFeaturesBlock {
                    &-text {
                        margin-left: 25px;
                        padding: 0 30px;
                        &_title {
                            margin: 8px 0;
                        }
                    }
                }
            }
            &.custExperience {
                margin-top: 53px;
                margin-bottom: 34px;
                .aiFeaturesBlock {
                    &-text {
                        padding: 0 38px;
                        &_title {
                            margin: 12px 0;
                        }
                    }
                }

                .custExperience {
                    &-desktop {
                        display: none;
                    }
                    &-mobile {
                        display: block;
                    }
                }
            }
        }
    }
    
    .connect-settingWrap.aiIsActive {
        height: calc(100vh - 176px);
    }

    .settingfooter {
        bottom: 114px;
    }
    .vConnectMainWrap .chatarea .chatListBlock .chatList-person.aiistyping .chatList-person_time {
        right: 40px;
    }
}

@media only screen and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .vcTab-browser {
        
        .settingPageTopBannerWrap {
            .topBannerRowWrap {
                flex-direction: column-reverse;
                background: var(--bkg_neutral_tiertiary);
                > div {
                    width: 100%;
                }
                .topBannerRightArea {
                    &-imgDesktop {
                        display: none;
                    }
                    &-imgMobile {
                        display: block;
                    }
                }
            }
            .topBannerLeftArea .brandingName {
                display: none;
            }
            .waveBGSetting {
                &-desktop {
                    display: none;
                }
                &-mobile {
                    display: block;
                }
            }
        }
        .aiFeaturesWrap {
            margin-top: 56px;
            .aiFeaturesBlock {
                flex-direction: column;
                &-img,
                &-text {
                    text-align: center;
                }
                &.poweredAi {
                    .aiFeaturesBlock-img {
                        margin-left: 0;
                    }
                    .aiFeaturesBlock-text {
                        margin: 0;
                    }
                }
                &.customizeAi {
                    flex-direction: column-reverse;
                    margin-top: 56px;
                    .aiFeaturesBlock-text,
                    .aiFeaturesBlock-img {
                        margin: 0;
                    }
                    .aiFeaturesBlock-text {
                        max-width: 100%;
                        &_title {
                            margin-right: 0;
                        }
                    }
                }
                &.custExperience {
                    margin-top: 56px;
                    .aiFeaturesBlock-text,
                    .aiFeaturesBlock-img {
                        margin: 0;
                    }
                }
            }
        }
    }
}

/*- END: Salse pade Design -*/
/*- START: pay pro -*/
.vcAndroidTab.vcTab {
    .avatar.avatar-sm {
        &.aiAvatarWrap {
            border: none !important;
        }
    }
}
/*- END: pay pro -*/

.vcMobile {
    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle {
        &:has(~ .messagesArea-footer .typeChatWrap.collapsibleChat .isTypingMain) {
            height: calc(100% - 143px) !important;
            flex-grow: initial;
        }
    }
    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle.tackControlActive {
        &:has(~ .messagesArea-footer .typeChatWrap.collapsibleChat .isTypingMain) {
            height: calc(100% - 230px) !important;
            flex-grow: initial;
        }
    }
}


.vcMobile.vcTab-portrait {
    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle {
        &:has(~ .messagesArea-footer .typeChatWrap .isTypingMain) {
            height: calc(100% - 220px) !important;
            flex-grow: initial;
        }
    }
}
.vcTab {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControl i {
        .fa-solid.fa-copy {
            font-size: 16px;
        }
    }
}


// @media (min-device-width: 800px) and (max-device-width: 1135px) {
//     .vcTab.vcAndroidTab,
//     .vcTab-portrait.vcAndroidTab {
//         .connect-settingWrap .customredio-box-singal > input[type=radio]:checked + label:after {
//             font-size: 24px !important;
//         }
//     }
// }

// @media (min-device-width: 800px) and (max-device-height: 1136px) and (orientation: portrait) {
//     .vcTab-portrait.vcAndroidTab {
//         .connect-settingWrap .customredio-box-singal > input[type=radio]:checked + label:after {
//             font-size: 30px !important;
//         }
//     }
// }


/*- START: multi location design -*/

.multiLocationAction-block {
    margin-top: 24px;
    .badgeBusiness {
        border-radius: 3px;
        background: var(--bkg_green_default);
        padding: 2px 8px;
        color: var(--text_neutral_ondark);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        display: inline-block;
    }
    .monthlyCostUrl {
        color: var(--text_blue_default);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration: none;
        margin-left: 16px;
    }
}

.vgn-modal-alert.tieredPricingModal {
    .modal-content {
        max-width: 480px;
    }
    .modal-header {
        padding-left: 19px;
        padding-top: 19px;
        .btn-close {
            right: 24px;
            top: 26px;

            &:after {
                font-size: 15px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
            }
        }
    }
    .modal-title {
        color: var(--text_neutral_default);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
    }
    .modal-body {
        padding: 16px 18px 24px 20px;
        .multiLocationTieredModal {
            &-title {
                color: var(--text_neutral_default);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                // line-height: 19px;
            }
            &-table {
                margin-top: 16px;
                width: 100%;
                border-radius: 5px;
                border: 1px solid var(--border_neutral_strong);
                border-spacing: 0;
                border-collapse: separate;
                overflow: hidden;
                thead > tr {
                    background: var(--bkg_neutral_secondary);
                    th {
                        border-bottom: 1px solid var(--border_neutral_strong);
                        padding: 16px 7px;
                        color: var(--text_neutral_default);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        width: 50%;
                        // line-height: 20px;
                    }
                }
                tbody > tr {
                    &.activeTier {
                        background: var(--bkg_neutral_secondary);
                        .activeTier-tag {
                            padding: 1px 8px;
                            border-radius: 3px;
                            background: var(--bkg_components_status_strong);
                            color: var(--text_neutral_inverse);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            margin-left: 5px;
                        }
                    }
                    td {
                        padding: 12px 7px;
                        color: var(--text_neutral_default);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        border-bottom: 1px solid var(--border_neutral_strong);
                        // line-height: 19px;
                        .contactSalesUrl {
                            cursor: pointer;
                            color: var(--text_blue_default);
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            text-decoration: none;
                        }
                    }
                    &:last-child td {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
    .modal-footer {
        .btn {
            padding: 9px 16px;
            color: var(--text_neutral_ondark);
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
        }
    }

    &.bookingLinkTypeModal {
        &.modal-dialog {
            max-width: 600px;
            .modal-content {
                max-width: none;
                .bookingLinkWrap {
                    .ai-boxt-heading {
                        color: var(--text_neutral_default);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0px;
                    }
                    .ai-boxt-description {
                        color: var(--text_neutral_secondary);
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0px;
                        margin-top: 4px;
                    }
                    .customredio-box-singal > label {
                        padding-top: 14px;
                        padding-bottom: 14px;
                    }
                }
            }
            .modal-footer {
                padding: 15px 18px 16px 20px;
            }
        }
        &.vgn-modal-alert .modal-footer .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
            display: block;
        }
    }
}
.tieredPricingModal.vgn-modal-alert .modal-footer {
    padding: 17px 18px 16px 20px;
    background: var(--bkg_neutral_secondary) !important;
    // .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
    //     display: none;
    // }
}
.bookingLinkWrap {
    display: flex;
    // align-items: center;
    // justify-content: space-between;
    margin-bottom: 0;
    .bookingLinkBlock {
        width: 50%;
        flex-grow: 1;
        &.allLocation {}
        &.singleLocation {
            margin-left: 16px;
        }
    }
}
.multiLocationSelect {
    &MainWrap {
        margin-top: 16px;
        position: relative;
        .textbox-label {
            color: var(--text_neutral_default);
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            .fa-solid.fa-chevron-right {
                display: none;
            }
        }
    }
    &Block {
        margin-top: 4px;
        position: relative;
        border-radius: 3px;
        border: 1px solid var(--border_neutral_strong);
        background: var(--bkg_neutral_default);
        padding: 8px;
        height: 40px;

        color: var(--text_neutral_default);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .fa-solid.fa-caret-down{
            position: absolute;
            right: 10px;
            top: 11px;
        }
    }
    &Wrap {
        position: absolute;
        z-index: 9;
        width: 100%;
        margin-top: 4px;
        padding-top: 8px;
        border-radius: 3px;
        background: var(--bkg_components_selector_default);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    }
    &__control {
        flex-direction: row-reverse;
        margin: 0 8px;
        border-color: var(--border_neutral_default) !important;
        background: var(--bkg_neutral_default) !important;
        &--is-focused {
            border-color: var(--border_neutral_default) !important;
            box-shadow: none !important;
        }
    }
    &__input {
        color: var(--text_neutral_default) !important;
    }
    &__menu {
        margin-top: 8px !important;
        background: var(--bkg_components_input_default) !important;
        &-list {
            max-height: 181px !important;
        }
    }
    &__group {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        &-heading {
            padding: 8px;
            margin-bottom: 0 !important;
            color: var(--text_neutral_default) !important;
            font-size: 15px !important;
            font-style: normal;
            font-weight: 700 !important;
            line-height: 20px;
        }
    }
    &__option {
        color: var(--text_neutral_default) !important;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 8px 8px 8px 36px !important;
        height: auto !important;
        &--is-focused,
        &--is-selected {
            background-color: transparent !important;
        }
    }
    &__value-container {
        padding: 0;
    }
    &__placeholder {
        margin: 0;
        color: var(--text_neutral_weak) !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    &__indicators {
        .fa-solid.fa-search {
            color: var(--text_neutral_weak);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-left: 8px;
            margin-right: 4px;
        }
    }
}

.customredio-box-singal {
    padding-left: 0px;
    height: 100%;
    width: 100%;

    >label {
        padding: 13px;
        border-radius: 5px;
        position: relative;
        font-size: 16px;
        border: 2px solid var(--border_neutral_strong);
        background: var(--bkg_neutral_default);
        height: 100%;
        cursor: pointer;
        display: inline-block;
        font-weight: 400;
        margin-bottom: 0;
        vertical-align: top;
        word-wrap: break-word;
        width: 100%;
        padding-left: 46px;
        padding-right: 10px;
    }


    >input[type="radio"] {
        display: none;
    }

    >input[type="radio"]+label {
        .radiocircle {
            background-color: var(--bkg_neutral_default);
            border: solid 1px var(--border_neutral_strong);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            top: 14px;
            position: absolute;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            content: "";
            cursor: pointer;
            float: left;
            height: 20px;
            margin-left: -32px;
            width: 20px;
        }

        .radiocircle.radiocircle-checked {
            display: none;
        }
    }

    >input[type="radio"]:checked+label {
        border: 2px solid var(--border_blue_default);
        background: var(--bkg_components_button_ghost_hover);
    }
    >input[type="radio"]:not(:disabled)+label:hover {
        border-color: var(--border_blue_default);
        // background: var(--bkg_components_button_ghost_hover);
    }

    >input[type="radio"]:disabled:checked+label {
        border: 2px solid var(--border_neutral_strong) !important;
        cursor: not-allowed;
        background: var(--border_neutral_default) !important;
    }
    >input[type="radio"]:disabled+label,
    >input[type="radio"]:disabled+label .radiocircle,
    >input[type="radio"]:disabled+label .radiocircle {
        cursor: not-allowed;
        opacity: 0.8;
    }

    >input[type="radio"]:checked+label .radiocircle {
        -webkit-transition: all ease 0.2s;
        -moz-transition: all ease 0.2s;
        transition: all ease 0.2s;
        margin-left: -32px;
        text-align: center;
        color: white;

        content: "";
        border-color: var(--icon_blue_default);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 20px;
        width: 20px;
        top: 14px;
        position: absolute;
    }

    >input[type="radio"]:checked+label .radiocircle.radiocircle-checked {
        display: block;
        // background: white;
        background: var(--icon_blue_default);
        // -webkit-border-radius: 50%;
        // -moz-border-radius: 50%;
        content: '\f058';
        // border-radius: 50%;
        // width: 6px;
        // height: 6px;
        font-weight: 700;
        font-family: "Font Awesome 6 Pro";
        position: absolute;
        font-size: 12px;
        // left: 14px;
        // top: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    >input[type="radio"]:disabled+label .radiocircle {
        -webkit-transition: all ease 0.2s;
        -moz-transition: all ease 0.2s;
        transition: all ease 0.2s;
        margin-left: -32px;
        text-align: center;
        color: white;

        content: "";
        border-color: var(--text_neutral_secondary);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 20px;
        width: 20px;
        top: 14px;
        position: absolute;
    }

    >input[type="radio"]:disabled+label .radiocircle.radiocircle-checked {
        // background: white;
        background: var(--text_neutral_secondary) !important;
        -webkit-text-fill-color: unset !important;

    }
}

.vcMobile {
    .tieredPricingModal.vgn-modal-alert {
        .modal-title {
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-top: 23px;
            margin-left: 15px;
        }
        .modal-body {
            padding-bottom: 11px;
            padding-top: 4px;
            padding-left: 15px;
            padding-right: 15px;
            .multiLocationTieredModal-title {
                display: none;
            }
            .multiLocationTieredModal-table {
                tbody > tr td {
                    padding: 12px 8px;
                    font-size: 16px;
                    line-height: 18.7px;
                    letter-spacing: 0;
                    font-weight: 400;
                    .contactSalesUrl {
                        font-size: 16px;
                    }
                }
                thead > tr th {
                    padding: 16px 8px;
                }
            }
        }
        .modal-footer {
            background: #fff !important;
            border: none;
            padding: 17px 23px 16px 25px;
            .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
                display: none;
            }
            .btn.btn-primary.vcgradientbtn.vcprimary-btn {
                margin-top: 0;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
        }
    }
    .multiLocationAction-block {
        display: none;
    }
    .getStartedWrapMobile {
        position: sticky;
        bottom: 0;
        z-index: 9;
        padding: 16px 25px;
        background: #fff;
    }
    .tieredPricingModal.bookingLinkTypeModal {
        &.modal-dialog.modal-dialog-centered {
            margin: 0;
            min-height: 100%;
            .modal-content {
                min-height: 100%;
                height: 100vh;
                .modal-header {
                    height: 44px;
                    padding: 0;
                    border-bottom: 1px solid var(--border_neutral_default);
                    background: var(--bkg_neutral_default);
                    .modal-title {
                        justify-content: center;
                        align-items: center;
                    }
                    .btn-close {
                        right: auto;
                        left: 12px;
                        &::after {
                            content: "\f053";
                            font-weight: 400;
                            font-size: 20px;
                        }
                    }
                }
                .modal-body {
                    background: var(--bkg_neutral_secondary);
                    padding: 16px 16px 0 16px;
                    .bookingLinkWrap {
                        flex-direction: column;
                        margin-top: 1px;
                        .bookingLinkBlock {
                            width: 100%;
                            margin-left: 0;
                            &.allLocation {
                                margin-bottom: 18px;
                            }
                            .ai-boxt-heading {
                                font-weight: 500;
                                font-size: 16px;
                            }
                            .ai-boxt-description {
                                color: var(--text_neutral_secondary);
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px;
                                letter-spacing: 0px;
                            }
                            
                            .customredio-box-singal > input[type=radio] + label {
                                padding-left: 49px;
                                .radiocircle {
                                    height: 24px;
                                    width: 24px;
                                    margin-left: -35px;
                                }
                            }
                            .customredio-box-singal > input[type=radio]:checked + label .radiocircle {
                                background: linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%);
                                .fa-solid.fa-check {
                                    color: var(--icon_neutral_ondark);
                                    font-size: 15px;
                                    font-style: normal;
                                    font-weight: 900;
                                    line-height: normal;
                                }
                            }
                            .customredio-box-singal > input[type=radio] {
                                &:not(:checked) + label {
                                    border-width: 1px;
                                }
                                &:checked + label {
                                    border-width: 2px;
                                }
                            }
                        }
                    }
                    .multiLocationSelectBlockWrap {
                        display: none;
                    }
                    .multiLocationSelectMainWrap {
                        .textbox-label {
                            border-radius: 10px;
                            background: var(--bkg_neutral_default);
                            padding: 16px;
                            color: var(--text_neutral_default);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .fa-solid.fa-chevron-right {
                                display: block;
                                color: var(--text_neutral_default);
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 900;
                                line-height: 15px;
                                margin: 0 4px 0 2px;
                            }
                        }
                    }
                }
                .modal-footer {
                    background: var(--bkg_neutral_secondary);
                    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
                    padding: 16px 23px;

                    .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
                        display: none;
                    }
                    .btn.vcconnect-primary-btn.vcgradientbtn.vcprimary-btn {
                        color: var(--text_neutral_ondark);
                        text-align: center;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        padding: 13px 16px !important;
                    }
                }
            }
        }
    }
}
.vcTab-portrait {
    .getStartedBtnTop {
        // display: none;
    }
    &.modal-open {
        .vgn-modal-alert.tieredPricingModal {
            align-items: center;
            justify-content: center;
            .modal-content {
                border-radius: 10px;
            }
        }
    }
}
.vcTab {
    .multiLocationAction-block {
        display: none;
    }
    .tieredPricingModal.vgn-modal-alert {
        .modal-footer {
            background: var(--bkg_neutral_default) !important;
            .btn.btn-primary.vcgradientbtn.vcprimary-btn {
                padding: 8px 16px !important;
                height: auto !important;
                
                font-size: 18px !important;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0px;
            }
            
            .btn.btn-secondary.vcgradientbtn.vcsecondary-btn {
                display: block;
                height: auto;
                .vcsecondaryInner {
                    padding: 8px 16px;
                }
                font-size: 18px !important;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0px;
            }
        }
    }
    .bookingLinkTypeModal.tieredPricingModal.vgn-modal-alert .modal-footer {
        button + button {
            margin-left: 16px;
        }
    }
}

/*- END: multi location design -*/